import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { changeLanguageCode, Logout } from "../../Helpers";
import loca from "../../utils/localization"

class DeleteAppUser extends Component {

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    deleteAppUser(userId) {
        document.getElementById("deleteUserBTN").classList.add("loading");
        axios(process.env.REACT_APP_API_URL + 'AppUser/Manage/' + userId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            window.UserManagement.getUsers();

                            toast.success(loca.TranslateHelper('delete-user-success-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            window.Modal.props.closeAction();
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("deleteUserBTN").classList.remove("loading");
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
                document.getElementById("deleteUserBTN").classList.remove("loading");
            });
    }

    render() {
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt="" />
                <h2 className="main tc">{loca.t('delete-user-label', { count: 1 })}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {loca.t('confirm-delete-user-label', { user: this.props.userName })}
                        <div className="form-group">
                            <button id="deleteUserBTN" className="btn btn-full mt-3" onClick={() => this.deleteAppUser(this.props.userId)}>{loca.t('delete-user-label', { count: 1 })}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DeleteAppUser);
