import { NoticeProps, components } from "react-select";
import loca from "../../utils/localization"
import React from "react";

export function LoadingOptionsMessage<OptionType> (props: NoticeProps<OptionType>) {
    return (
        <components.NoOptionsMessage {...props}>
            <loca.Translate locaKey={"loading-label"}></loca.Translate>
            <div className='loading'></div>
        </components.NoOptionsMessage>
    );
}