// TODO abalint This condition should be reconsidered
/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import "../css/main.scss";
import Header from "../modules/Header";
import axios from "axios";
import { changeLanguageCode, Logout, openModal, tagTypeToString } from "../Helpers";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import DeleteUser from "../modals/Users/DeleteUser";
import EditUser from "../modals/Users/EditUser";
import RemoveUserFromBag from "../modals/Bags/RemoveUserFromBag";
import UserToBag from "../modals/Bags/UserToBag";
import DeleteTag from "../modals/Tags/DeleteTag";
import EditTag from "../modals/Tags/EditTag";
import loca from "../utils/localization"
import ApproveDeletion from "../modals/Users/ApproveDeletion";

const userColumns = [
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('name-label')}</div>{loca.TranslateHelper('name-label')}</div>,
        id: 'name',
        width: '25%',
        sortable: true,
        selector: (row, index) => row.lastName.toUpperCase() + ' ' + row.firstName.toUpperCase() + ' ' + (row.externalId ? '(' + row.externalId + ')' : ''),
        cell: row => <div>{row.lastName} {row.firstName} {row.externalId ? '(' + row.externalId + ')' : ''}</div>
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('job-title-label')}</div>{loca.TranslateHelper('job-title-label')}</div>,
        sortable: true,
        width: '7%',
        selector: (row, index) => row.jobTitleName,
        cell: row => <div title={row.jobTitleName}>{row.jobTitleName}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('gender-label')}</div>{loca.TranslateHelper('gender-label')}</div>,
        sortable: true,
        selector: (row, index) => row.gender,
        width: '7%',
        cell: row => <div title={row.genderName}>{row.genderName}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('department-label')}</div>{loca.TranslateHelper('department-label')}</div>,
        sortable: true,
        selector: (row, index) => row.departmentName,
        width: '7%',
        cell: row => <div title={row.departmentName}>{row.departmentName}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('email-label')}</div>{loca.TranslateHelper('email-label')}</div>,
        sortable: true,
        width: '10%',
        selector: (row, index) => row.emailAddress,
        cell: row => <div title={row.emailAddress}>{row.emailAddress}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('assigned-tags-label')}</div>{loca.TranslateHelper('assigned-tags-label')}</div>,
        selector: 'tags',
        width: '14%',
        sortable: true,
        cell: row =>
            Object.keys(row.tags).map(key =>
                <span key={key}>
                    {row.tags[key].tagName} ({row.tags[key].externalId})<br />
                </span>
            )
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('comments-label')}</div>{loca.TranslateHelper('comments-label')}</div>,
        selector: (row, index) => row.comment,
        sortable: true,
        width: '20%',
        cell: row => {
            if (row.comment) {
                return <div title={row.comment}>
                    <i className="fas fa-comment-alt" /> {row.comment}
                </div>
            } else {
                return ''
            }
        }
        ,
    },
    {
        name: '',
        sortable: false,
        width: '10%',
        cell: row =>
            <div>
                <button className="btn btn-action"
                    disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled && !row.deletionPending || (row.deletionPending && JSON.parse(localStorage.getItem('configData')).isPendingUserDeletionDisabled) ? 'disabled' : ''}
                    onClick={() => openModal(row.deletionPending ? <ApproveDeletion userId={row.userId} hasAssignment={row.hasAssignment} userName={row.lastName + ' ' + row.firstName} /> : <DeleteUser userId={row.userId} hasAssignment={row.hasAssignment} userName={row.lastName + ' ' + row.firstName} />, { 'size': '600px' })}>
                    <i className="icon-delete" />
                    {row.deletionPending && !JSON.parse(localStorage.getItem('configData')).isPendingUserDeletionDisabled && <span className="badge position-absolute badge-pending">{loca.TranslateHelper('pending-label')}</span>}
                </button>
                <button className="btn btn-action" onClick={() => openModal(<EditUser userId={row.userId} user={row} />, { 'size': '1100px' })}><i className="icon-edit" /></button>
            </div>,
    },
];
const bagColumns = [
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('building-label')}</div>{loca.TranslateHelper('building-label')}</div>,
        sortable: true,
        width: '14%',
        id: 'building',
        label: <div>{loca.TranslateHelper('building-label')}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.building,
        cell: row => <div>
            <div>{row.building}</div>
        </div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('room-label')}</div>{loca.TranslateHelper('room-label')}</div>,
        sortable: true,
        width: '6%',
        id: 'room',
        label: <div>{loca.TranslateHelper('room-label')}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.room,
        cell: row => <div>
            <div>{row.room}</div>
        </div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('gender-label')}</div>{loca.TranslateHelper('gender-label')}</div>,
        sortable: true,
        width: '5%',
        id: 'gender',
        label: <div>{loca.TranslateHelper('gender-label')}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.roomGender,
        cell: row => <div>
            <div>{row.roomGender}</div>
        </div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('system-label')}</div>{loca.TranslateHelper('system-label')}</div>,
        sortable: true,
        width: '10%',
        id: 'system',
        label: <div>{loca.TranslateHelper('system-label')}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.systemName,
        cell: row => <div>
            <div>{row.systemName}</div>
        </div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('bag-label')}</div>{loca.TranslateHelper('bag-label')}</div>,
        sortable: true,
        width: '5%',
        id: 'bag',
        label: <div>{loca.TranslateHelper('bag-label')}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.bagNumber,
        cell: row => <div>
            <div>{row.bagNumber}</div>
        </div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('state-label')}</div>{loca.TranslateHelper('state-label')}</div>,
        sortable: true,
        width: '5%',
        id: 'state',
        label: <div>{loca.TranslateHelper('state-label')}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.state,
        right: true,
        cell: row =>
            <div>
                <div className={'my-4 status status-' + row.state} />
            </div>
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('assigned-users-label')}</div>{loca.TranslateHelper('assigned-users-label')}</div>,
        selector: 'users',
        width: '10%',
        id: 'users',
        label: <div>{loca.TranslateHelper('assigned-users-label')}</div>,
        hideable: true,
        omit: false,
        sortable: false,
        cell: row =>
            Object.keys(row.assignments).map(key =>
                <span key={key}>
                    {row.assignments[key].userId !== "00000000-0000-0000-0000-000000000000" ? <button className="" onClick={() => openModal(<RemoveUserFromBag userId={row.assignments[key].userId} userName={row.assignments[key].lastName + ' ' + row.assignments[key].firstName} bag={row} />, { 'size': '600px' })}><i className="icon-delete">&nbsp;</i></button> : ''}
                    {row.assignments[key].userId !== "00000000-0000-0000-0000-000000000000" ? <button className="" onClick={() => openModal(<EditUser userId={row.assignments[key].userId} user={row.assignments[key]} />, { 'size': '1100px' })}><i className="icon-edit">&nbsp;</i></button> : ''}
                    {row.assignments[key].lastName} {row.assignments[key].firstName} {row.assignments[key].externalId ? '(' + row.assignments[key].externalId + ')' : ''} <br />
                </span>
            )
    },
    {
        name: '',
        sortable: false,
        selector: 'add-user',
        id: '',
        width: '10%',
        ignoreRowClick: true,
        cell: row => <div><button className="btn btn-full" onClick={() => openModal(<UserToBag bag={row} />, { 'size': '800px' })}><i className="icon-add-user" />{loca.TranslateHelper('users-label')}</button></div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('jobtitle-label')}</div>{loca.TranslateHelper('jobtitle-label')}</div>,
        selector: 'title',
        width: '10%',
        id: 'title',
        label: <div>{loca.TranslateHelper('jobtitle-label')}</div>,
        hideable: true,
        omit: false,
        sortable: false,
        cell: row =>

            Object.keys(row.assignments).map(key =>
                <span key={key}>
                    {row.assignments[key].jobTitleName} <br />
                </span>
            )
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('department-label')}</div>{loca.TranslateHelper('department-label')}</div>,
        selector: 'department',
        width: '10%',
        id: 'department',
        label: <div>{loca.TranslateHelper('department-label')}</div>,
        hideable: true,
        omit: false,
        sortable: false,
        cell: row =>
            Object.keys(row.assignments).map(key =>
                <span key={key}>
                    {row.assignments[key].departmentName} <br />
                </span>
            )
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('comments-label')}</div>{loca.TranslateHelper('comments-label')}</div>,
        selector: 'comments',
        width: '15%',
        id: 'comments',
        sortable: false,
        hidable: true,
        omit: false,
        cell: row =>
            Object.keys(row.assignments).map(key =>
                row.assignments[key].comment && <span className="d-block" key={key}>
                    <i className="fas fa-comment-alt" /> {row.assignments[key].comment} <br />
                </span>
            )
    },
];
const tagColumns = [
    {
        name: 'RFID UID',
        sortable: true,
        id: 'uid',
        selector: (row, index) => row.externalId,
        cell: row => <div>{row.externalId}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('tag-name-label')}</div>{loca.TranslateHelper('tag-name-label')}</div>,
        sortable: true,
        selector: (row, index) => row.tagName,
        cell: row => <div>{row.tagName}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('tag-type-label')}</div>{loca.TranslateHelper('tag-type-label')}</div>,
        sortable: true,
        selector: row => tagTypeToString(row.tagType),
        filterable: true,
        width: '100px',
        cell: row => <div>{tagTypeToString(row.tagType)}</div>,
    },
    {
        name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('assigned-to-label')}</div>{loca.TranslateHelper('assigned-to-label')}</div>,
        sortable: true,
        selector: (row, index) => (row.user ? row.user.lastName + ' ' + row.user.firstName + ' (' + row.user.externalId + ')' : ''),
        cell: row => <div>{row.user ? row.user.lastName + ' ' + row.user.firstName + ' (' + row.user.externalId + ')' : ''} </div>,
    },
    {
        name: '',
        selector: 'actions',
        sortable: false,
        width: '80px',
        cell: row =>
            <div>
                <button disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled && row.tagType !== 3 ? 'disabled' : ''} className="btn btn-action" onClick={() => openModal(<DeleteTag tagId={row.tagId} tagName={row.tagName} />)}><i className="icon-delete" /></button>
                <button className="btn btn-action" onClick={() => openModal(<EditTag tagId={row.tagId} />)}><i className="icon-edit" /></button>
            </div>,
    },
];

const UserResults = (props) => {
    return (
        <>
            <div className="container-div mt-5">
                <div className="row">
                    <div className={'col-12'}>
                        <h3 className={'main mb-4'}>{loca.TranslateHelper('users-label')}</h3>
                    </div>
                </div>
            </div>

            <DataTable
                noHeader
                className="datatable"
                highlightOnHover
                columns={userColumns}
                data={props.results}
            />

            {props.results.length > 20 && <div className={'row justify-content-end p-0 m-0'}>
                <div className={'col-auto pt-3'}>
                    <button className={'btn btn-small text-uppercase'}>{loca.TranslateHelper('show-all-results-label')}</button>
                </div>
            </div>}
        </>)
};

const BagResults = (props) => <>
    <div className="container-div mt-5">
        <div className="row">
            <div className={'col-12'}>
                <h3 className={'main mb-4'}>{loca.TranslateHelper('bags-label')}</h3>
            </div>
        </div>
    </div>

    <DataTable
        noHeader
        className="datatable"
        pagination={false}
        highlightOnHover
        columns={bagColumns}
        data={props.results}
    />
</>;

const TagResults = (props) => <>

    <div className="container-div mt-5">
        <div className="row">
            <div className={'col-12'}>
                <h3 className={'main mb-4'}>{loca.TranslateHelper('tags-label')}</h3>
            </div>
        </div>
    </div>

    <DataTable
        noHeader
        className="datatable"
        pagination={false}
        highlightOnHover
        columns={tagColumns}
        data={props.results}
    />
</>;

class searchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoading: true,
            searchText: this.props.match.params.text,
            searchCategory: this.props.match.params.category,
            userResults: [],
            tagResults: [],
            bagResults: [],
            currentview: 'search',
            userResultLimit: 2,
            tagResultLimit: 5,
            bagResultLimit: 5,
        }

        window.View = this;

        this.userSearch()
        this.tagSearch()
        this.bagsSearch()
    }

    userSearch() {
        axios(process.env.REACT_APP_API_URL + 'Search/searchUsers', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            params: {
                searchString: this.state.searchText
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ userResults: response.data });
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                console.error('There was an error!', error.message);
            });
    }

    tagSearch() {
        axios(process.env.REACT_APP_API_URL + 'Search/searchTags', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            params: {
                searchString: this.state.searchText
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    //(response)
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ tagResults: response.data });
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                console.error('There was an error!', error.message);
            });
    }

    bagsSearch() {
        axios(process.env.REACT_APP_API_URL + 'Search/searchLockerBags', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            params: {
                searchString: this.state.searchText
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ bagResults: response.data });
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                console.error('There was an error!', error.message);
            });
    }

    componentDidMount() {
        if (this.state.searchCategory) {
            window.Header.setSearchCategory({ value: this.state.searchCategory, label: loca.TranslateHelper(this.state.searchCategory + '-label') });
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className={'search-page'}>

                <Header
                    headerTitle={t('search-results-for-label', { text: this.state.searchText })}
                    subtitle={false}
                    enableStat={false}
                />

                {this.state.searchCategory === 'All' && <>

                    <UserResults results={this.state.userResults} />
                    <TagResults results={this.state.tagResults} />
                    <BagResults results={this.state.bagResults} />
                </>}

                {this.state.searchCategory === 'User' && <>

                    <UserResults results={this.state.userResults} />
                    <TagResults results={this.state.tagResults} />
                    <BagResults results={this.state.bagResults} />
                </>}

                {this.state.searchCategory === 'Tag' && <>
                    <TagResults results={this.state.tagResults} />
                    <UserResults results={this.state.userResults} />
                    <BagResults results={this.state.bagResults} />
                </>}

                {this.state.searchCategory === 'LockerBag' && <>
                    <BagResults results={this.state.bagResults} />
                    <TagResults results={this.state.tagResults} />
                    <UserResults results={this.state.userResults} />
                </>}
            </div>
        )
    }
}

export default withTranslation()(searchPage)


