import React, { Component } from "react";
import Dropzone from "react-dropzone";

import UploadService from "../services/upload-files.service";
import {changeLanguageCode, Logout} from "../Helpers";
import axios from "axios";
import {toast} from "react-toastify";
import loca from "../utils/localization"

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.upload = this.upload.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
        };
    }

    upload(idx, file) {
        let _progressInfos = [...this.state.progressInfos];

        UploadService.upload(file, this.props.dataType, (event) => {
            _progressInfos[idx].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            this.setState({
                _progressInfos,
            });
            this.setState({
                uploadStatus:1
            });
        })
            .then(() => {
                this.setState({
                    uploadStatus:2
                });
                this.setState((prev) => {
                    let nextMessage = [
                        ...prev.message,
                        //"Uploaded the file successfully: " + file.name,
                        loca.TranslateHelper('successfull-import-label'),
                    ];
                    return {
                        message: nextMessage,
                    };
                });

                return true;
            })
            .then(() => {
                setTimeout(function(){
                    window.location.reload()
                },1000);
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [
                        ...prev.message,
                        "Could not upload the file: " + file.name,
                    ];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage,
                    };
                });
            });
    }

    uploadFiles() {
        const selectedFiles = this.state.selectedFiles;

        let _progressInfos = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }

        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i]);
                }
            }
        );
    }

    onDrop(files) {
        if (files.length > 0) {
            this.setState({ selectedFiles: files });
        }
    }


    getTemplate(){
        axios(process.env.REACT_APP_API_URL+'Provision/'+this.props.dataType,{
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'import'+this.props.dataType+'.csv'); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }
                    }else{
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }


    render() {
        const { selectedFiles, progressInfos, message, fileInfos } = this.state;

        return (
            <div className={'mt-3'}>
                {progressInfos &&
                progressInfos.map((progressInfo, index) => (
                    this.state.uploadStatus === 1 && <div className="mb-4 file-status" key={index}>
                        <span><i className={'icon-page mr-2'}></i>{progressInfo.fileName}</span>
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info"
                                role="progressbar"
                                aria-valuenow={progressInfo.percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progressInfo.percentage + "%" }}
                            >
                                <span>Uploading: {progressInfo.percentage}%</span>
                            </div>
                        </div>
                    </div>
                ))}

                <div className="my-3">
                    {!this.state.uploadStatus && <Dropzone onDrop={this.onDrop} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps({ className: selectedFiles &&
                                    Array.isArray(selectedFiles) &&
                                    selectedFiles.length ? 'dropzone uploaded' : 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    {selectedFiles &&
                                    Array.isArray(selectedFiles) &&
                                    selectedFiles.length ? (
                                        <div className="selected-file">
                                            {selectedFiles.length > 3
                                                ? `${selectedFiles.length} files`
                                                : selectedFiles.map((file, index) => (<div key={index}> <div className={'float-left'}><i className={'icon-page float-left mr-2'}></i> {file.name} </div><div className={'float-right'} onClick={()=>this.setState({selectedFiles:null})}><i className={'icon-cross float-left mr-2'}></i> {loca.TranslateHelper('remove-label')}</div></div>))
                                            }
                                        </div>
                                    ) : (
                                        loca.TranslateHelper('drag-and-drop-label')
                                    )}
                                </div>
                                <aside className="selected-file-wrapper">
                                    <button
                                        className="btn btn-full"
                                        disabled={!selectedFiles}
                                        onClick={this.uploadFiles}
                                    >
                                        <i className={'icon-upload'}></i>{loca.TranslateHelper('upload-label')}
                                    </button>
                                </aside>
                            </section>
                        )}
                    </Dropzone>}
                </div>

                {this.state.uploadStatus === 2 && message.length > 0 && (
                    <>
                        <div className="alert alert-success" role="alert">
                            {message.map((item, i) => {
                                return <div className={'p-4'} key={i}><i className={'icon-check mr-2'}></i>{item}</div>;
                            })}
                        </div>

                        <button className="btn btn-full mt-3" onClick={()=>this.setState({uploadStatus:0,selectedFiles:null})}>{loca.TranslateHelper('import-label')}</button>
                    </>
                    )}

                {this.state.uploadStatus === 1 && message.length > 0 && (
                    <>
                        <div className="alert alert-danger" role="alert">
                            {message.map((item, i) => {
                                return <div className={'p-4'} key={i}><i className={'icon-cross mr-2'}></i>{item}</div>;
                            })}
                        </div>

                        <button className="btn btn-full mt-3" onClick={()=>this.setState({uploadStatus:0,selectedFiles:null})}>{loca.TranslateHelper('import-label')}</button>
                    </>
                    )}

                {fileInfos.length > 0 && (
                    <div className="card">
                        <div className="card-header">List of Files</div>
                        <ul className="list-group list-group-flush">
                            {fileInfos &&
                            fileInfos.map((file, index) => (
                                <li className="list-group-item" key={index}>
                                    <a href={file.url}>{file.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {fileInfos.length !== 0 && (
                    <div className="form-group">
                        <button id="importBtn" className="btn btn-full mt-3">{loca.TranslateHelper('start-importing-label')}</button>
                    </div>
                )}


                {!this.state.uploadStatus && <button className={'btn btn-simple'} onClick={() => this.getTemplate()}><i className={'icon-download'}></i> {loca.TranslateHelper('download-template-label')}</button>}
            </div>
        );
    }
}
