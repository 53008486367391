import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { changeLanguageCode, Logout, RefreshView } from "../../Helpers";
import loca from "../../utils/localization"

class DeleteUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activateCleaningState: JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled ? true : false,
        }
    }

    deleteUsers() {
        document.getElementById("deleteBTN").classList.add("loading");
        axios(process.env.REACT_APP_API_URL + 'User/deleteMultipleUsers', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            data: {
                activateCleaningState: this.state.activateCleaningState,
                userIds: this.props.users
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            RefreshView(window.View.state.currentview);

                            window.Modal.props.closeAction();

                            toast.success(loca.TranslateHelper('delete-user-success-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("deleteBTN").classList.remove("loading");
                    }
                }
            )
            .catch(error => {
                if (error.response) {
                    toast.error(error.response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    document.getElementById("deleteBTN").classList.remove("loading");
                }
            });
    }

    changeCleaningState = (e) => {
        this.setState({ activateCleaningState: !this.state.activateCleaningState });
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt="" />
                <h2 className="main tc">{loca.TranslateHelper('delete-user-label', { count: this.props.users.length })}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {t('confirm-delete-user-label')}
                        {JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled && <div className="checkbox-container mt-4 mb-2">
                            <input type="checkbox" id={'activateCleaningState'} name="activateCleaningState" className="inp-cbx" checked={this.state.activateCleaningState === true ? 'checked' : ''} onChange={this.changeCleaningState} />
                            <label className="cbx" htmlFor={'activateCleaningState'}>
                                <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1" /></svg></span>
                                <span className={'text-left'}>{loca.TranslateHelper('place-to-cleaning-state-user-delete-label')}</span>
                            </label>
                        </div>}
                        <div className="form-group">
                            <button id="deleteBTN" className="btn btn-full mt-3" onClick={() => this.deleteUsers()}>{loca.TranslateHelper('delete-user-label', { count: this.props.users.length })}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DeleteUsers);
