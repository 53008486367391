import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import "../css/main.scss";
import Header from "../modules/Header";
import axios from "axios";
import {changeLanguageCode, Logout} from "../Helpers";
import {toast} from "react-toastify";
import loca from "../utils/localization"


class FloorPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plans : [],
        }
    }
    getFloorPlan() {
        axios(process.env.REACT_APP_API_URL+'FloorPlan/getWithoutData',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            this.setState({ plans: response.data });

                        }
                    }else{
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    downloadFile(id, filename){

        document.getElementById("download-"+id).classList.add("loading");
        axios(process.env.REACT_APP_API_URL+'FloorPlan/getData/'+id,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{

                            var byte = this.base64ToArrayBuffer(response.data.floorPlanBytes);

                            var blob = new Blob([byte], {type: "application/pdf"});
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            var fileName = response.data.name;
                            link.download = fileName;
                            link.click();

                            document.getElementById("download-"+id).classList.remove("loading");
                        }
                    }else{

                        document.getElementById("download-"+id).classList.remove("loading");
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {

                document.getElementById("download-"+id).classList.remove("loading");
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    showFile(id, filename){
        document.getElementById("view-"+id).classList.add("loading");
        axios(process.env.REACT_APP_API_URL+'FloorPlan/getData/'+id,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{

                            var byte = this.base64ToArrayBuffer(response.data.floorPlanBytes);

                            var blob = new Blob([byte], {type: "application/pdf"});
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.target = "_blank";
                            link.click();

                            document.getElementById("view-"+id).classList.remove("loading");
                        }
                    }else{

                        document.getElementById("view-"+id).classList.remove("loading");
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {

                document.getElementById("view-"+id).classList.remove("loading");
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    componentDidMount() {
        this.getFloorPlan();
    }

    render() {
        return (
            <div>
                <Header headerTitle={loca.TranslateHelper('side-floorPlan')} subtitle={false} enableStat={false}/>

                <div className="container-div">
                    <div className="row">
                        {this.state.plans.map((item,index) => (
                            <div className="col col-sm-6 col-md-6 col-lg-4" key={index}>
                                <div className="floorplan">
                                    <div className="download">
                                        <span className="ellipsis">{item.name}</span>
                                        <button className="btn btn-full pl-3 pr-2 float-right ml-2 btn-sq" id={'view-'+item.floorPlanId} onClick={()=>this.showFile(item.floorPlanId,item.name,item.extension)}><i className="icon-zoom"/></button>
                                        <button className="btn btn-full pl-3 pr-2 float-right btn-sq" id={'download-'+item.floorPlanId} onClick={()=>this.downloadFile(item.floorPlanId,item.name,item.extension)}><i className="icon-download"/></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(FloorPlan)


