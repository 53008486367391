import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import loca from "../utils/localization"

class Support extends Component {
    render() {
        return (
            <div>
                <img className="modal-icon" src={require("../images/svg/envelope2.svg")} alt=""/>
                <h2 className="main tc">{loca.TranslateHelper('support-and-feedback')}</h2>

                <div className="row mt-6">
                    <div className="col col-5">
                        <div className="row blender-medium">
                            <div className="col-md-1 pr-1"><i className="icon-marker fs-12"/></div>
                            <div className="col-md-10">
                                Schweiz<br/>
                                Zippsafe AG<br/>
                                Europa-Strasse 17<br/>
                                8152 Glattbrugg<br/>
                            </div>
                        </div>
                    </div>
                    <div className="col col-5">
                        <div className="row mb-1 blender-medium">
                            <div className="col-md-1"><i className="icon-phone fs-12"/></div>
                            <div className="col-md-10">+41 44 510 81 00</div>
                        </div>
                        <div className="row mb-1 blender-medium">
                            <div className="col-md-1"><i className="icon-envelope fs-12"/></div>
                            <div className="col-md-10"><a href="mailto:support@zippsafe.com">support(at)zippsafe.com</a></div>
                        </div></div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Support);