import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Select from 'react-select';
import axios from "axios";
import SelectColours from "../../css/SelectColours";
import { toast } from "react-toastify";
import {
    changeLanguageCode,
    Logout,
    openModal,
    Option,
    RefreshView,
    ValueContainer
} from "../../Helpers";
import CreateTag from "../Tags/CreateTag";
import { connect } from "react-redux";
import { compose } from "redux";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"
import UserToBag from "../Bags/UserToBag";

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            firstname: '',
            lastname: '',
            externalId: '',
            jobtitleId: '',
            genderId: '',
            departmentId: '',
            email: '',
            comment: '',
            jobtitle: '',
            user: [],
            genders: [],
            departments: [],
            jobtitles: [],

            selectedTagIds: [],
            tagOptions: [],
        };

        this.CreateUserValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onInputChange = (values, { action }) => {
        switch (action) {
            case 'select-option':
                this.setState({ 'selectedTagIds': Object.values(values).map(v => v.value) });
                return;
            default:
                return;
        }
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    getUnassignedTags() {
        axios(process.env.REACT_APP_API_URL + 'Tag/unassigned', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            let unassignedTags = Object.keys(response.data).map((key) => {
                                return { value: response.data[key]['tagId'], label: response.data[key]['tagName'], uid: response.data[key]['externalId'] }
                            });

                            if (this.props.bag) {
                                this.selectCustomOption = (
                                    <div className="row m-0 justify-content-end">
                                        <div className="col col-md-auto py-3">
                                            <button
                                                className="btn btn-full"
                                                onClick={() => openModal(
                                                    <CreateTag />,
                                                    {
                                                        'size': '600px',
                                                        'closeAction': (createdTag) => openModal(
                                                            <CreateUser {...this.props} createdTag={createdTag} state={this.state} />,
                                                            {
                                                                'size': '1000px',
                                                                'closeAction': (createdUser) => openModal(
                                                                    <UserToBag createdUser={createdUser} bag={this.props.bag} />,
                                                                    { 'size': '800px' })
                                                            })
                                                    })}>
                                                <i className="icon-tag" />{loca.TranslateHelper('create-tag-label')}
                                            </button>
                                        </div>
                                    </div>);
                            }
                            else {
                                this.selectCustomOption = (
                                    <div className="row m-0 justify-content-end">
                                        <div className="col col-md-auto py-3">
                                            <button
                                                className="btn btn-full"
                                                onClick={() => openModal(
                                                    <CreateTag />,
                                                    {
                                                        'size': '600px',
                                                        'closeAction': (createdTag) => openModal(
                                                            <CreateUser {...this.props} createdTag={createdTag} state={this.state} />,
                                                            {
                                                                'size': '900px'
                                                            })
                                                    })}>
                                                <i className="icon-tag" />{loca.TranslateHelper('create-tag-label')}
                                            </button>
                                        </div>
                                    </div>);
                            }

                            unassignedTags.unshift({ value: '__customOption', component: this.selectCustomOption, uid: '' });
                            this.setState({ tagOptions: unassignedTags });
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    saveUser() {
        if (this.CreateUserValidator.allValid()) {
            document.getElementById("saveUserBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'User/', {
                method: 'POST',
                data: {
                    'externalId': this.state.externalId,
                    'firstName': this.state.firstname,
                    'lastName': this.state.lastname,
                    'jobTitleId': this.state.jobtitleId,
                    'departmentId': this.state.departmentId,
                    'genderId': this.state.genderId,
                    'emailAddress': this.state.email,
                    'comment': this.state.comment,
                    'tagIds': null,
                },
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 201) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {

                                const userId = response.data.userId;

                                this.saveUserTags(userId);
                            }
                        } else {
                            toast.error('Error', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            document.getElementById("saveUserBTN").classList.remove("loading");
                        }
                    }
                )
                .catch(error => {
                    if (error.response) {
                        toast.error(error.response.data, {
                            position: toast.POSITION.TOP_CENTER,
                        });

                        document.getElementById("saveUserBTN").classList.remove("loading");
                    }
                });
        } else {
            document.getElementById("saveUserBTN").classList.remove("loading");
            this.CreateUserValidator.showMessages();
            this.forceUpdate();
        }
    }

    saveUserTags(userId) {
        if (this.CreateUserValidator.allValid()) {
            document.getElementById("saveUserBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'Assignment/UserTagAssignments', {
                method: 'PUT',
                data: {
                    'userId': userId,
                    'tagIds': this.state.selectedTagIds,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 204) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {

                                RefreshView(window.View.state.currentview);

                                toast.success(loca.TranslateHelper('create-user-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                if (window.userToBag) {
                                    window.Modal.props.closeAction(response.data);
                                } else {
                                    window.Modal.props.closeAction();
                                }
                                document.getElementById("saveUserBTN").classList.remove("loading");
                            }
                        } else {
                            toast.error('Error', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    console.log(error)
                });
        } else {
            this.EditUserValidator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this.getUnassignedTags();
        this.setState(this.props.state)

        if (this.props.createdTag) {
            this.setState({ 'selectedTagIds': [...this.props.state.selectedTagIds, this.props.createdTag.tagId] });
        }
    }

    render() {
        const filters = this.props;
        const selectedValues = this.state.tagOptions.filter(tagOption => this.state.selectedTagIds.some(tagId => tagId === tagOption.value));

        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('create-new-user-label')}</h2>
                <div className="row mt-4">
                    <div className="col col-md-8">
                        <h5 className="main mb-3">{loca.TranslateHelper('user-data-label')}</h5>
                        <div className="row">

                            <div className="col col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" name="lastname" id="lastname" placeholder={loca.TranslateHelper('lastname-label')} value={this.state.lastname || ''} onChange={this.onChange} />
                                        <label htmlFor="lastname">{loca.TranslateHelper('lastname-label')} <em>*</em></label>
                                    </div>
                                    {this.CreateUserValidator.message('lastname', this.state.lastname, 'required')}
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" name="firstname" id="firstname" placeholder={loca.TranslateHelper('firstname-label')} value={this.state.firstname || ''} onChange={this.onChange} />
                                        <label htmlFor="firstname">{loca.TranslateHelper('firstname-label')} <em>*</em></label>
                                    </div>
                                    {this.CreateUserValidator.message('firstname', this.state.firstname, 'required')}
                                </div>
                            </div>
                            <div className="col col-md-12">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" name="externalId" id="externalId" placeholder={loca.TranslateHelper('user-id-label')} value={this.state.externalId || ''} onChange={this.onChange} />
                                        <label htmlFor="externalId">{loca.TranslateHelper('user-id-label')} <em>*</em></label>
                                    </div>
                                    {this.CreateUserValidator.message('externalId', this.state.externalId, 'required')}
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <Select
                                            name="jobtitleId"
                                            id="jobtitleId"
                                            styles={SelectColours}
                                            className="SelectContainer"
                                            onChange={this.handleChange.bind(this, 'jobtitleId')}
                                            placeholder={loca.TranslateHelper('choose-job-title-label')}
                                            // eslint-disable-next-line
                                            value={filters.jobTitles.filter(option => option.value === this.state.jobtitleId)}
                                            options={filters.jobTitles} components={{}} />
                                        <label htmlFor="jobtitleId">{loca.TranslateHelper('job-title-label')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <Select
                                            name="genderId"
                                            id="genderId"
                                            styles={SelectColours}
                                            className="SelectContainer"
                                            onChange={this.handleChange.bind(this, 'genderId')}
                                            placeholder={loca.TranslateHelper('choose-gender-label')}
                                            // eslint-disable-next-line
                                            value={filters.genders.filter(option => option.value === this.state.genderId)}
                                            options={filters.genders} components={{}} />
                                        <label htmlFor="genderId">{loca.TranslateHelper('gender-label')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" name="email" id="email" placeholder={loca.TranslateHelper('email-label')} value={this.state.email || ''} onChange={this.onChange} />
                                        <label htmlFor="email">{loca.TranslateHelper('email-label')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <Select
                                            name="departmentId"
                                            id="departmentId"
                                            styles={SelectColours}
                                            className="SelectContainer"
                                            onChange={this.handleChange.bind(this, 'departmentId')}
                                            placeholder={loca.TranslateHelper('choose-department-label')}
                                            // eslint-disable-next-line
                                            value={filters.departments.filter(option => option.value === this.state.departmentId)}
                                            options={filters.departments} components={{}} />
                                        <label htmlFor="departmentId">{loca.TranslateHelper('department-label')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-12">
                                <div className="form-group">
                                    <div className="input-group textarea-group">
                                        <textarea id="comment" name="comment" placeholder={loca.TranslateHelper('comment-label')} defaultValue={this.state.comment || ''} onChange={this.onChange} />
                                        <label htmlFor="comment">{loca.TranslateHelper('comment-label')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-12">
                                <div className="form-group">
                                    <button id="saveUserBTN" className="btn btn-full" onClick={() => this.saveUser()}>{loca.TranslateHelper('save-label')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-md-4">
                        <h5 className="main mb-3">{loca.TranslateHelper('assign-to-tag-label')}</h5>
                        <div className="row mt-3">
                            <div className="col col-md-12">
                                <div className="form-group">
                                    <div className="input-group">
                                        <Select
                                            closeMenuOnSelect={false}
                                            isSearchable
                                            name="selectedTagIds"
                                            id="selectedTagIds"
                                            isMulti
                                            hideSelectedOptions={false}
                                            onChange={this.onInputChange}
                                            className="SelectContainer"
                                            value={selectedValues}
                                            styles={SelectColours}
                                            placeholder={loca.TranslateHelper('choose-tag-label')}
                                            options={this.state.tagOptions} components={{ ValueContainer: ValueContainer, Option: Option }} />
                                        <label htmlFor="tagId">{loca.TranslateHelper('tag-label')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.customFields,
    };
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(CreateUser)
