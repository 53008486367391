import React from "react";

import loca from "../utils/localization";
import { cva } from "class-variance-authority";
import { RReadonly } from "../utils/recursive-readonly";

interface Bag {
    bagId: string;
    room: string;
    systemName: string;
    bagNumber: string;
    building: string;
}

interface Props {
    bag: Bag;
    intent: "default" | "assigning" | "unassigning";
    onClick(bag: Bag): string;
}

const defaultIntent = "default"

const containerClass = cva("col bag-tile", {
    variants: {
        intent: {
            default: "",
            assigning: "brand-text-success",
            unassigning: "text-danger",
        }
    },
    defaultVariants: {
        intent: defaultIntent,
    }
})

const icon = cva("", {
    variants: {
        intent: {
            default: "icon-delete",
            assigning: "icon-delete",
            unassigning: "fa fa-undo",
        }
    },
    defaultVariants: {
        intent: defaultIntent,
    }
})

export function BagTile(props: RReadonly<Props>) {
    return <div
        key={props.bag.bagId}
        className={containerClass({ intent: props.intent })}>

        <div className="bag-tile-info">
            <div>
                <div key={props.bag.bagId}>
                    <span>{props.bag.building}&nbsp;/&nbsp;</span>
                    <span className="font-bold">{props.bag.room}</span>
                </div>
            </div>

            <div>
                <div>
                    <span><loca.Translate locaKey="system-label" />:&nbsp;</span>
                    <span className="font-bold">{props.bag.systemName}</span>
                </div>
                &nbsp;
                <div>
                    <span><loca.Translate locaKey="bag-number-label" />:&nbsp;</span>
                    <span className="font-bold">{props.bag.bagNumber}</span>
                </div>
            </div>
        </div>

        <div className="bag-tile-action">
            <button onClick={() => props.onClick(props.bag)}>
                <i className={icon({ intent: props.intent })} />
            </button>
        </div>

    </div>
}
