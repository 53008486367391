import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { changeLanguageCode, Logout } from "../../Helpers";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

const setLoginLocal = async (loginData) => {
    try {
        // UPDATE LOGIN DATA

        window.localStorage.setItem("loginData", JSON.stringify(loginData.data));
        //await AsyncStorage.setItem('loginData', JSON.stringify(loginData.data));
    } catch (err) {
        console.log(err);
    }
};

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            password: '',
            password2: ''
        };

        this.ChangePasswordValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    changePassword() {
        if (this.ChangePasswordValidator.allValid()) {
            document.getElementById("changePasswordBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'AppUser/Profile/Password', {
                method: 'POST',
                data: {
                    'currentPassword': this.state.currentPassword,
                    'password': this.state.password,
                    'password2': this.state.password2
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {
                                axios(process.env.REACT_APP_API_URL + 'AppUser', {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                                        'Accept-Language': changeLanguageCode(loca.language)
                                    },
                                    withCredentials: true,
                                })
                                    .then(
                                        response => {
                                            if (response.status !== 200) {
                                                Logout();
                                            } else {
                                                const o = { ...response, userId: response.data.userId };
                                                setLoginLocal(o);

                                                toast.success(loca.TranslateHelper('save-success-label'), {
                                                    position: toast.POSITION.TOP_CENTER,
                                                });

                                                setTimeout(function () {
                                                    window.location.reload(false)
                                                }, 2000);
                                            }
                                        }
                                    )
                                    .catch(error => {
                                        this.setState({ errorMessage: error.message });
                                        document.getElementById("changePasswordBTN").classList.remove("loading");
                                    });
                            }
                        } else {
                            toast.error('Error', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    //console.log(error.response.data[0].description);
                    document.getElementById("changePasswordBTN").classList.remove("loading");

                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        } else {
            this.ChangePasswordValidator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (
            <div>
                <h2 className="main tc">{loca.TranslateHelper('change-password-label')}</h2>
                <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <div className="input-group">
                                <input type="password" name="currentPassword" id="currentPassword" placeholder={loca.TranslateHelper('current-password-label')} onChange={this.onChange} />
                                <label htmlFor="currentPassword">{loca.TranslateHelper('current-password-label')}</label>
                            </div>
                            {this.ChangePasswordValidator.message('password', this.state.currentPassword, 'required')}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input type="password" name="password" id="password" placeholder={loca.TranslateHelper('new-password-label')} onChange={this.onChange} />
                                <label htmlFor="password">{loca.TranslateHelper('new-password-label')}</label>
                            </div>
                            {this.ChangePasswordValidator.message('password', this.state.password, 'required|min:8,string')}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input type="password" name="password2" id="password2" placeholder={loca.TranslateHelper('password-confirm-label')} onChange={this.onChange} />
                                <label htmlFor="password2">{loca.TranslateHelper('password-confirm-label')}</label>
                            </div>
                            {this.ChangePasswordValidator.message('password2', this.state.password2, `required|in:${this.state.password}`)}
                        </div>
                        <div className="form-group error-group">
                            {this.state.errorMessage}
                        </div>
                        <div className="form-group">
                            <button id="changePasswordBTN" className="btn btn-full mt-3" onClick={() => this.changePassword()}>{loca.TranslateHelper('save-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ChangePassword);
