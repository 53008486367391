import axios from "axios";

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "application/json"
    }
});

class UploadFilesService {
    upload(file, dataType, onUploadProgress) {
        let formData = new FormData();

        var endpoint = '';
        if(dataType === 'userTemplate'){
            endpoint = 'provisionUser';
            formData.append("userProvisionFile", file);
        }
        if(dataType === 'tagTemplate'){
            endpoint = 'provisionTag';
            formData.append("tagProvisionFile", file);
        }

        return http.post("/Provision/"+endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
            },
            withCredentials: true,
            credentials: 'same-origin',
            onUploadProgress,
        });
    }
}

export default new UploadFilesService();
