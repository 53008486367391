import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { changeLanguageCode, Logout } from "../../Helpers";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

class EditSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemId: this.props.systemId,
            systemName: this.props.system.systemName,
            systemSerialNumber: this.props.system.systemSerialNumber,
            serialNumber: this.props.system.deviceSerialNumber,
            iotHubDeviceId: this.props.system.iotHubDeviceId,
            active: String(this.props.system.isActive),
            communicationMode: null
        };

        this.EditSystemValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    saveSystem() {
        if (this.EditSystemValidator.allValid()) {
            document.getElementById("saveSystemBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'System/' + this.state.systemId, {
                method: 'PUT',
                data: {
                    "deviceSerialNumber": this.state.serialNumber,
                    "systemName": this.state.systemName,
                    "systemSerialNumber": this.state.systemSerialNumber,
                    "iotHubDeviceId": this.state.iotHubDeviceId,
                    "isActive": this.state.active,
                },
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 202) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {
                                if (window.Systems) {
                                    window.Systems.getSystems();
                                }

                                toast.success(loca.TranslateHelper('system-saved-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                window.Modal.props.closeAction();
                                document.getElementById("saveSystemBTN").classList.remove("loading");
                            }
                        }
                        else {
                            toast.error('There was an error!', {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            document.getElementById("saveSystemBTN").classList.remove("loading");
                        }
                    }
                )
                .catch(error => {
                    console.log(error);
                    document.getElementById("saveSystemBTN").classList.remove("loading");
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        } else {
            document.getElementById("saveSystemBTN").classList.remove("loading");
            this.EditSystemValidator.showMessages();
            this.forceUpdate();
        }
    }

    getInfo() {
        axios(process.env.REACT_APP_API_URL + 'System/communicationInfo', {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ communicationMode: loca.TranslateHelper(response.data.communicationMode + '-label') })
                        }
                    }
                    else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                console.log(error);
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    componentDidMount() {
        this.getInfo();
    }

    render() {
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('edit-system-label')}</h2>
                <div className="row subrow mb-2">
                    <div className={'col-auto'}>
                        {loca.TranslateHelper('system-serial-number-label')}: {this.props.system.systemSerialNumber}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col col-md-12">
                        <form>
                            <div className="row">
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="systemName" id="systemName" placeholder={loca.TranslateHelper('system-name-label')} onChange={this.onChange} readOnly={true} value={this.state.systemName || ''} />
                                            <label htmlFor="systemName">{loca.TranslateHelper('system-name-label')}</label>
                                        </div>
                                        {this.EditSystemValidator.message('systemName', this.state.systemName, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="systemSerialNumber" id="systemSerialNumber" placeholder={loca.TranslateHelper('system-serial-number-label')} onChange={this.onChange} value={this.state.systemSerialNumber} />
                                            <label htmlFor="systemSerialNumber">{loca.TranslateHelper('system-serial-number-label')}</label>
                                        </div>
                                        {this.EditSystemValidator.message('systemSerialNumber', this.state.systemSerialNumber, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="serialNumber" id="serialNumber" placeholder={loca.TranslateHelper('device-serial-number-label')} onChange={this.onChange} value={this.state.serialNumber || ''} />
                                            <label htmlFor="serialNumber">{loca.TranslateHelper('device-serial-number-label')} <em>*</em></label>
                                        </div>
                                        {this.EditSystemValidator.message('serialNumber', this.state.serialNumber, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="iotHubDeviceId" id="iotHubDeviceId" placeholder={this.state.communicationMode || loca.TranslateHelper('azurecloud-label')} onChange={this.onChange} value={this.state.iotHubDeviceId} />
                                            <label htmlFor="iotHubDeviceId">{this.state.communicationMode || loca.TranslateHelper('azurecloud-label')} <em>*</em></label>
                                        </div>

                                        {this.EditSystemValidator.message('iotHubDeviceId', this.state.iotHubDeviceId, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12 py-3">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col col-md-6">
                                                <div className="checkbox-container">
                                                    <input type="radio" id={'status-1'} name="active" className="inp-cbx" value={"true"} checked={this.state.active === "true" ? 'checked' : ''} onChange={this.onChange} />
                                                    <label className="cbx" htmlFor={'status-1'}>
                                                        <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1" /></svg></span>
                                                        <span>{loca.TranslateHelper('active-label')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col col-md-6">
                                                <div className="checkbox-container">
                                                    <input type="radio" id={'status-0'} name="active" className="inp-cbx" value={"false"} checked={this.state.active === "true" ? '' : 'checked'} onChange={this.onChange} />
                                                    <label className="cbx" htmlFor={'status-0'}>
                                                        <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1" /></svg></span>
                                                        <span>{loca.TranslateHelper('inactive-label')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <button type="button" id="saveSystemBTN" className="btn btn-full" onClick={() => this.saveSystem()}>{loca.TranslateHelper('save-label')}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EditSystem);
