import React, { Component } from 'react';
import { Modal, ModalManager } from 'react-dynamic-modal';
import loca from "./utils/localization";
import axios from "axios";
import { store } from "./index";
import SimpleReactValidator from "simple-react-validator";
import { components } from "react-select";
import { toast } from "react-toastify";
import { Provider } from "react-redux";

SimpleReactValidator.addLocale('en', {
    required: 'This field is required',
    numeric: 'This field must be a number',
    email: 'Please enter valid email address',
    min: 'This field must be at least 8 characters.',
    max: 'This field must be at most 16 characters.',
    in: 'Passwords need to match',
    alpha_num: 'This field may only contains letters and numbers',
    regex: 'Please provide RFID UID in Hexadecimal format.',
    default: 'Validation has failed!'
});

SimpleReactValidator.addLocale('de', {
    required: 'Dieses Feld ist erforderlich',
    numeric: 'Dieses Feld muss eine Nummer beinhalten',
    email: 'Bitte gültige email angeben',
    min: 'Dieses Feld darf minimal 8 Zeichen beinhalten.',
    max: 'Dieses Feld darf maximal 16 Zeichen beinhalten.',
    in: 'Passwörter müssen übereinstimmen',
    alpha_num: 'Dieses Feld darf nur Buchstaben und Zahlen enthalten.',
    regex: 'Bitte geben Sie die RFID-UID im Hexadezimalformat an.',
    default: 'Validierung fehlgeschlagen'
});

export const validator = new SimpleReactValidator({
    locale: loca.language,
})


export function Logout() {
    localStorage.removeItem('loginData');
    localStorage.removeItem('amsData');
    window.location.reload(false);
}

export function RefreshView(view) {
    switch (view) {
        case 'bags':
            window.BagsTable.setState({ enablePaging: false });
            window.BagsTable.appendBags(true)
            break;

        case 'users':
            window.UsersTable.setState({ enablePaging: false });
            window.UsersTable.appendUsers(true)
            break;

        case 'tags':
            window.TagsTable.setState({ enablePaging: false });
            window.TagsTable.appendTags(true)
            break;

        case 'search':
            window.View.tagSearch()
            window.View.userSearch()
            window.View.bagsSearch()
            break;

        // Should never reach
        default:
            break;
    }
}

export function getBagStatusLabel(status) {
    switch (status) {
        case 0:
            return "free-label";

        case 1:
            return "assigned-label";

        case 2:
            return "out-of-use-label";

        case 3:
            return "cleaning-label";

        case 99:
            return "pending-lanel";

        // Should never reach
        default:
            return "n/a"
    }
}

export function changeLanguageCode(code) {
    switch (code) {
        case 'en':
            return 'en-GB';
        case 'de':
            return 'de-DE';
        case 'fr':
            return 'fr-FR';
        default:
            return 'en-GB';
    }
}

export function getBagsTooltip(id) {
    switch (id) {
        case 0:
        default:
            return 'free-state-tooltip-label'
        case 1:
            return 'assigned-state-tooltip-label'
        case 2:
            return 'tag-missing-state-tooltip-label'
        case 3:
            return 'cleaning-state-tooltip-label'
    }
}

export function tagTypeToString(tagType) {
    const value = TagTypesLabels[tagType];
    const translations = loca.getDataByLanguage(loca.language);
    return translations.translations[value]
}

export let TagTypes = {
    1: loca.TranslateHelper('normal-tag-label'),
    2: loca.TranslateHelper('visitor-tag-label'),
    3: loca.TranslateHelper('master-tag-label'),
    7: loca.TranslateHelper('cleaning-tag-label'),
    //4 : Translate('selection-mode-label')
}

export let TagTypesLabels = {
    1: 'normal-tag-label',
    2: 'visitor-tag-label',
    3: 'master-tag-label',
    7: 'cleaning-tag-label',
    //4 : 'selection-mode-tag-label'
}

export let UserTypes = {
    0: 'Officer',
    1: 'Admin',
    2: 'SuperAdmin'
}

export let TagTypesFromHelpers = Object.keys(TagTypes).map((key) => {
    return { value: key, label: TagTypes[key] }
});
TagTypesFromHelpers.unshift({ value: '', label: loca.TranslateHelper('all-label') });


export let UserTypesFromHelpers = Object.keys(UserTypes).map((key) => {
    return { value: key, label: UserTypes[key] }
});

export function filter_getGenders() {
    axios(process.env.REACT_APP_API_URL + 'CustomFields/genders', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
            'Accept-Language': changeLanguageCode(loca.language)
        },
        withCredentials: true,
        credentials: 'same-origin',
    })
        .then(
            response => {
                if (response.status === 200) {
                    if (response.data === 'User not logged in.') {
                        Logout();
                    } else {

                        let gendersFromApi = response.data.map(gender => {
                            return { value: gender.genderId, label: gender.genderName }
                        });

                        gendersFromApi.unshift({ value: '', label: loca.TranslateHelper('all-label') });

                        store.dispatch({
                            type: 'ADD_GENDER',
                            obj: gendersFromApi
                        })
                    }
                } else {
                }
            }
        )
        .catch(error => {
        });
}
export function filter_getDepartments() {
    axios(process.env.REACT_APP_API_URL + 'CustomFields/departments', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
            'Accept-Language': changeLanguageCode(loca.language)
        },
        withCredentials: true,
        credentials: 'same-origin',
    })
        .then(
            response => {
                if (response.status === 200) {
                    if (response.data === 'User not logged in.') {
                        Logout();
                    } else {

                        let departmentsFromApi = response.data.map(department => {
                            return { value: department.departmentId, label: department.departmentName }
                        });

                        departmentsFromApi.unshift({ value: '', label: loca.TranslateHelper('all-label') });

                        store.dispatch({
                            type: 'ADD_DEPARTMENT',
                            obj: departmentsFromApi
                        })
                    }
                } else {
                }
            }
        )
        .catch(error => {
        });
}
export function filter_getJobTitles() {
    axios(process.env.REACT_APP_API_URL + 'CustomFields/jobTitles', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
            'Accept-Language': changeLanguageCode(loca.language)
        },
        withCredentials: true,
        credentials: 'same-origin',
    })
        .then(
            response => {
                if (response.status === 200) {
                    if (response.data === 'User not logged in.') {
                        Logout();
                    } else {

                        let jobtitlesFromApi = response.data.map(jobtitle => {
                            return { value: jobtitle.jobTitleId, label: jobtitle.jobTitleName }
                        });

                        jobtitlesFromApi.unshift({ value: '', label: loca.TranslateHelper('all-label') });

                        store.dispatch({
                            type: 'ADD_JOBTITLE',
                            obj: jobtitlesFromApi
                        })
                    }
                } else {
                }
            }
        )
        .catch(error => {
        });
}

export const userTagStates = [
    { label: loca.TranslateHelper('all-label'), value: '' },
    { label: loca.TranslateHelper('with-tag-label'), value: 1 },
    { label: loca.TranslateHelper('no-tags-label'), value: 0 },
    { label: loca.TranslateHelper('deletion-pending-label'), value: 99 }
];

export const tagStates = [
    { label: loca.TranslateHelper('all-label'), value: '' },
    { label: loca.TranslateHelper('free-label'), value: 1 },
    { label: loca.TranslateHelper('assigned-label'), value: 0 }
];

export function getConfig() {
    axios(process.env.REACT_APP_API_URL + 'Tenant/configuration/' + JSON.parse(localStorage.getItem('loginData')).tenantId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
            'Accept-Language': changeLanguageCode(loca.language)
        },
        withCredentials: true,
    })
        .then(
            response => {
                if (response.status === 200) {
                    if (response.data === 'User not logged in.') {
                        Logout();
                    } else {
                        window.localStorage.setItem("configData", JSON.stringify(response.data));
                    }
                } else {
                    toast.error('error', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        )
        .catch(error => {
            toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
            });
        });
}

export function openModal(component, params) {
    window.Header.clearSearch();
    ModalManager.open(<MyModal size={(params ? params.size ? params.size : params.size : '600px')} component={component} closeAction={params ? params.closeAction ? params.closeAction : ModalManager.close : ModalManager.close} onRequestClose={() => false} />);
}

export const Option = ({ data, innerProps, isDisabled, isSelected }) =>
    !isDisabled ? (
        <div {...innerProps}>
            {data.value === '__customOption' ? data.component : (<div className="row custom-dropdown mx-0 align-items-center">
                <div className="col col-md-12">
                    <div className="checkbox-container">
                        <input type="checkbox" id={'option-' + data.value} className="inp-cbx" value={data.value} checked={isSelected ? 'checked' : ''} />
                        <label className="cbx">
                            <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1" /></svg></span>
                            <span className="ellipsis">{data.label}</span>
                        </label>
                    </div>
                </div>
                <div className="col col-md-12 uid text-left ellipsis pl-5">[UID : {data.uid}]</div>
            </div>)
            }


        </div>
    ) : null;


export class MyModal extends Component {
    render() {
        const { component, onRequestClose, size, closeAction } = this.props;
        window.Modal = this;

        const modalAnim = {
            transition: { // transition for the modal window
                property: 'all',
                duration: 300, // millisecond
                timingfunction: 'cubic-bezier(0.16, 1, 0.3, 1)'
            },
            begin: { //beginning style of transition
                'transform': 'translateY(-100vh)',
                'opacity': 0
            },
            end: { // end style of transition
                'transform': 'translateY(0)',
                'opacity': 1
            }
        }
        const style = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999,
                overflow: 'auto',
                perspective: 1300,
                backgroundColor: 'rgba(0,0,0,0.30)'
            },

            content: {
                position: 'relative',
                margin: '10% auto',
                width: '80%',
                border: 'none',
                background: '#fff',
                overflow: 'visible',
                borderRadius: '0px',
                outline: 'none',
                boxShadow: 'none',
                padding: '50px',
                maxWidth: size
            }
        }

        return (
            <loca.I18nextProvider i18n={loca.i18n}>
                <Provider store={store}>
                    <Modal
                        onRequestClose={onRequestClose}
                        effect={modalAnim}
                        style={style}>
                        <div className="modal-box">
                            <button className="modal-close" onClick={closeAction}><i className="icon-close" /></button>
                            {component}
                        </div>
                    </Modal>
                </Provider>
            </loca.I18nextProvider>
        );
    }
}

export const ValidatorMessages = {
    messages: {
        email: <loca.Translate locaKey={'please-enter-valid-email-label'} />,
        required: <loca.Translate locaKey={'this-field-is-required-label'} />
    }
}


export const ValueContainer = ({ children, getValue, ...props }) => {

    let maxToShow = 1;
    let displayLength = 0;
    var length = 0;

    let values = getValue();

    Object.keys(values).forEach(function (objectKey) {
        if (values[objectKey].value && values[objectKey].value !== '__customOption') {
            length++;
        }
    });

    let title = getValue()
        .slice(maxToShow)
        .map((x) => x.label)
        .join(", ");

    displayLength = length;

    return (
        <components.ValueContainer {...props}>
            {React.Children.map(children, child => !props.hasValue || child.type === components.Input ? child : null)}
            {displayLength !== 0 ? <div className="col p-1 selected-tag"
                title={title}>{`${displayLength} `} {loca.TranslateHelper('selected-label')}</div> : ''}

        </components.ValueContainer>
    );
};


export const bagSelectorValueContainer = ({ children, getValue, ...props }) => {

    let maxToShow = 1;
    let displayLength = 0;
    var length = 0;

    let values = getValue();

    Object.keys(values).forEach(function (objectKey) {
        if (values[objectKey].value && values[objectKey].value !== '__customOption') {
            length++;
        }
    });

    let title = getValue()
        .slice(maxToShow)
        .map((x) => x.label)
        .join(", ");

    displayLength = length;

    return (
        <components.ValueContainer {...props}>
            {React.Children.map(children, child => !props.hasValue || child.type === components.Input ? child : null)}
            {displayLength !== 0 ? <div className="col p-1 selected-tag"
                title={title}>{`${displayLength} `} {loca.TranslateHelper('selected-label')}</div> : ''}

        </components.ValueContainer>
    );
};

