import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import axios from "axios";
import {
    changeLanguageCode,
    filter_getDepartments,
    filter_getJobTitles,
    Logout,
    openModal,
    validator
} from "../Helpers";
import {toast} from "react-toastify";
import DeleteJobTitle from "../modals/System/DeleteJobTitle";
import DeleteDepartment from "../modals/System/DeleteDepartment";
import loca from "../utils/localization"
import EditJobTitle from "../modals/System/EditJobTitle";
import EditDepartment from "../modals/System/EditDepartment";

class CustomFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            department:'',
            jobtitle:'',
            departments:[],
            jobtitles:[]
        };

        this.validator = validator

        window.CustomFields = this;
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        this.getDepartments();
        this.getJobTitles();
    }

    getDepartments(){
        axios(process.env.REACT_APP_API_URL+'CustomFields/departments',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else {

                            let departments = Object.keys(response.data).map((key)=> {
                                return {value: response.data[key]['departmentId'], label: response.data[key]['departmentName']}
                            });

                            this.setState({ departments: departments });
                            filter_getDepartments();
                        }
                    }else{
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    getJobTitles(){
        axios(process.env.REACT_APP_API_URL+'CustomFields/jobTitles',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else {

                            let jobtitles = Object.keys(response.data).map((key)=> {
                                return {value: response.data[key]['jobTitleId'], label: response.data[key]['jobTitleName']}
                            });

                            this.setState({ jobtitles: jobtitles });
                            filter_getJobTitles()
                        }
                    }else{
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    addDepartment(){
        if (this.validator.fieldValid('department')) {
            document.getElementById("createDepartmentBTN").classList.add("loading");

            axios(process.env.REACT_APP_API_URL+'CustomFields/department',{
                method: 'POST',
                data:{
                    'departmentName' : this.state.department,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if(response.status === 201){
                            if(response.data === 'User not logged in'){
                                Logout();
                            }else {
                                this.getDepartments();

                                toast.success(loca.TranslateHelper('save-department-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                this.setState({'department': ''});
                                document.getElementById("createDepartmentBTN").classList.remove("loading");
                            }
                        }else{
                            toast.error('ERROR', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    document.getElementById("createDepartmentBTN").classList.remove("loading");
                });
        } else {
            this.validator.showMessageFor('department');
            this.forceUpdate();
        }
    }
    addJobTitle(){
        if (this.validator.fieldValid('jobtitle')) {
            document.getElementById("createJobTitleBTN").classList.add("loading");

            axios(process.env.REACT_APP_API_URL+'CustomFields/jobTitle',{
                method: 'POST',
                data:{
                    'jobTitleName' : this.state.jobtitle,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if(response.status === 201){
                            if(response.data === 'User not logged in'){
                                Logout();
                            }else {
                                this.getJobTitles();

                                toast.success(loca.TranslateHelper('save-jobtitle-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });
                                this.setState({'jobtitle': ''});

                                document.getElementById("createJobTitleBTN").classList.remove("loading");
                            }
                        }else{
                            toast.error('ERROR', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    document.getElementById("createJobTitleBTN").classList.remove("loading");
                });
        } else {
            this.validator.showMessageFor('jobtitle');
            this.forceUpdate();
        }
    }
    render() {
        const {t} = this.props
        return (
            <div>
                <Header headerTitle={loca.TranslateHelper('side-settings')} subtitle={loca.TranslateHelper('side-customFields')} enableStat={false}/>

                <div className="container-div mt-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div><h5 className="main mb-3">{loca.TranslateHelper('departments-label')}</h5></div>

                            <div className="new-item">
                                <div className="form-group">
                                    <div className="input-group-simple row">
                                        <input type="text" id="department" name="department" placeholder={t('add-new-label')} className="col col-md-7" value={this.state.department} onChange={this.onChange}/>
                                        <button id="createDepartmentBTN" type="button" className="col col-md-5 btn btn-full" onClick={()=>this.addDepartment()}><i className="icon-plus"/>{loca.TranslateHelper('create-label')}</button>
                                    </div>
                                    {this.validator.message('department', this.state.department, 'required')}
                                </div>
                            </div>

                            <ul id="departments" className="tagList mt-5">
                                {this.state.departments.map((item,index) => (
                                    <li className="pl-1" key={item.value}>{item.label}
                                        <button onClick={()=>openModal(<EditDepartment departmentName={item.label} departmentId={item.value}/>,{'size':'600px'})}><i className="icon-edit ml-3"/></button>
                                        <button onClick={()=>openModal(<DeleteDepartment departmentName={item.label} departmentId={item.value}/>,{'size':'600px'})}><i className="icon-delete"/></button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="col-md-6">
                            <div><h5 className="main mb-3">{loca.TranslateHelper('jobtitles-label')}</h5></div>

                            <div className="new-item">
                                <div className="form-group">
                                    <div className="input-group-simple row">
                                        <input type="text" id="jobtitle" name="jobtitle" placeholder={t('add-new-label')} className="col col-md-7" value={this.state.jobtitle} onChange={this.onChange}/>
                                        <button id="createJobTitleBTN" type="button" className="col col-md-5 btn btn-full" onClick={()=>this.addJobTitle()}><i className="icon-plus"/>{loca.TranslateHelper('create-label')}</button>
                                    </div>

                                    {this.validator.message('jobtitle', this.state.jobtitle, 'required')}
                                </div>
                            </div>

                            <ul id="jobtitles" className="tagList mt-5">
                                {this.state.jobtitles.map((item,index) => (
                                    <li className="pl-1" key={item.value}>{item.label}
                                        <button onClick={()=>openModal(<EditJobTitle jobTitleName={item.label} jobTitleId={item.value}/>,{'size':'600px'})}><i className="icon-edit ml-3"/></button>
                                        <button onClick={()=>openModal(<DeleteJobTitle jobTitleName={item.label} jobTitleId={item.value}/>,{'size':'600px'})}><i className="icon-delete"/></button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CustomFields)
