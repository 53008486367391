import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import TagsTable from "../components/TagsTable";
import { openModal } from "../Helpers";
import CreateTag from "../modals/Tags/CreateTag";
import ImportCSV from "../modals/Users/ImportCSV";
import { ModalManager } from "react-dynamic-modal";
import loca from "../utils/localization";

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentview: 'tags'
        };

        window.View = this;
    }
    render() {

        const addTagsButton = <div>
            <button className="btn btn-full"
                onClick={() => openModal(
                    <CreateTag />,
                    { 'size': '600px' }
                )}>
                <i className="icon-plus" />
                {loca.TranslateHelper('create-tag-label')}
            </button>

            <button className="ml-3 btn btn-full"
                onClick={() => openModal(
                    <ImportCSV dataType={'tagTemplate'} />,
                    { 'size': '400px', 'closeAction': ModalManager.close() }
                )}>
                <i className="icon-upload" />
                {loca.TranslateHelper('import-label')}
            </button>
        </div>

        return (
            <div>
                <Header
                    headerTitle={loca.TranslateHelper('side-tags')}
                    filter="TagsFilter"
                    subtitle={addTagsButton}
                    enableStat={true}
                    headerActions
                    page={"tags"}
                    value1Label={loca.TranslateHelper('free-label')}
                    value2Label={loca.TranslateHelper('assigned-label')}
                    labelTotal={loca.TranslateHelper('total-number-of-tags-label')}
                />

                <TagsTable />
            </div>
        )
    }
}

export default withTranslation()(Tags);
