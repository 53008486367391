import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {changeLanguageCode, Logout, RefreshView} from "../../Helpers";
import loca from "../../utils/localization"

class RemoveUserFromBag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bagToRemove: [],
            activateCleaningState: JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled ? true : false
        }
    }
    removeAssign(userId){
        const self = this;
        Promise.resolve(self.setBagInfo()).then(function(result) {
            document.getElementById("deleteBTN").classList.add("loading");

            axios(process.env.REACT_APP_API_URL+'Assignment/removeMultipleAssignments',{
                method: 'DELETE',
                data:self.state.bagToRemove,
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if(response.status === 204){
                            if(response.data === 'User not logged in'){
                                Logout()
                            }else{
                                document.getElementById("deleteBTN").classList.remove("loading");

                                window.Modal.props.closeAction();
                                RefreshView(window.View.state.currentview);
                                toast.success(loca.TranslateHelper('remove-user-from-bag-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                            }
                        }else{
                            if(document.getElementById("deleteBTN")){
                                document.getElementById("deleteBTN").classList.remove("loading");
                            }
                            toast.error(response, {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    if(document.getElementById("deleteBTN")){
                        document.getElementById("deleteBTN").classList.remove("loading");
                    }
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                })
        });
    }

    componentDidMount() {
        this.setBagInfo()
    }

    setBagInfo(){
        const bags = []
        if(this.props.bag.length >=1){
            Object.keys(this.props.bag).map(key =>
                bags.push({bagId:this.props.bag[key].bagId,userId: this.props.userId, activateCleaningState: this.state.activateCleaningState})
            )
        }else{
            bags.push({bagId:this.props.bag.bagId,userId: this.props.userId, activateCleaningState: this.state.activateCleaningState})
        }
        this.setState({bagToRemove:bags});
    }

    changeCleaningState = (e) => {
        this.setState({activateCleaningState: !this.state.activateCleaningState});
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt=""/>
                <h2 className="main tc">{t('remove-user-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {t('confirm-remove-user-from-bag-label',{user: this.props.userName})}
                        {JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled && <div className="checkbox-container mt-4 mb-2">
                            <input type="checkbox" id={'activateCleaningState'} name="activateCleaningState" className="inp-cbx" checked={this.state.activateCleaningState === true ? 'checked' : ''} onChange={this.changeCleaningState}/>
                            <label className="cbx" htmlFor={'activateCleaningState'}>
                                <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1"/></svg></span>
                                <span className={'text-left'}>{loca.TranslateHelper('place-to-cleaning-state-label')}</span>
                            </label>
                        </div>}
                        <div className="form-group">
                            <button id="deleteBTN" className="btn btn-full mt-3" onClick={() => this.removeAssign(this.props.userId)}>{t('remove-user-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(RemoveUserFromBag);
