import React from 'react';
import loading from '../images/svg/loading.gif';

export default function ShowMore(props) {
    return (
        <div className={'text-center py-5'}>
            <img src={loading} width={'20px'} alt="Loading"/>
        </div>
    )
}
