import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {changeLanguageCode, Logout} from "../../Helpers";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

class EditJobTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobTitleId : props.jobTitleId,
            jobTitleName : props.jobTitleName
        };

        this.JobTitleValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChange(stateId,e){
        this.setState({[stateId] : e.value})
    }

    saveJobTitle() {
        if (this.JobTitleValidator.allValid()) {
            document.getElementById("saveJobTitleBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL+'CustomFields/jobTitle',{
                method: 'PUT',
                data:{
                    "jobTitleId": this.state.jobTitleId,
                    "jobTitleName": this.state.jobTitleName
                },
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        console.log(response)
                        if(response.status === 200){
                            if(response.data === 'User not logged in.'){
                                Logout();
                            }else{

                                toast.success(loca.TranslateHelper('jobtitle-saved-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                window.Modal.props.closeAction();
                                window.CustomFields.getJobTitles();
                                document.getElementById("saveJobTitleBTN").classList.remove("loading");
                            }
                        }
                        else{
                            toast.error('There was an error!', {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            document.getElementById("saveJobTitleBTN").classList.remove("loading");
                        }
                    }
                )
                .catch(error => {
                    console.log(error);
                    document.getElementById("saveJobTitleBTN").classList.remove("loading");
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        } else {
            document.getElementById("saveJobTitleBTN").classList.remove("loading");
            this.JobTitleValidator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('edit-job-title-label')}</h2>
                <div className="row mt-4">
                    <div className="col col-md-12">
                        <form>
                            <div className="row">
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="jobTitleName" id="jobTitleName" placeholder= { loca.TranslateHelper('job-title-label') } onChange={this.onChange} value={this.state.jobTitleName || ''}/>
                                            <label htmlFor="jobTitleName">{loca.TranslateHelper('job-title-label')}</label>
                                        </div>
                                        {this.JobTitleValidator.message('jobTitleName', this.state.jobTitleName, 'required')}
                                    </div>
                                </div>

                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <button type="button" id="saveJobTitleBTN" className="btn btn-full" onClick={() => this.saveJobTitle()}>{ loca.TranslateHelper('save-label') }</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EditJobTitle);
