import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import "../css/main.scss";
import {BrowserRouter as Router, Route} from "react-router-dom";
import LockerBags from "./LockerBags";
import Users from "./Users";
import Tags from "./Tags";
import Profile from "./Profile";
import FloorPlan from "./FloorPlan";
import Synch from "./Synch";
import CustomFields from "./CustomFields";
import SideComponent from "../components/Side";
import UserManagement from "./UserManagement";
import Systems from "./Systems";
import Search from "./Search";

import {
    Logout,
    filter_getGenders,
    filter_getDepartments,
    filter_getJobTitles,
} from "../Helpers";
import axios from "axios";
import Cleaning from "./Cleaning";

class Desktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantName: '',
            showMenu: true, // SHOW SIDEMENU
            headerVisibility: true, // SHOW HEADER
            timeout:1000 * 60 * 10, // IDLE TIMEOUT, LOGIN AFTER
            isTimedOut: false, // IDLE LOGOUT
            showSearch: false // SHOW SEARCH BAR
        };

        window.Desktop = this;

        this.toggleMenu = this.toggleMenu.bind(this);
        this.showHeader = this.showHeader.bind(this);
        this.expandMenu = this.expandMenu.bind(this);

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    _onAction(e) {
        this.setState({isTimedOut: false})
    }

    _onActive(e) {
        this.setState({isTimedOut: false})
    }

    _onIdle(e) {
        this.refreshUser();
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            Logout();
        } else {
            this.idleTimer.reset();
            this.setState({isTimedOut: true})
        }

    }

    getTenant(){
        axios(process.env.REACT_APP_API_URL+'Tenant/'+JSON.parse(localStorage.getItem('loginData')).tenantId,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        this.setState({tenantName: response.data.tenantName})
                    }else{
                    }
                }
            )
            .catch(error => {
            });
    }

    refreshUser = function(){
        // LOGOUT USER IF SESSION EXPIRED
        axios(process.env.REACT_APP_API_URL+'AppUser',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        Logout();
                    }
                }
            )
            .catch(error => {
            });
    }

    toggleMenu = function() {
        this.setState({ showMenu: !this.state.showMenu }); // TOGGLE SIDEMENU
    }
    expandMenu = function() {
        this.setState({ showMenu: true }); // TOGGLE SIDEMENU
    }
    showHeader = function() {
        this.setState({ headerVisibility: true }); // SHOW HEADER
    }

    componentDidMount() {
        // GET FILTER AND SEARCH ELEMENTS
        filter_getGenders();
        filter_getDepartments();
        filter_getJobTitles();

        this.getTenant()

        setTimeout(function(){
            window.Desktop.setState({'showSearch' : true}); // SHOW SEARCH
        },500);
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout} />

                <Router>
                    <SideComponent toggleMenu={this.toggleMenu} expandMenu={this.expandMenu} showMenu={this.state.showMenu}  />
                    <main className={`${this.state.showMenu || this.state.openSystem ? "" : "full"}`}>
                        <Route exact path="/"><LockerBags /></Route>
                        <Route exact path="/locker-bags"><LockerBags /></Route>
                        <Route exact path="/users"><Users /></Route>
                        <Route exact path="/profile"><Profile /></Route>
                        <Route exact path="/tags"><Tags /></Route>
                        <Route exact path="/floorplan"><FloorPlan /></Route>
                        <Route exact path="/synch"><Synch /></Route>
                        <Route exact path="/search/:category/:text" component={Search}></Route>
                        <Route exact path="/settings/custom-fields"><CustomFields /></Route>
                        <Route exact path="/settings/systems"><Systems /></Route>
                        <Route exact path="/settings/cleaning"><Cleaning /></Route>
                        {JSON.parse(localStorage.getItem('loginData')).role !== 'Officer' ? <Route exact path="/settings/user-management"><UserManagement /></Route> : ''}
                </main>
                </Router>
            </div>
        )
    }
}

export default Desktop
