import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { openModal } from '../Helpers'
import "../css/main.scss";
import Header from "../modules/Header";
import UsersTable from "../components/UsersTable";
import CreateUser from "../modals/Users/CreateUser";
import { ModalManager } from 'react-dynamic-modal';
import ImportCSV from "../modals/Users/ImportCSV";
import loca from "../utils/localization";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentview: 'users'
        };

        window.View = this;
    }

    render() {
        const addUserButton = <div>
            <button className="btn btn-full"
                disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled ? 'disabled' : ''}
                onClick={() => openModal(
                    <CreateUser />,
                    { 'size': '1100px', 'closeAction': ModalManager.close() }
                )}>
                <i className="icon-plus" />
                {loca.TranslateHelper('create-new-user-label')}
            </button>

            <button className="ml-3 btn btn-full"
                onClick={() => openModal(
                    <ImportCSV dataType={'userTemplate'} />,
                    { 'size': '400px', 'closeAction': ModalManager.close() }
                )}>
                <i className="icon-upload" /> {loca.TranslateHelper('import-label')}
            </button>
        </div>

        return (
            <div>
                <Header
                    page={'users'}
                    headerTitle={loca.TranslateHelper('side-users')}
                    subtitle={addUserButton}
                    enableStat={true}
                    headerActions
                    value1Label={loca.TranslateHelper('no-tags-label')}
                    value2Label={loca.TranslateHelper('with-tag-label')}
                    value4Label={loca.TranslateHelper('deletion-pending-label')}
                    labelTotal={loca.TranslateHelper('total-number-users-label')} />
                <UsersTable />
            </div>
        )
    }
}

export default withTranslation()(Users)
