import React, { Component } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {
    openModal,
    Logout,
    tagTypeToString,
    changeLanguageCode,
} from "../Helpers";
import DeleteTag from "../modals/Tags/DeleteTag";
import EditTag from "../modals/Tags/EditTag";
import { toast } from "react-toastify";
import Select from "react-select";
import SelectColours from "../css/SelectColours";
import loca from "../utils/localization"
import qs from 'qs';
import handleViewport from 'react-in-viewport';
import ShowMore from "../modules/showMore";
import { ExportContent } from '../atoms/ExportContent';
import { getFilename } from '../utils/axios';
import { ColumnVisibilityButton } from '../atoms/ColumnVisibilityButton';

require('intersection-observer');

const Block = (props) => {
    const { forwardedRef, enablePaging } = props;
    return (
        <div className="viewport-block" ref={forwardedRef}>
            {enablePaging && <ShowMore />}
        </div>
    );
};

const ViewportBlock = handleViewport(Block, /** options: {}, config: {} **/);

const FilterComponent = ({ onFilter, onClear, handleChange, state }) => (
    <div className="col-md-10 filters">

        <div className="row">
            <div className="col col-md-2 pr-0">
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            isSearchable
                            isClearable
                            name="tagtype"
                            id="tagtype"
                            className="SelectContainer"
                            styles={SelectColours}
                            placeholder={loca.TranslateHelper('choose-tag-type-label')}
                            onChange={
                                e => { handleChange('filterTagType', e); setTimeout(function () { onFilter(e) }, 100) }
                            }
                            value={state.filterItems.filterTagTypes.length ? state.filterItems.filterTagTypes.filter(option => option.value === state.filterTagType) : ''}
                            options={state.filterItems.filterTagTypes} />

                        <label htmlFor="tagtype">{loca.TranslateHelper('tag-type-label')}</label>
                    </div>
                </div>
            </div>
            <div className="col col-md-2 pr-0">
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            isSearchable
                            isClearable
                            name="tagstate"
                            id="tagstate"
                            className="SelectContainer"
                            styles={SelectColours}
                            placeholder={loca.TranslateHelper('state-label')}
                            onChange={
                                e => { handleChange('filterUserType', e); setTimeout(function () { onFilter(e) }, 100) }
                            }
                            value={state.filterItems.filterStates.length ? state.filterItems.filterStates.filter(option => option.value === state.filterUserType) : ''}
                            options={state.filterItems.filterStates} />

                        <label htmlFor="tagtype">{loca.TranslateHelper('state-label')}</label>
                    </div>
                </div>
            </div>
            <div className="col col-md-auto pr-0">
                <div className="form-group">
                    <button className="btn btn-simple mt-3" onClick={e => { onClear(e); setTimeout(function () { onFilter(e) }, 100) }}><i className="icon-cross">&nbsp;</i>{loca.TranslateHelper('clear-filter-label')}</button>
                </div>
            </div>
        </div>
    </div>
);



class TagsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            resetPaginationToggle: false,
            filterTagType: "",
            filterUserType: "",
            dataLoading: true,
            sorting: "",
            sortDirection: "asc",
            page: 1,
            pageSize: 20,
            maxPage: null,
            enablePaging: true,
            filterItems: {
                filterTagTypes: [],
                filterStates: []
            },
            omit: localStorage.getItem('tagsTableColumns') ? JSON.parse(localStorage.getItem('tagsTableColumns')) : {
                "rfid-uid": false,
                "tag-type": false
            }
        }
        window.TagsTable = this;
    }

    handleChange = (stateId, e) => {
        this.setState({ [stateId]: e.value });
    }

    handleClear = () => {
        if (this.state.sorting !== "") {
            window.location.reload();
        }

        const { resetPaginationToggle } = this.state;

        this.setState({
            resetPaginationToggle: !resetPaginationToggle,
            filterTagType: "",
            filterUserType: "",
        });

        this.clearAfterFilter();
    };


    updateFilter = () => {
        this.setState({ dataLoading: true })

        this.updateStatistics();

        this.refreshFilter(true);

        this.setState({
            page: 1,
            enablePaging: true,
            dataLoading: false
        });
    }


    refreshFilter(resetPaging) {
        if (resetPaging) {
            this.clearAfterFilter();
        }

        axios(process.env.REACT_APP_API_URL + 'Filter/tagOptions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            var tagTypesFilter = Object.keys(response.data.tagTypes).map(function (key, index) {
                                return { value: key, label: response.data.tagTypes[key] };
                            });

                            var statesFilter = Object.keys(response.data.tagStatuses).map(function (key, index) {
                                return { value: key, label: response.data.tagStatuses[key] };
                            });

                            tagTypesFilter.unshift({ key: "", label: loca.TranslateHelper('all-label') });
                            statesFilter.unshift({ key: "", label: loca.TranslateHelper('all-label') });

                            this.setState({
                                filterItems: {
                                    filterTagTypes: tagTypesFilter,
                                    filterStates: statesFilter,
                                }
                            });
                        }
                    } else {
                        toast.error('error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }


    toggleHideTable = () => {
        this.setState({ showColumnHide: !this.state.showColumnHide });
    }

    saveHideColumns = (col) => {
        this.setState(prevState => ({
            omit: {
                ...prevState.omit,
                [col]: !this.state.omit[col]
            }
        }), () => setImmediate(() => {
            window.localStorage.setItem("tagsTableColumns", JSON.stringify(this.state.omit));
        }));
    }

    getSubHeaderComponent = () => {
        return (
            <div className='col-12'>
                <div className="row w-100">
                    <FilterComponent
                        onFilter={this.updateFilter}
                        onClear={this.handleClear}
                        onInputChange={this.handleChange}
                        handleChange={this.handleChange}
                        state={this.state}
                    />

                    <div className="col-action">
                        <ExportContent exportWithFormat={this.exportData} />
                        <button className="float-right" onClick={window.Header.showHeader}><i className="icon-show-header">&nbsp;</i></button>
                        <button className="float-right" onClick={window.Header.hideHeader}><i className="icon-hide-header">&nbsp;</i></button>
                        <ColumnVisibilityButton
                            columns={["rfid-uid", "tag-type"]}
                            isVisible={this.state.showColumnHide}
                            omit={this.state.omit}
                            saveHideColumns={this.saveHideColumns}
                            toggleHideVisibility={this.toggleHideTable} />
                        <div className="clear">&nbsp;</div>
                    </div>
                </div>
            </div>
        );
    }

    exportData = (format) => {

        const data = {
            TagType: this.state.filterTagType,
            TagState: this.state.filterUserType,
            ExportFormat: format,
        };

        axios(process.env.REACT_APP_API_URL + 'Tag/export?' + qs.stringify(data), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            responseType: 'arraybuffer', // default is json
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            var byte = response.data;

                            var blob = new Blob([byte], { type: response.headers["content-type"] });
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);

                            link.download = getFilename(response);
                            link.click();
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    updateStatistics = () => {
        axios(process.env.REACT_APP_API_URL + 'Tag/statistics', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            params: {
                TagType: this.state.filterTagType,
                State: this.state.filterUserType
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            window.Header.setState({ stat_value1: response.data.unassigned })
                            window.Header.setState({ stat_value2: response.data.assigned })
                            window.Header.setState({ stat_value3: '' })
                            window.Header.setState({ stat_value4: '' })
                            window.Header.setState({ stat_valueTotal: response.data.totalCount })
                            window.Header.setState({ stat_show: true })

                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    getStatistics() {
        window.Header.setState({ stat_show: false })
        window.Header.setState({ stat_value1: '' })
        window.Header.setState({ stat_value2: '' })
        window.Header.setState({ stat_value3: '' })
        window.Header.setState({ stat_value4: '' })
        window.Header.setState({ stat_valueTotal: '' })

        axios(process.env.REACT_APP_API_URL + 'Tag/statistics', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            window.Header.setState({ stat_value1: response.data.unassigned })
                            window.Header.setState({ stat_value2: response.data.assigned })
                            window.Header.setState({ stat_value3: '' })
                            window.Header.setState({ stat_value4: '' })
                            window.Header.setState({ stat_valueTotal: response.data.totalCount })
                            window.Header.setState({ stat_show: true })

                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    appendTags(resetPaging) {

        if (this.state.enablePaging || resetPaging) {
            axios(process.env.REACT_APP_API_URL + 'Tag/paged', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                params: {
                    Page: this.state.page,
                    PageSize: this.state.pageSize,
                    OrderBy: this.state.sorting,
                    DescedingOrder: this.state.sortDirection === 'asc' ? false : true,
                    TagType: this.state.filterTagType,
                    TagState: this.state.filterUserType
                },
                withCredentials: true,
                credentials: 'same-origin',
            })
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data === 'User not logged in.') {
                                Logout()
                            } else {

                                this.setState({ data: [...this.state.data, ...response.data.data] });
                                this.filteredItems = [...this.state.data, ...response.data.data];

                                if (response.data.pageInfo.page < Math.ceil(response.data.totalRecords / response.data.pageInfo.pageSize)) {
                                    this.setState({ page: response.data.pageInfo.page + 1 });
                                    this.setState({ enablePaging: true });
                                } else {
                                    this.setState({ enablePaging: false });
                                }

                                this.getStatistics();

                                this.refreshFilter(resetPaging);
                                this.setState({ dataLoading: false })
                            }
                        } else {
                            toast.error('There was an error!', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        }
    }

    clearAfterFilter() {
        this.setState({
            page: 1,
            enablePaging: true
        });
        this.setState({ data: [] })
    }

    loadMore() {
        this.appendTags(false)
    }



    sortDataTable(column, direction, event) {
        const self = this;

        self.setState({
            sorting: column.id,
            sortDirection: direction,
            page: 1,
            enablePaging: true
        })

        setTimeout(function () {
            self.appendTags(true);
        }, 100)

    }

    render() {

        const columns = [
            {
                name: 'RFID UID',
                sortable: true,
                id: 'rfid',
                omit: this.state.omit["rfid-uid"],
                selector: (row, index) => row.externalId,
                cell: row => <div>{row.externalId}</div>,

            },
            {
                name: loca.TranslateHelper('tag-label'),
                sortable: true,
                id: 'tag id',
                omit: false,
                selector: (row, index) => row.tagName,
                cell: row => <div>{row.tagName}</div>,
            },
            {
                name: loca.TranslateHelper('tag-type-label'),
                id: 'tag type',
                sortable: true,
                omit: this.state.omit["tag-type"],
                selector: row => tagTypeToString(row.tagType),
                filterable: true,
                width: '100px',
                cell: row => <div>{tagTypeToString(row.tagType)}</div>,
            },
            {
                name: loca.TranslateHelper('assigned-to-label'),
                id: 'assigned to',
                sortable: true,
                selector: (row, index) => (row.user ? row.user.lastName + ' ' + row.user.firstName + ' (' + row.user.externalId + ')' : ''),
                cell: row => <div>{row.user ? row.user.lastName + ' ' + row.user.firstName + ' (' + row.user.externalId + ')' : ''} </div>,
            },
            {
                name: '',
                sortable: false,
                width: '80px',
                export: false,
                cell: row =>
                    <div>
                        <button disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled && row.tagType !== 3 ? 'disabled' : ''} className="btn btn-action" onClick={() => openModal(<DeleteTag tagId={row.tagId} tagName={row.tagName} />)}><i className="icon-delete" /></button>
                        <button className="btn btn-action" onClick={() => openModal(<EditTag tagId={row.tagId} />)}><i className="icon-edit" /></button>
                    </div>,
            },
        ];
        const data = this.state.data;

        return (
            <div onClick={() => { window.Header.setState({ showSearchResult: false }) }}>
                <DataTable
                    noHeader
                    className="datatable"
                    progressPending={this.state.dataLoading}
                    progressComponent={<div><h2 className="main my-5">{loca.TranslateHelper('loading-label')}</h2></div>}
                    pagination={false}
                    paginationPerPage={this.state.pageSize}
                    paginationRowsPerPageOptions={[15, 30, 50]}
                    paginationResetDefaultPage={this.state.resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    onSort={(column, sortDirection, event) => this.sortDataTable(column, sortDirection, event)}
                    sortServer={true}
                    subHeaderComponent={this.getSubHeaderComponent()}
                    highlightOnHover
                    columns={columns}
                    data={data}
                />
                {this.state.enablePaging && <ViewportBlock enablePaging={this.state.enablePaging} onEnterViewport={() => this.loadMore()} />}
            </div>

        )
    }
}

export default (TagsTable);
