import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { changeLanguageCode, Logout } from "../../Helpers";
import { toast } from "react-toastify";
import loca from "../../utils/localization"
import { RefreshView } from "../../Helpers";


class AssignExist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bags: [],
            newBags: []
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    componentDidMount() {
        var bags = []
        var newBags = []
        if (this.props.bag.length >= 1) {
            Object.keys(this.props.bag).forEach(key => {
                bags.push({ bagId: this.props.bag[key].bagId, userId: this.props.assignedUser.value })
                newBags.push({ bagId: this.props.bag[key].bagId, userId: this.props.user })
            }
            )
        } else {
            bags.push({ bagId: this.props.bag.bagId, userId: this.props.assignedUser.value });
            newBags.push({ bagId: this.props.bag.bagId, userId: this.props.user });
        }

        this.setState({ bags: bags })
        this.setState({ newBags: newBags })
    }

    doAssign() {


        document.getElementById("assignUserBTN").classList.add("loading");

        axios(process.env.REACT_APP_API_URL + 'Assignment/removeMultipleAssignments', {
            method: 'DELETE',
            data: this.state.bags,
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 204) {
                        if (response.data === 'User not logged in') {
                            Logout()
                        } else {
                            axios(process.env.REACT_APP_API_URL + 'Assignment/addMultipleAssignments', {
                                method: 'POST',
                                data: this.state.newBags,
                                headers: {
                                    'Content-Type': 'application/json',
                                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                                    'Accept-Language': changeLanguageCode(loca.language)
                                },
                                withCredentials: true,
                            })
                                .then(
                                    response => {
                                        if (response.status === 204) {
                                            if (response.data === 'User not logged in') {
                                                Logout()
                                            } else {

                                                if (window.BagsTable) {
                                                    window.BagsTable.appendBags();
                                                }

                                                window.Modal.props.closeAction();

                                                toast.success(loca.TranslateHelper('assign-to-bag-success-label'), {
                                                    position: toast.POSITION.TOP_CENTER,
                                                });

                                                RefreshView(window.View.state.currentview);

                                                document.getElementById("assignUserBTN").classList.remove("loading");
                                            }
                                        } else {
                                            toast.error(response.data, {
                                                position: toast.POSITION.TOP_CENTER,
                                            });
                                            document.getElementById("assignUserBTN").classList.remove("loading");
                                        }
                                    }
                                )
                                .catch(error => {
                                    toast.error(error.response.data, {
                                        position: toast.POSITION.TOP_CENTER,
                                    });

                                    document.getElementById("assignUserBTN").classList.remove("loading");
                                });
                        }
                    } else {
                        toast.error(response.data, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("assignUserBTN").classList.remove("loading");
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });

                document.getElementById("assignUserBTN").classList.remove("loading");
            });

    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt="" />
                <h2 className="main tc">{t('user-assignment-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {t('user-assignent-exist-label', { old: this.props.assignedUser.label, new: this.props.selectedUserName })}
                        <div className="form-group">
                            <button id="assignUserBTN" className="btn btn-full mt-3" onClick={() => this.doAssign(this.props.tagId)}>{t('remove-and-assign-user-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(AssignExist);
