import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import {openModal} from "../Helpers";
import ChangeName from "../modals/Profile/ChangeName";
import ChangePassword from "../modals/Profile/ChangePassword";
import loca from "../utils/localization";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('loginData'))
        };
    }
    render() {
        return (
            <div>
                <Header
                    headerTitle={`${this.state.user['lastName']} ${this.state.user['firstName']}`}
                    subtitle={false}
                    enableStat={false}
                />

                <div className="container-div">
                    <div className="row">
                        <div className="col col-md-3">
                            <h4 className="main">{loca.TranslateHelper('my-name-label')}</h4>
                            <div className="text-muted">{this.state.user['lastName']} {this.state.user['firstName']}</div>
                            <button className="btn btn-full mt-3"
                                    onClick={()=>openModal(
                                        <ChangeName
                                            firstName={this.state.user['firstName']}
                                            lastName={this.state.user['lastName']}/>,
                                        {'size':'600px'})}>
                                    {loca.TranslateHelper('change-name-label')}
                            </button>
                        </div>


                        <div className="col col-md-3">
                            <h4 className="main">{loca.TranslateHelper('password-label')}</h4>
                            <div className="text-muted">*********</div>
                            <button className="btn btn-full mt-3"
                                    onClick={()=>openModal(
                                        <ChangePassword/>,
                                        {'size':'600px'})}>
                                    {loca.TranslateHelper('change-password-label')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Profile)
