import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import loca from "../../utils/localization"
import BagsTable from "../../components/BagsTable";

class SearchBagForUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <h2>{loca.TranslateHelper('user-to-bag-assignment')}</h2>
                <BagsTable
                    selectForUser
                    filterGender={this.props.filterGender}
                    bagsPendingAssignment={this.props.bagsPendingAssignment}
                    filterState={0}
                    hideUnusedFilters
                    disableAction
                    hideAssigned
                    hideGender
                    hideState />
            </div>
        )
    }
}

export default withTranslation()(SearchBagForUser);
