import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import BagsTable from "../components/BagsTable";
import loca from "../utils/localization";

class LockerBags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentview: 'bags'
        };

        window.View = this;
    }

    render() {
        return (
            <div>
                <Header
                    page={'bags'}
                    headerTitle={loca.TranslateHelper('side-lockerBags')}
                    subtitle={window.Desktop.state.tenantName}
                    filter="TagsFilter"
                    enableStat={true}
                    headerActions
                    value1Label={loca.TranslateHelper('free-label')}
                    value2Label={loca.TranslateHelper('assigned-label')}
                    value3Label={loca.TranslateHelper('out-of-use-label')}
                    value4Label={loca.TranslateHelper('cleaning-label')}
                    value5Label={loca.TranslateHelper('synch-label')}
                    value6Label={loca.TranslateHelper('dead-locker-label')}
                    labelTotal={loca.TranslateHelper('total-capacity-label')}
                />

                <BagsTable/>

            </div>
        )
    }
}

export default withTranslation()(LockerBags);
