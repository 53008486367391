import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import loca from "../../utils/localization";
import UploadFiles from "../../modules/upload-files.component";

class ImportCSV extends Component {
    render() {
        const {t} = this.props
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('import-label')}</h2>

                <div className="row">
                    <div className="col-md-12">
                        {t('file-should-be-label')} zip, .csv, .xls

                        <UploadFiles dataType={this.props.dataType} />

                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ImportCSV);
