import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import axios from "axios";
import SelectColours from "../../css/SelectColours";
import { toast } from "react-toastify";
import {
  changeLanguageCode,
  Logout,
  openModal,
  RefreshView,
  ValueContainer,
} from "../../Helpers";
import CreateTag from "../Tags/CreateTag";
import RemoveTagFromUser from "./RemoveTagFromUser";
import DeleteUser from "./DeleteUser";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization";
import SearchBagForUser from "./SearchBagForUser";
import { connect } from "react-redux";
import { compose } from "redux";
import { BagTile } from "../../atoms/BagTile";

const CustomOption = ({ data, innerProps, isDisabled, isSelected }) =>
  !isDisabled && !data.isDisabledElement ? (
    <div {...innerProps}>
      {data.value === "__customOption" ? (
        data.component
      ) : (
        <div className="row custom-dropdown mx-0 align-items-center">
          <div className="col col-md-12">
            <div className="checkbox-container">
              <input
                type="checkbox"
                id={"option-" + data.value}
                className="inp-cbx"
                value={data.value}
                checked={isSelected ? "checked" : ""}
              />
              <label className="cbx">
                <span>
                  <svg viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" />
                  </svg>
                </span>
                <span className="ellipsis">{data.label}</span>
              </label>
            </div>
          </div>
          <div className="col col-md-12 uid text-left ellipsis pl-5">
            [UID : {data.uid}]
          </div>
        </div>
      )}
    </div>
  ) : null;

function tagToTagOption(tag) {
  return {
    value: tag["tagId"],
    label: tag["tagName"],
    uid: tag["externalId"],
    isSelected: false,
    isDisabledElement: false,
  };
}

function isCancelEvent(event) {
  return !!event.target;
}

class EditUserInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: this.props.user.firstName,
      lastname: this.props.user.lastName,
      externalId: this.props.user.externalId,
      jobtitleId: this.props.user.jobTitleId,
      genderId: this.props.user.genderId,
      departmentId: this.props.user.departmentId,
      email: this.props.user.emailAddress,
      comment: this.props.user.comment,
      user: undefined, // On first opening, load the newest value from backend
      unassignedTagOptions: [], // The list of tags the user can choose from
      selectedTagOptions: [], // The list of tags the user selected to be assigned to them
      selectedBags: [], // The list of bags that were selected to be assigned to user
      removedTagIds: [], // These tags were removed from user, do not show them
      enableAssignBag: true,
      selectedToUnassignBags: [], // These bags were selected to be unassigned
      cleanUnassignedBags: false,
    };

    this.EditUserValidator = new SimpleReactValidator({
      locale: loca.language,
    });

    window.EditUser = this;
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectedTagsChange = (values) => {
    this.setState((state) => ({
      ...state,
      selectedTagOptions: values,
    }));
  };

  handleChange(stateId, e) {
    this.setState({ [stateId]: e.value });
  }

  getUnassignedTags() {
    axios(process.env.REACT_APP_API_URL + "Tag/unassigned", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
        "Accept-Language": changeLanguageCode(loca.language),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            let unassignedTags = response.data.map(tagToTagOption);

            const selectCustomOption = (
              <div className="row m-0 justify-content-end">
                <div className="col col-md-auto py-3">
                  <button
                    className="btn btn-full"
                    onClick={() =>
                      openModal(<CreateTag />, {
                        size: "600px",
                        closeAction: (createdTag) =>
                          openModal(
                            <EditUser
                              state={this.state}
                              userId={this.state.user.userId}
                              user={this.state.user}
                              createdTagOption={tagToTagOption(createdTag)}
                            />,
                            { size: "1100px" }
                          ),
                      })
                    }
                  >
                    <i className="icon-tag" />
                    {loca.TranslateHelper("create-tag-label")}
                  </button>
                </div>
              </div>
            );

            unassignedTags.unshift({
              value: "__customOption",
              component: selectCustomOption,
              uid: "",
              isDisabledElement: false,
              isSelected: false,
            });
            this.setState({ unassignedTagOptions: unassignedTags });
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserById() {
    axios(process.env.REACT_APP_API_URL + "User/" + this.props.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
        "Accept-Language": changeLanguageCode(loca.language),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            this.setState({ user: response.data });
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  saveUser() {
    if (this.allValid()) {
      document.getElementById("saveUserBTN").classList.add("loading");
      axios(process.env.REACT_APP_API_URL + "User/" + this.state.user.userId, {
        method: "PUT",
        data: {
          externalId: this.state.externalId,
          firstName: this.state.firstname,
          lastName: this.state.lastname,
          jobTitleId: this.state.jobtitleId,
          departmentId: this.state.departmentId,
          genderId: this.state.genderId,
          emailAddress: this.state.email,
          comment: this.state.comment,
        },
        headers: {
          "Content-Type": "application/json",
          tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
          "Accept-Language": changeLanguageCode(loca.language),
        },
        withCredentials: true,
      })
        .then((response) => {
          if (response.status === 202) {
            if (response.data === "User not logged in.") {
              Logout();
            } else {
              toast.success(loca.TranslateHelper("save-user-success-label"), {
                position: toast.POSITION.TOP_CENTER,
              });
              this.saveUserTags();
            }
          } else {
            toast.error("Error", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data, {
              position: toast.POSITION.TOP_CENTER,
            });

            document.getElementById("saveUserBTN").classList.remove("loading");
          }
        });
    } else {
      this.EditUserValidator.showMessages();
      this.forceUpdate();
    }
  }

  saveUserTags() {
    if (this.allValid()) {
      const userTagList = [
        ...this.state.user.tags
          .filter(
            (tag) =>
              tag.tagId !== this.props.removedTagId &&
              !this.state.removedTagIds.some((removed) => removed === tag.tagId)
          )
          .map((tag) => tag.tagId),
        ...this.state.selectedTagOptions.map((tag) => tag.value),
      ];

      axios(process.env.REACT_APP_API_URL + "Assignment/UserTagAssignments", {
        method: "PUT",
        data: {
          userId: this.state.user.userId,
          tagIds: userTagList,
        },
        headers: {
          "Content-Type": "application/json",
          tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
          "Accept-Language": changeLanguageCode(loca.language),
        },
        withCredentials: true,
      })
        .then((response) => {
          console.log(response);
          if (response.status === 204) {
            if (response.data === "User not logged in.") {
              Logout();
            } else {
              toast.success(
                loca.TranslateHelper("assign-to-user-success-label"),
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );

              if (this.state.selectedBags.length > 0 || this.state.selectedToUnassignBags.length > 0) {
                this.saveBagAssignments();
              } else {
                document
                  .getElementById("saveUserBTN")
                  .classList.remove("loading");
              }

              RefreshView(window.View.state.currentview);
              window.Modal.props.closeAction();
            }
          } else {
            toast.error("Error", {
              position: toast.POSITION.TOP_CENTER,
            });
            document.getElementById("saveUserBTN").classList.remove("loading");
          }
        })
        .catch((error) => {
          toast.error(error.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          document.getElementById("saveUserBTN").classList.remove("loading");
        });
    } else {
      this.EditUserValidator.showMessages();
      this.forceUpdate();
    }
  }

  saveBagAssignments = () => {
    const userBags = this.state.user.bags
      .filter(bag => !this.state.selectedToUnassignBags.find(unassign => bag.bagId === unassign.bagId));
    const newBags = this.state.selectedBags;

    const bagIdList = [...userBags, ...newBags].map(b => b.bagId);

    axios(process.env.REACT_APP_API_URL + `User/${this.state.user.userId}/assignments?cleaning=${this.state.cleanUnassignedBags}`, {
      method: "PUT",
      data: bagIdList,
      headers: {
        "Content-Type": "application/json",
        tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
        "Accept-Language": changeLanguageCode(loca.language),
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(loca.TranslateHelper("assign-to-bag-success-label"), {
            position: toast.POSITION.TOP_CENTER,
          });

          document.getElementById("saveUserBTN").classList.remove("loading");
        }
      })
      .catch((error) => {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  componentDidMount = () => {
    this.setState(this.props.state);
    this.getUnassignedTags();

    if (
      this.props.createdTagOption &&
      !isCancelEvent(this.props.createdTagOption)
    )
      this.setState((state) => ({
        ...state,
        selectedTagOptions: [
          ...state.selectedTagOptions,
          this.props.createdTagOption,
        ],
      }));

    if (this.props.selectedBags && !isCancelEvent(this.props.selectedBags))
      this.setState((state) => ({
        ...state,
        selectedBags: [...state.selectedBags, ...this.props.selectedBags],
      }));

    if (this.props.removedTagId && !isCancelEvent(this.props.removedTagId))
      this.setState((state) => ({
        ...state,
        removedTagIds: [
          ...state.removedTagIds,
          this.props.removedTagId,
        ],
      }));

    if (!this.state.user && !this.props?.state?.user) this.getUserById();
  };

  removeSelectedBag = (bag) => {
    this.setState(state => ({
      ...state,
      selectedBags: state.selectedBags.filter(b => b.bagId !== bag.bagId),
    }))
  }

  selectBagForUnassign = (bag) => {
    this.setState(state => ({
      ...state,
      selectedToUnassignBags: [...state.selectedToUnassignBags, bag],
    }))
  }

  unselectBagForUnassign = (bag) => {
    this.setState(state => ({
      ...state,
      selectedToUnassignBags: state.selectedToUnassignBags.filter(b => b.bagId !== bag.bagId),
    }))
  }

  toggleClean = () => {
    this.setState(state => ({
      ...state,
      cleanUnassignedBags: !state.cleanUnassignedBags,
    }))
  }

  bagsValid = () => {
    if (!this.state.user?.bags)
      return false;

    const bags = [...this.state.user.bags, ...this.state.selectedBags]
      .filter(b1 => !this.state.selectedToUnassignBags.find(b2 => b2.bagId === b1.bagId));

    const systemCount = {}
    for (const bag of bags) {
      systemCount[bag.systemName] = (systemCount?.[bag.systemName] ?? 0) + 1;
    }

    return !Object.values(systemCount).some(count => count > 3);
  }

  tagsValid = () => {
    if (!this.state.user?.tags)
      return false;

    const userTagsCount = this.state.user.tags.length;
    const selectedTagsCount = this.state.selectedTagOptions.length;

    return userTagsCount + selectedTagsCount <= 5;
  }

  allValid = () => {
    return this.EditUserValidator.allValid() && this.bagsValid() && this.tagsValid();
  }

  render = () => {
    const filters = this.props;
    const tooManyTags = !this.tagsValid();
    const tooManyBagsPerSystem = !this.bagsValid();

    if (this.state.user) {
      return (
        <div>
          <h2 className="main">{loca.TranslateHelper("edit-user-label")}</h2>
          <div className="row mt-4">
            <div className="col col-md-6">
              <h5 className="main mb-3">
                {loca.TranslateHelper("user-data-label")}
              </h5>
              <div className="row">
                <div className="col col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        placeholder={loca.TranslateHelper("lastname-label")}
                        value={this.state.lastname || ""}
                        onChange={this.onChange}
                        disabled={
                          JSON.parse(localStorage.getItem("amsData")).isEnabled
                            ? "disabled"
                            : ""
                        }
                      />
                      <label htmlFor="lastname">
                        {loca.TranslateHelper("lastname-label")} <em>*</em>
                      </label>
                    </div>
                    {this.EditUserValidator.message(
                      "lastname",
                      this.state.lastname,
                      "required"
                    )}
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        placeholder={loca.TranslateHelper("firstname-label")}
                        value={this.state.firstname || ""}
                        onChange={this.onChange}
                        disabled={
                          JSON.parse(localStorage.getItem("amsData")).isEnabled
                            ? "disabled"
                            : ""
                        }
                      />
                      <label htmlFor="firstname">
                        {loca.TranslateHelper("firstname-label")} <em>*</em>
                      </label>
                    </div>
                    {this.EditUserValidator.message(
                      "firstname",
                      this.state.firstname,
                      "required"
                    )}
                  </div>
                </div>
                <div className="col col-md-12">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        name="externalId"
                        id="externalId"
                        placeholder={loca.TranslateHelper("user-id-label")}
                        value={this.state.externalId || ""}
                        onChange={this.onChange}
                        disabled={
                          JSON.parse(localStorage.getItem("amsData")).isEnabled
                            ? "disabled"
                            : ""
                        }
                      />
                      <label htmlFor="externalId">
                        {loca.TranslateHelper("user-id-label")} <em>*</em>
                      </label>
                    </div>
                    {this.EditUserValidator.message(
                      "externalId",
                      this.state.externalId,
                      "required"
                    )}
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <Select
                        name="jobtitleId"
                        id="jobtitleId"
                        styles={SelectColours}
                        className="SelectContainer"
                        onChange={this.handleChange.bind(this, "jobtitleId")}
                        placeholder={loca.TranslateHelper(
                          "choose-job-title-label"
                        )}
                        value={
                          this.state.jobtitleId && filters.jobTitles
                            ? filters.jobTitles.filter(
                              (option) =>
                                option.value === this.state.jobtitleId
                            )
                            : ""
                        }
                        options={filters.jobTitles}
                        components={{}}
                      />
                      <label htmlFor="jobtitleId">
                        {loca.TranslateHelper("job-title-label")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <Select
                        name="genderId"
                        id="genderId"
                        styles={SelectColours}
                        className="SelectContainer"
                        onChange={this.handleChange.bind(this, "genderId")}
                        placeholder={loca.TranslateHelper(
                          "choose-gender-label"
                        )}
                        value={
                          this.state.genderId && filters.genders
                            ? filters.genders.filter(
                              (option) => option.value === this.state.genderId
                            )
                            : ""
                        }
                        options={filters.genders}
                        components={{}}
                      />
                      <label htmlFor="genderId">
                        {loca.TranslateHelper("gender-label")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder={loca.TranslateHelper("email-label")}
                        value={this.state.email || ""}
                        onChange={this.onChange}
                      />
                      <label htmlFor="email">
                        {loca.TranslateHelper("email-label")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="form-group">
                    <div className="input-group">
                      <Select
                        name="departmentId"
                        id="departmentId"
                        styles={SelectColours}
                        className="SelectContainer"
                        onChange={this.handleChange.bind(this, "departmentId")}
                        placeholder={loca.TranslateHelper(
                          "choose-department-label"
                        )}
                        value={
                          this.state.departmentId
                            ? filters.departments.filter(
                              (option) =>
                                option.value === this.state.departmentId
                            )
                            : ""
                        }
                        options={filters.departments}
                        components={{}}
                      />
                      <label htmlFor="departmentId">
                        {loca.TranslateHelper("department-label")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col col-md-12">
                  <div className="form-group">
                    <div className="input-group textarea-group">
                      <textarea
                        id="comment"
                        name="comment"
                        placeholder={loca.TranslateHelper("comment-label")}
                        defaultValue={this.state.comment || ""}
                        onChange={this.onChange}
                      />
                      <label htmlFor="comment">
                        {loca.TranslateHelper("comment-label")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col col-md-12">
                  <div className="form-group">
                    <button
                      id="saveUserBTN"
                      className="btn btn-full"
                      onClick={() => this.saveUser()}
                    >
                      {loca.TranslateHelper("save-label")}
                    </button>
                    <button
                      disabled={
                        JSON.parse(localStorage.getItem("amsData")).isEnabled &&
                          !this.state.user.deletionPending
                          ? "disabled"
                          : ""
                      }
                      id="deleteUserBTN"
                      className="btn btn-full ml-2"
                      onClick={() =>
                        openModal(
                          <DeleteUser
                            userId={this.state.user.userId}
                            hasAssignment={
                              (this.props.user.bags &&
                                this.props.user.bags.length) ||
                                this.props.hasAssignment ||
                                this.props.user.hasAssignment
                                ? true
                                : false
                            }
                            userName={
                              this.state.lastname + " " + this.state.firstname
                            }
                          />,
                          { size: "600px" }
                        )
                      }
                    >
                      <i className="icon-delete" />
                      {loca.TranslateHelper("delete-user-label", { count: 1 })}
                    </button>
                    {tooManyTags && this.EditUserValidator.messageWhenPresent(loca.t("too-many-tags"))}
                    {tooManyBagsPerSystem && this.EditUserValidator.messageWhenPresent(loca.t("too-many-bags-per-system"))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-md-3">
              <h5 className="main mb-3">
                {loca.TranslateHelper("assign-to-tag-label")}
              </h5>
              <div className="row mt-3">
                <div className="col col-md-12">
                  <div className="form-group">
                    <div className="input-group">
                      <Select
                        closeMenuOnSelect={false}
                        isSearchable
                        name="tagId"
                        id="tagId"
                        isMulti
                        hideSelectedOptions={false}
                        onChange={this.onSelectedTagsChange}
                        className={
                          JSON.parse(localStorage.getItem("amsData")).isEnabled
                            ? "SelectContainer disabled"
                            : "SelectContainer"
                        }
                        value={this.state.selectedTagOptions}
                        styles={SelectColours}
                        isDisabled={
                          JSON.parse(localStorage.getItem("amsData")).isEnabled
                            ? true
                            : false
                        }
                        placeholder={loca.TranslateHelper("choose-tag-label")}
                        options={this.state.unassignedTagOptions}
                        components={{
                          ValueContainer: ValueContainer,
                          Option: CustomOption,
                        }}
                      />
                      <label htmlFor="tagId">
                        {loca.TranslateHelper("tag-label")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="main mb-3 mt-4">
                {loca.TranslateHelper("user-tags-label")}
              </h5>
              <ul id="tags" className="tagList">
                {this.state.user.tags
                  .filter(
                    (tag) =>
                      tag.tagId !== this.props.removedTagId &&
                      !this.state.removedTagIds.some(
                        (removed) => removed === tag.tagId
                      )
                  )
                  .map((item) => (
                    <li key={item.tagId}>
                      <i className="icon-tag" /> {item.tagName}{" "}
                      <button
                        disabled={
                          JSON.parse(localStorage.getItem("amsData")).isEnabled
                            ? "disabled"
                            : ""
                        }
                        onClick={() =>
                          openModal(
                            <RemoveTagFromUser
                              userName={
                                this.state.user.lastName +
                                " " +
                                this.state.user.firstName
                              }
                              userId={this.state.user.userId}
                              tagName={item.tagName}
                              tagId={item.tagId}
                            />,
                            {
                              size: "600px",
                              closeAction: (removedTagId) =>
                                openModal(
                                  <EditUser
                                    state={this.state}
                                    userId={this.state.user.userId}
                                    user={this.state.user}
                                    removedTagId={removedTagId}
                                  />,
                                  { size: "1100px" }
                                ),
                            }
                          )
                        }
                      >
                        <i className="icon-delete" />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="col col-md-3">
              <h5 className="main mb-3">
                {loca.TranslateHelper("assigned-bags-label")}
              </h5>

              {this.state.user.bags &&
                this.state.user.bags.map((bag) => {
                  const isBagSelectedForUnassign = this.state.selectedToUnassignBags.find(b => b.bagId === bag.bagId) !== undefined;
                  const mode = isBagSelectedForUnassign ? "unassigning" : "default";
                  const onClick = isBagSelectedForUnassign ? this.unselectBagForUnassign : this.selectBagForUnassign;

                  return <BagTile bag={bag} intent={mode} onClick={onClick} />;
                })}

              {this.state.selectedBags?.length > 0 &&
                this.state.selectedBags.map((bag) => (
                  <BagTile bag={bag} intent="assigning" onClick={this.removeSelectedBag} />
                ))}

              {this.state.enableAssignBag && (
                <div>

                  {this.state.selectedToUnassignBags.length > 0 && <div className="col-12 checkbox-container mb-3">
                    <input
                      type="checkbox"
                      id="activateCleaningState"
                      name="activateCleaningState"
                      className="inp-cbx"
                      checked={this.state.cleanUnassignedBags}
                      onChange={this.toggleClean} />
                    <label className="cbx" htmlFor={'activateCleaningState'}>
                      <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1" /></svg></span>
                      <span className={'text-left'}>{loca.TranslateHelper('place-to-cleaning-state-label')}</span>
                    </label>
                  </div>}

                  <div className="row">
                    <div className={"col-12"}>
                      <button
                        id="assignBagBTN"
                        className="btn btn-full"
                        onClick={() =>
                          openModal(
                            <SearchBagForUser
                              filterGender={this.state.user.genderId}
                              userId={this.state.user.userId}
                              bagsPendingAssignment={this.state.selectedBags}
                            />,
                            {
                              size: "1280px",
                              closeAction: (bags) =>
                                openModal(
                                  <EditUser
                                    state={this.state}
                                    userId={this.state.user.userId}
                                    user={this.state.user}
                                    selectedBags={bags}
                                  />,
                                  { size: "1100px" }
                                ),
                            }
                          )
                        }
                      >
                        {loca.TranslateHelper("add-user-to-bag-label")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };
}

function mapStateToProps(state) {
  return {
    ...state.customFields,
  };
}

const EditUser = compose(
  connect(mapStateToProps),
  withTranslation()
)(EditUserInner);

export default EditUser;
