import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import Select from "react-select";
import SelectColours from "../../css/SelectColours";
import { TagTypesFromHelpers, Logout, RefreshView, changeLanguageCode } from "../../Helpers"
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

class CreateTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rfid_uid: '',
            tag_name: '',
            tagtype: '',
            tagtypes: []
        };

        this.CreateTagValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    saveTag() {
        if (this.CreateTagValidator.allValid()) {
            document.getElementById("saveTagBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'Tag', {
                method: 'POST',
                data: {
                    'externalId': this.state.rfid_uid,
                    'tagName': this.state.tag_name,
                    'tagType': this.state.tagtype,
                    'userId': null
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 201) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {
                                RefreshView(window.View.state.currentview);
                                toast.success(loca.TranslateHelper('create-tag-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                window.Modal.props.closeAction(response.data);

                                document.getElementById("saveTagBTN").classList.remove("loading");
                            }
                        } else {
                            toast.error('ERROR', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            document.getElementById("saveTagBTN").classList.remove("loading");
                        }
                    }
                ).catch(error => {
                    if (error.response) {
                        toast.error(error.response.data, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("saveTagBTN").classList.remove("loading");
                    }
                });
        } else {
            document.getElementById("saveTagBTN").classList.remove("loading");
            this.CreateTagValidator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('create-tag-edit-label')}</h2>
                <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" name="rfid_uid" id="rfid_uid" placeholder={loca.TranslateHelper('rfid-uid-label')} onChange={this.onChange} />
                                <label htmlFor="rfid_uid">{loca.TranslateHelper('rfid-uid-label')} <em>*</em></label>
                            </div>
                            {this.CreateTagValidator.message('rfid_uid', this.state.rfid_uid, ['required', 'min:1', 'max:16', 'regex:^[a-fA-F0-9]+$'])}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" name="tag_name" id="tag_name" placeholder={loca.TranslateHelper('tag-name-label')} onChange={this.onChange} />
                                <label htmlFor="tag_name">{loca.TranslateHelper('tag-name-label')} <em>*</em></label>
                            </div>
                            {this.CreateTagValidator.message('tag_name', this.state.tag_name, 'required|alpha_num_dash_space')}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <Select
                                    name="tagtype"
                                    id="tagtype"
                                    styles={SelectColours}
                                    className="SelectContainer"
                                    onChange={this.handleChange.bind(this, 'tagtype')}
                                    placeholder={loca.TranslateHelper('choose-tag-type-label')}
                                    isOptionDisabled={isOptionDisabled}
                                    options={TagTypesFromHelpers}
                                    components={{}} />
                                <label htmlFor="department">{loca.TranslateHelper('tag-type-label')} <em>*</em></label>
                            </div>
                            {this.CreateTagValidator.message('tagtype', this.state.tagtype, 'required')}
                        </div>
                        <div className="form-group">
                            <button id="saveTagBTN" className="btn btn-full" onClick={() => this.saveTag()}><i className="icon-tag" />{loca.TranslateHelper('create-tag-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CreateTag);

function isOptionDisabled(option) {
    const amsData = JSON.parse(localStorage.getItem('amsData'));
    const isEnabled = amsData.isEnabled;

    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const isNotSuperAdmin = loginData.role === 'Officer' || loginData.role === 'LocalAdmin';

    if (isEnabled) {
        // Justification: Value is a number stored as string, it's unfortunate but for now it is the lesser evil
        // eslint-disable-next-line eqeqeq
        return option.value != 3 && option.value != 7;
    } else {
        console.log(option);
        return isNotSuperAdmin
            ? option.value >= 3
            : false;
    }
}

