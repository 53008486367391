import React, { Component } from 'react';
import "../css/main.scss";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {changeLanguageCode, getConfig, Logout, validator} from "../Helpers";
import loca from "../utils/localization"

const setLoginLocal = async (loginData) => {
    try {
        window.localStorage.setItem("loginData", JSON.stringify(loginData.data));
    } catch (err) {
        console.log(err);
    }
};
const setAMS = async (loginData) => {
    try {
        window.localStorage.setItem("amsData", JSON.stringify(loginData.data));
    } catch (err) {
        console.log(err);
    }
};


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.login();
        }
    }

    getVersion(){
        axios(process.env.REACT_APP_API_URL+'Version',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            window.localStorage.setItem("versionData", JSON.stringify(response.data));
                        }
                    }else{
                        toast.error('error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }



    getInfo(){
        axios(process.env.REACT_APP_API_URL+'System/communicationInfo',{
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            window.localStorage.setItem("communicationMode", JSON.stringify(response.data.communicationMode));
                        }
                    }
                    else{
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                console.log(error);
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }


    login(){
            if (validator.allValid()) {
                document.getElementById("loginBtn").classList.add("loading");
                axios(process.env.REACT_APP_API_URL+'AppUser',{
                    method: 'POST',
                    data:{
                        'username' : this.state.email,
                        'password': this.state.password
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': changeLanguageCode(loca.language)
                    },
                    withCredentials: true,
                })
                    .then(
                        response => {
                            if (response.status === 200) {
                                const o = { ...response, userId: response.data.userId };
                                setLoginLocal(o); // SAVE LOGIN DATA

                                getConfig();
                                this.getInfo();
                                this.getVersion();

                                setTimeout(function(){
                                    axios(process.env.REACT_APP_API_URL+'IntegrationService',{
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'tenantId': response.data.tenantId,
                                            'Accept-Language': changeLanguageCode(loca.language)
                                        },
                                        withCredentials: true,
                                    }).then(amsResponse => {
                                        if (amsResponse.status === 200) {
                                            const a = { ...amsResponse, isEnabled: amsResponse.data.isEnabled };
                                            setAMS(a); // SAVE AMS DATA

                                            window.location.reload(false)
                                        }
                                    }).catch(error => {
                                        console.log(error);
                                        toast.error(error.response.data, {
                                            position: toast.POSITION.TOP_CENTER,
                                        });
                                        document.getElementById("loginBtn").classList.remove("loading");
                                    });
                                },2000);
                                //window.location.reload(false)
                            }else{
                                toast.error('Login error!', {
                                    position: toast.POSITION.TOP_CENTER,
                                });
                                document.getElementById("loginBtn").classList.remove("loading");
                            }
                        }
                    )
                    .catch(error => {
                        console.log(error);
                        toast.error(error.response.data, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("loginBtn").classList.remove("loading");
                    });
            } else {
                validator.showMessages();
                this.forceUpdate();
            }
    }
    render() {
        return (
            <div>
                <div className="login-row">
                    <div className="col">
                        <h2 className="main">{loca.TranslateHelper('login-title')}</h2>
                        <form>
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="text" name="email" id="email" autoComplete="email" placeholder= { loca.TranslateHelper('email-label') } onChange={this.onChange} onKeyDown={this.onEnterPress}/>
                                    <label htmlFor="email">{loca.TranslateHelper('email-label')}</label>
                                </div>
                                {validator.message('email', this.state.email, 'required')}
                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <input type="password" name="password" id="password" autoComplete="current-password" placeholder= { loca.TranslateHelper('password-label') } onChange={this.onChange} onKeyDown={this.onEnterPress}/>
                                    <label htmlFor="password">{loca.TranslateHelper('password-label')}</label>
                                </div>
                                {validator.message('password', this.state.password, 'required')}
                            </div>

                            <div className="btn-container">
                                <button type="button" id="loginBtn" className="btn btn-full" onClick={() => this.login()}><i className="icon-arrow"/> {loca.TranslateHelper('login-label')}</button>
                            </div>
                        </form>
                    </div>
                    <div className="col"/>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Login)
