import React, { Component } from 'react';
import i18n from '../i18n';
import { withTranslation } from 'react-i18next';

import axios from "axios";
import { changeLanguageCode, Logout, openModal } from "../Helpers";
import ChangeName from "../modals/Profile/ChangeName";
import ChangePassword from "../modals/Profile/ChangePassword";
import Select from "react-select";
import EditUser from "../modals/Users/EditUser";
import EditTag from "../modals/Tags/EditTag";
import UserToBag from "../modals/Bags/UserToBag";
import { toast } from "react-toastify";
import SelectColours from "../css/SelectColours";
import loca from "../utils/localization";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLanguages: false,
            showProfile: false,
            headerVisibility: true,
            user: JSON.parse(localStorage.getItem('loginData')),
            stat_value1: 0,
            stat_value2: 0,
            stat_value3: 0,
            stat_value4: 0,
            stat_value5: 0,
            stat_valueTotal: 0,
            stat_show: false,
            searchMenu: false,
            showSearchResult: false,
            searchText: '',
            searchUsersResults: null,
            searchTagsResults: null,
            searchBagsResults: null,
            searchCategories: [],
            searchCategory: {
                value: 'All',
                label: 'All'
            }
        };

        window.Header = this;

        this.showHeader = this.showHeader.bind(this);
        this.hideHeader = this.hideHeader.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        this.getSearchCategories();


    }

    clearSearch() {
        this.setState({ searchUsersResults: null })
        this.setState({ searchTagsResults: null })
        this.setState({ searchBagsResults: null })
        this.setState({ searchText: '' })
        this.setState({ showSearchResult: false })
        document.getElementById('searchText').value = ''
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    changeLanguage(lang) {
        i18n.changeLanguage(lang);
        this.setState({ showLanguages: false });

        window.location.reload(false)
    }
    showLanguageBox() {
        this.setState({ showLanguages: true });
        this.setState({ showProfile: false });
    }
    hideLanguageBox() {
        this.setState({ showLanguages: false });
    }
    toggleLanguageBox() {
        this.setState({ showLanguages: !this.state.showLanguages });
    }
    showProfileBox() {
        this.setState({ showProfile: true });
        this.setState({ showLanguages: false });
    }
    hideProfileBox() {
        this.setState({ showProfile: false });
    }
    toggleProfileBox() {
        this.setState({ showProfile: !this.state.showProfile });
    }
    showHeader = function () {
        this.setState({ headerVisibility: true });
    }
    hideHeader = function () {
        this.setState({ headerVisibility: false });
    }
    logout = function () {
        axios(process.env.REACT_APP_API_URL + 'AppUser/logout', {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    Logout();
                }
            )
            .catch(error => {
                //this.setState({ errorMessage: error.message });
                console.error('There was an error!', error.message);
            });
    }

    searchChange = (stateId, e) => {
        switch (e.action) {
            default:
            case 'clear':
                break;
            case 'select-option':
                switch (stateId.action) {
                    default:
                    case 'EditUser':
                        openModal(<EditUser userId={stateId.value} user={stateId.user} />, { 'size': '1100px' })
                        break;
                    case 'EditTag':
                        openModal(<EditTag tagId={stateId.value} />, { 'size': '600px' })
                        break;
                    case 'EditBag':
                        openModal(<UserToBag bag={stateId.bag} />, { 'size': '800px' })
                        break;
                }

                this.setState({ searchMenu: false })
                break;
        }
    }

    searchInputChange = (stateId, e) => {
        switch (e.action) {
            default:
            case 'clear':
                this.setState({ searchMenu: false })
                this.setState({ showSearchResult: false })
                break;
            case 'input-change':
                if (stateId.length >= 1) {
                    this.setState({ searchMenu: true })
                    this.setState({ showSearchResult: true })
                } else {
                    this.setState({ searchMenu: false })
                }
                break;
            case 'set-value':

                break;
        }
    }

    headerFilterPending = function (value) {
        if (this.props.page === 'bags') {
            if (value === false) { value = null }

            window.BagsTable.setState({ filterPending: value }, function () {
                setTimeout(function () {
                    window.BagsTable.updateFilter()
                }, 10);
            })
        }
    }

    headerFilterDeadLocker = function (value) {
        if (this.props.page === 'bags') {
            if (value === false) { value = null }

            window.BagsTable.setState({ filterDeadLocker: value }, function () {
                setTimeout(function () {
                    window.BagsTable.updateFilter()
                }, 10);
            })
        }
    }

    headerFilter = (value) => {
        switch (this.props.page) {
            case 'users':
                window.UsersTable.state.filterTagType === null ?
                    window.UsersTable.setState({ filterTagType: '' }, function () { window.UsersTable.updateFilter() })
                    :
                    window.UsersTable.state.filterTagType === value
                        ? window.UsersTable.setState({ filterTagType: '' }, function () { window.UsersTable.updateFilter() })
                        : window.UsersTable.setState({ filterTagType: value }, function () { window.UsersTable.updateFilter() });
                break;
            case 'bags':
                window.BagsTable.state.filterState === null ?
                    window.BagsTable.setState({ filterState: '' }, function () {
                        setTimeout(function () {
                            window.BagsTable.updateFilter()
                        }, 10);
                    })
                    :
                    (window.BagsTable.state.filterState === value ?
                        window.BagsTable.setState({ filterState: '' }, function () {
                            setTimeout(function () {
                                window.BagsTable.updateFilter()
                            }, 10);
                        })
                        :
                        window.BagsTable.setState({ filterState: value }, function () {
                            setTimeout(function () {
                                window.BagsTable.updateFilter()
                            }, 10);
                        }))
                break;
            case 'tags':
                window.TagsTable.state.filterUserType === null ?
                    window.TagsTable.setState({ filterUserType: '' }, function () { window.TagsTable.updateFilter() })
                    :
                    window.TagsTable.state.filterUserType === value
                        ? window.TagsTable.setState({ filterUserType: '' }, function () { window.TagsTable.updateFilter() })
                        : window.TagsTable.setState({ filterUserType: value }, function () { window.TagsTable.updateFilter() });
                break;
            default:
                break; // Should never reach
        }
    }

    handleKeyUp(e) {
        var self = this;
        clearTimeout(this.searching);
        if (e.keyCode === 13) {
            if (e.target.value !== '') {
                window.location.href = '/search/' + this.state.searchCategory.value + '/' + this.state.searchText;
            }
        } else if (e.keyCode === 27) {
            this.setState({ showSearchResult: false })
        } else {
            this.setState({
                searchText: e.target.value
            }, () => {
                if (this.state.searchText === '') {
                    this.setState({ searchUsersResults: null })
                    this.setState({ searchTagsResults: null })
                    this.setState({ searchBagsResults: null })
                    this.setState({ showSearchResult: false })
                } else {
                    self.searching = setTimeout(function () {
                        self.search();
                    }, 500);
                }
            });
        }
    }

    clickToSearch() {
        var self = this;
        if (self.state.searchText !== '') {
            window.location.href = '/search/' + self.state.searchCategory.value + '/' + self.state.searchText;
        }
    }

    getSearchCategories() {
        axios(process.env.REACT_APP_API_URL + 'Search/getCategories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            const categoryArray = [];
                            for (const value of Object.values(response.data)) {
                                categoryArray.push({ value: value, label: loca.TranslateHelper(value + '-label') });
                            }
                            this.setState({ searchCategories: categoryArray })

                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                //this.setState({ errorMessage: error.message });
                console.error('There was an error!', error.message);
            });
    }

    setSearchCategory(e) {
        this.setState({ searchCategory: e })
        this.setState({ searchResults: null })
    }

    search() {
        if (this.state.searchText) {
            this.setState({ showSearchResult: true })
            var query = '';
            if (this.state.searchCategory.value === 'All') {
                query = 'GeneralResult'
            } else {
                query = this.state.searchCategory.value
            }
            axios(process.env.REACT_APP_API_URL + 'Search/search' + query + 's', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                params: {
                    searchString: this.state.searchText
                },
                withCredentials: true,
                credentials: 'same-origin',
            })
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {
                                var arr = response.data;

                                switch (this.state.searchCategory.value) {
                                    case 'All':
                                        if (arr.bagAssignmentSearchResults.length > 10) { arr.bagAssignmentSearchResults.length = 10; }
                                        if (arr.userSearchResults.length > 10) { arr.userSearchResults.length = 10; }
                                        if (arr.tagSearchResults.length > 10) { arr.tagSearchResults.length = 10; }

                                        this.setState({ searchBagsResults: arr.bagAssignmentSearchResults });
                                        this.setState({ searchUsersResults: arr.userSearchResults });
                                        this.setState({ searchTagsResults: arr.tagSearchResults });
                                        break;

                                    case 'User':
                                        if (arr.length > 10) { arr.length = 10; }
                                        this.setState({ searchUsersResults: arr });
                                        break;

                                    case 'Tag':
                                        if (arr.length > 10) { arr.length = 10; }
                                        this.setState({ searchTagsResults: arr });
                                        break;

                                    case 'LockerBag':
                                        if (arr.length > 10) { arr.length = 10; }
                                        this.setState({ searchBagsResults: arr });
                                        break;

                                    default:
                                    //Should never reach
                                }
                            }
                        } else {
                            toast.error('Error', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    //this.setState({ errorMessage: error.message });
                    console.error('There was an error!', error.message);
                });
        }
    }

    render() {
        let stat, subtitle, actionBtn;

        if (this.props.subtitle) {
            subtitle = <h3 className="main">{this.props.subtitle}</h3>
        }

        if (this.props.enableStat && this.state.stat_show) {
            this.props.page === 'bags' ?
                stat = <div className={"statistics bags-stat"} onClick={() => { window.Header.setState({ showSearchResult: false }) }}>

                    <div className="chart">
                        <svg viewBox="-5 -5 50 50" className="donut">
                            <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff" />
                            <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                stroke="#b0b0b0" strokeWidth="15" />

                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" stroke="#FFE600" strokeWidth="15" strokeDasharray={this.state.stat_value1 / this.state.stat_valueTotal * 100 + ", 100"}
                                strokeDashoffset="0"></circle>
                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" stroke="#EA5160" strokeWidth="15" strokeDasharray={this.state.stat_value3 / this.state.stat_valueTotal * 100 + ", 100"}
                                strokeDashoffset={(this.state.stat_value1) / this.state.stat_valueTotal * -100}></circle>
                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" stroke="#b0b0b0" strokeWidth="15" strokeDasharray={this.state.stat_value2 / this.state.stat_valueTotal * 100 + ", 100"}
                                strokeDashoffset={(this.state.stat_value1 + this.state.stat_value3) / this.state.stat_valueTotal * -100}></circle>
                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" stroke="#00AADB" strokeWidth="15" strokeDasharray={this.state.stat_value4 / this.state.stat_valueTotal * 100 + ", 100"}
                                strokeDashoffset={(this.state.stat_value1 + this.state.stat_value3 + this.state.stat_value2) / this.state.stat_valueTotal * -100}></circle>
                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                fill="transparent" stroke="#db7d19" strokeWidth="15" strokeDasharray={this.state.stat_value5 / this.state.stat_valueTotal * 100 + ", 100"}
                                strokeDashoffset={(this.state.stat_value1 + this.state.stat_value3 + this.state.stat_value2 + this.state.stat_value4) / this.state.stat_valueTotal * -100}></circle>
                        </svg>

                        <div className="title" onClick={() => this.headerFilter('')}>{this.state.stat_valueTotal} <span>{this.props.labelTotal}</span></div>
                        <div className={this.state.stat_value5 ? window.BagsTable.state.filterPending === true ? "subtitle pending pointer active" : "subtitle pending pointer" : "d-none"} onClick={() => this.headerFilterPending(!window.BagsTable.state.filterPending)}> <div className={'hourglass-icon'}><i className={'fas fa-hourglass-end font-size-10 mr-1'}></i></div>{this.state.stat_value5} <span>{this.props.value5Label} <div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('sync-state-tooltip-label')}</div></div></span></div>
                        <div className={this.state.stat_value6 ? window.BagsTable.state.filterDeadLocker === true ? "subtitle pending pointer active" : "subtitle pending pointer" : "d-none"} onClick={() => this.headerFilterDeadLocker(!window.BagsTable.state.filterDeadLocker)}> <div className={'hourglass-icon'}><i className={'fas fa-exclamation-triangle font-size-10 mr-1'}>&nbsp;</i></div>&nbsp;{this.state.stat_value6} <span>{this.props.value6Label} <div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('dead-locker-tooltip-label')}</div></div></span></div>
                    </div>
                    <div className="stat">
                        <div className={window.BagsTable.state.filterState !== "" && window.BagsTable.state.filterState === 0 ? "stat-col free pointer active" : "stat-col free pointer"} onClick={() => this.headerFilter(0)}>{this.state.stat_value1} <span>{this.props.value1Label}<div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('free-state-tooltip-label')}</div></div></span></div>
                        <div className={this.state.stat_value4 ? window.BagsTable.state.filterState === 3 ? "stat-col cleaning pointer active" : "stat-col cleaning pointer" : 'd-none'} onClick={() => this.headerFilter(3)}>{this.state.stat_value4} <span>{this.props.value4Label} <div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('cleaning-state-tooltip-label')}</div></div></span></div>
                        <div className={window.BagsTable.state.filterState === 1 ? "stat-col assigned pointer active" : "stat-col assigned pointer"} onClick={() => this.headerFilter(1)}>{this.state.stat_value2} <span>{this.props.value2Label}<div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('assigned-state-tooltip-label')}</div></div></span></div>
                        <div className={window.BagsTable.state.filterState === 2 ? "stat-col outofuse pointer active" : "stat-col outofuse pointer"} onClick={() => this.headerFilter(2)}>{this.state.stat_value3} <span>{this.props.value3Label} <div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('tag-missing-state-tooltip-label')}</div></div></span></div>
                        <div className="clear">&nbsp;</div>
                    </div>
                </div> : this.props.page === 'users' ?
                    stat = <div className={"statistics"} onClick={() => { window.Header.setState({ showSearchResult: false }) }}>

                        <div className="chart">
                            <svg viewBox="-5 -5 50 50" className="donut">
                                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff" />
                                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                    stroke="#b0b0b0" strokeWidth="15" />

                                <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                    fill="transparent" stroke="#FFE600" strokeWidth="15" strokeDasharray={this.state.stat_value1 / this.state.stat_valueTotal * 100 + ", 100"}
                                    strokeDashoffset="0"></circle>
                            </svg>

                            <div className="title" onClick={() => this.headerFilter('')}>{this.state.stat_valueTotal} <span>{this.props.labelTotal}</span></div>
                            {!JSON.parse(localStorage.getItem('configData')).isPendingUserDeletionDisabled && this.state.stat_value4 !== 0 && <div className={window.UsersTable.state.filterTagType !== "" && window.UsersTable.state.filterTagType === 3 ? "subtitle deletion pointer active" : "subtitle deletion pointer"} onClick={() => this.headerFilter(3)}>{this.state.stat_value4} <span>{this.props.value4Label} <div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('deletion-pending-state-tooltip-label')}</div></div></span></div>}

                        </div>
                        <div className="stat">
                            <div
                                className={window.UsersTable.state.filterTagType === "2" ? "stat-col free pointer active" : "stat-col free pointer"}
                                onClick={() => this.headerFilter("2")}>
                                {this.state.stat_value1}
                                <span>{this.props.value1Label}
                                    <div className={'ctooltip'}>
                                        <div className={'tt'}>{loca.TranslateHelper('no-tag-state-tooltip-label')}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div
                                className={window.UsersTable.state.filterTagType !== "" && window.UsersTable.state.filterTagType === "1" ? "stat-col notag pointer active" : "stat-col notag pointer"}
                                onClick={() => this.headerFilter("1")}>
                                {this.state.stat_value2}
                                <span>{this.props.value2Label}
                                    <div className={'ctooltip'}>
                                        <div className={'tt'}>{loca.TranslateHelper('with-tag-state-tooltip-label')}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className="clear">&nbsp;</div>
                        </div>
                    </div> : stat = <div className={"statistics"}>

                        <div className="chart">
                            <svg viewBox="-5 -5 50 50" className="donut">
                                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff" />
                                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                    stroke="#b0b0b0" strokeWidth="15" />

                                <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954"
                                    fill="transparent" stroke="#FFE600" strokeWidth="15" strokeDasharray={this.state.stat_value1 / this.state.stat_valueTotal * 100 + ", 100"}
                                    strokeDashoffset="0"></circle>
                            </svg>

                            <div className="title" onClick={() => this.headerFilter('')}>{this.state.stat_valueTotal} <span>{this.props.labelTotal}</span></div>
                        </div>
                        <div className="stat">
                            <div className={window.TagsTable.state.filterUserType === "0" ? "stat-col free pointer active" : "stat-col free pointer"} onClick={() => this.headerFilter("0")}>{this.state.stat_value1} <span>{this.props.value1Label}<div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('free-tag-state-tooltip-label')}</div></div></span></div>
                            <div className={window.TagsTable.state.filterUserType === "1" ? "stat-col assigned pointer active" : "stat-col assigned pointer"} onClick={() => this.headerFilter("1")}>{this.state.stat_value2} <span>{this.props.value2Label}<div className={'ctooltip'}><div className={'tt'}>{loca.TranslateHelper('assigned-tag-state-tooltip-label')}</div></div></span></div>
                            <div className="clear">&nbsp;</div>
                        </div>
                    </div>
        }


        if (this.props.actionBtn && !this.state.stat_show) {
            actionBtn = this.props.actionBtn
        }

        return (
            <div className="inner-padding">
                <header className={`${this.state.headerVisibility ? "visible" : "invisible"}`}>
                    <div className="statusbar">
                        <div className="search">
                            <div className={'categories'}>
                                <Select
                                    isSearchable={false}
                                    isClearable={false}
                                    name="category"
                                    id="category"
                                    className="SelectContainer"
                                    styles={SelectColours}
                                    placeholder={loca.TranslateHelper('choose-category-label')}
                                    onChange={
                                        e => { this.setSearchCategory(e); }
                                    }
                                    value={this.state.searchCategories ? this.state.searchCategories.filter(option => option.value === this.state.searchCategory.value) : ''}
                                    options={this.state.searchCategories} />
                            </div>
                            <div className={'search-icon'} onClick={() => this.clickToSearch()}>
                                <i className={'icon-search'} />
                            </div>
                            {window.Desktop.state.showSearch ?
                                <input type="text" placeholder={i18n.t('search-placeholder')} id={'searchText'} onFocus={() => { this.setState({ showSearchResult: true }) }} onKeyUp={this.handleKeyUp} /> : ''}


                            {((this.state.searchUsersResults || this.state.searchTagsResults || this.state.searchCategory.value) && this.state.showSearchResult) && (
                                <div className={'search-results'}>
                                    {((this.state.searchCategory.value === 'User' || this.state.searchCategory.value === 'All') && this.state.searchUsersResults) &&
                                        <div>
                                            <h6>{loca.TranslateHelper('users-label')}</h6>
                                            <ul>
                                                {!this.state.searchUsersResults.length && <li>{(loca.TranslateHelper('no-results-label'))}</li>}
                                                {this.state.searchUsersResults.map((item, key) => <li key={item.userId} onClick={() => openModal(<EditUser userId={item.userId} user={item} />, { 'size': '1100px' })}><i className={'icon-user mr-3'}></i>{item.firstName} {item.lastName} <span>ID: {item.externalId}</span></li>)}
                                            </ul>
                                        </div>
                                    }
                                    {((this.state.searchCategory.value === 'Tag' || this.state.searchCategory.value === 'All') && this.state.searchTagsResults) &&
                                        <div>
                                            <h6>{loca.TranslateHelper('tags-label')}</h6>
                                            <ul>
                                                {!this.state.searchTagsResults.length && <li>{(loca.TranslateHelper('no-results-label'))}</li>}
                                                {this.state.searchTagsResults.map((item, key) => <li key={item.tagId} onClick={() => openModal(<EditTag tagId={item.tagId} />)}><i className={'icon-tag mr-3'}></i>{item.tagName} <span>ID: {item.externalId}</span></li>)}
                                            </ul>
                                        </div>
                                    }
                                    {((this.state.searchCategory.value === 'LockerBag' || this.state.searchCategory.value === 'All') && this.state.searchBagsResults) &&
                                        <div>
                                            <h6>{loca.TranslateHelper('bags-label')}</h6>

                                            <ul>
                                                {!this.state.searchBagsResults.length && <li>{(loca.TranslateHelper('no-results-label'))}</li>}
                                                {this.state.searchBagsResults.map((item, key) => <li key={item.userId} onClick={() => openModal(<UserToBag bag={item} />, { 'size': '800px' })}><i className={'icon-bag mr-3'}></i> {item.bagNumber} / {item.systemName} / {item.room} <span>{item.building}</span></li>)}

                                            </ul>
                                        </div>
                                    }
                                </div>
                            )}

                            {this.state.searchText && <div className={'clear-search'} onClick={() => this.clearSearch()}><i className={'icon-cross mr-2'}></i>{loca.TranslateHelper('clear-label')}</div>}

                        </div>

                        <div className={`${this.state.showProfile ? "profile opened" : "profile"}`}>
                            <i className="icon-user">&nbsp;</i>
                            <div className="name"><span>{loca.TranslateHelper('header-welcome')}</span>{this.state.user.lastName}</div>
                            <button className="dropdown-icon" onMouseEnter={() => this.showProfileBox()}><i className="icon-dropdown">&nbsp;</i></button>
                            <div className="clear">&nbsp;</div>

                            <div className="profile-box" onMouseLeave={() => this.hideProfileBox()}>
                                <ul>
                                    <li><button onClick={() => openModal(<ChangeName firstName={this.state.user['firstName']} lastName={this.state.user['lastName']} />, { 'size': '600px' })}>{loca.TranslateHelper('header-changeName')}</button></li>
                                    <li><button onClick={() => openModal(<ChangePassword />, { 'size': '600px' })}>{loca.TranslateHelper('header-changePassword')}</button></li>
                                    <li><button onClick={() => this.logout()}>{loca.TranslateHelper('header-logout')}</button></li>
                                </ul>
                            </div>
                        </div>
                        <div className={`${this.state.showLanguages ? "language opened" : "language"}`}>
                            {loca.language} <button onMouseEnter={() => this.showLanguageBox()}><i className="icon-dropdown" >&nbsp;</i></button>

                            <div className="languages" onMouseLeave={() => this.hideLanguageBox()}>
                                <ul>
                                    {Object.keys(i18n.services.resourceStore.data).map((language, index) => {
                                        return <li key={index}><button onClick={() => {
                                            this.changeLanguage(language);
                                        }}>{language}</button></li>;
                                    })}
                                </ul>
                            </div>

                        </div>
                        <div className="clear">&nbsp;</div>
                    </div>
                    <div className="headline" onClick={() => { window.Header.setState({ showSearchResult: false }) }}>
                        {stat}
                        {actionBtn}

                        <h1 className="main">{this.props.headerTitle}</h1>
                        {subtitle}
                    </div>
                </header>
            </div>
        )
    }
}

export default withTranslation()(Header);
