import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import {changeLanguageCode, Logout} from "../../Helpers";
import axios from "axios";
import {toast} from "react-toastify";
import loca from "../../utils/localization"

class ChangeStatus extends Component {

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId,e){
        this.setState({[stateId] : e.value})
    }

    updateSystem(){
        axios(process.env.REACT_APP_API_URL+'System/changeActiveStatus/'+this.props.systemId,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json-patch+json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            data:!this.props.isActive,
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 202){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            toast.success(loca.TranslateHelper('change-system-success-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            window.Modal.props.closeAction();
                            window.Systems.getSystems();
                        }
                    }else{
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt=""/>
                <h2 className="main tc">{t('change-system-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {this.props.isActive ? t('are-you-sure-deactivate-system-label') : t('are-you-sure-activate-system-label')}
                        <div className="form-group">
                            <button id="syncBTN" className="btn btn-full mt-3" onClick={() => this.updateSystem()}>{this.props.isActive ? t('deactivate-label') : t('activate-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ChangeStatus);
