import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import axios from "axios";
import { changeLanguageCode, Logout, openModal } from "../Helpers";
import DataTable from "react-data-table-component";
import EditSystem from "../modals/System/EditSystem";
import { toast } from "react-toastify";
import ChangeStatus from "../modals/System/ChangeStatus";
import SynchResponse from "../modals/System/SynchResponse";
import loca from "../utils/localization"

class Systems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            communicationMode: JSON.parse(localStorage.getItem('communicationMode')),
            selectedSystems: new Set(),
        };

        window.Systems = this;
    }

    componentDidMount() {
        this.getSystems();
    }

    getSystems() {
        axios(process.env.REACT_APP_API_URL + 'System', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ data: response.data })
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    synchSystems = () => {
        axios(process.env.REACT_APP_API_URL + 'Assignment/syncAllData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
            data: [...this.state.selectedSystems]
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ selectedSystems: new Set() })
                            openModal(
                                <SynchResponse
                                    totalSystemCount={response.data.totalSystemCount}
                                    selectedSystemCount={response.data.selectedSystemCount}
                                    failedSychronizationCount={response.data.failedSychronizationCount}
                                />, { 'size': '600px' }
                            );
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    onGlobalSelectorClicked = (event) => {
        if (event.target.checked) {
            // Check all
            this.setState(state => ({
                ...state,
                selectedSystems: new Set(state.data.map(d => d.systemId)),
            }));
        }
        else {
            // Uncheck all
            this.setState(state => ({
                ...state,
                selectedSystems: new Set(),
            }));
        }
    }

    render = () => {

        const columns = [
            {
                name: <div className="ctooltip">
                    <div>
                        <input type="checkbox" id={'cb_all'} onClick={this.onGlobalSelectorClicked} />
                        <label htmlFor={'cb_all'} className={'selector'} />
                    </div>
                </div>,
                sortable: false,
                width: "90px",
                selector: (row) => row.systemId,
                cell: row => {
                    const htmlId = 'cb_' + row.systemId;
                    const isSelected = this.state.selectedSystems.has(row.systemId);
                    const toggle = () => {
                        this.setState(state => {
                            const newSelecteds = new Set(state.selectedSystems);
                            if (isSelected)
                                newSelecteds.delete(row.systemId);
                            else
                                newSelecteds.add(row.systemId);

                            return {
                                ...state,
                                selectedSystems: newSelecteds,
                            }
                        })
                    }

                    return (
                        <div className={'systemStatus'}>
                            <input type="checkbox" id={htmlId} onClick={toggle} />
                            <label htmlFor={htmlId} className={'selector'}>
                                <div className={`fake-checkbox ${isSelected && 'checked'}`} />
                            </label>
                        </div>
                    )
                }
            }, {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('room-name-label')}</div>{loca.TranslateHelper('room-name-label')}</div>,
                id: 'roomName',
                width: "160px",
                sortable: true,
                selector: (row, index) => row.roomName,
                cell: row => <div>{row.roomName}</div>,
            }, {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('system-name-label')}</div>{loca.TranslateHelper('system-name-label')}</div>,
                id: 'systemname',
                width: '120px',
                sortable: true,
                selector: (row, index) => row.systemName,
                cell: row => <div>{row.systemName}</div>,
            },
            {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('system-type-label')}</div>{loca.TranslateHelper('system-type-label')}</div>,
                sortable: true,
                width: '120px',
                selector: (row, index) => row.systemType,
                cell: row => <div className={'badge badge-warning'}>{row.systemType}</div>,
            },
            {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('system-serial-number-label')}</div>{loca.TranslateHelper('system-serial-number-label')}</div>,
                sortable: true,
                width: '160px',
                selector: (row, index) => row.systemSerialNumber,
                cell: row => <div>{row.systemSerialNumber}</div>,
            },
            {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('device-serial-number-label')}</div>{loca.TranslateHelper('device-serial-number-label')}</div>,
                sortable: true,
                width: '160px',
                selector: (row, index) => row.deviceSerialNumber,
                cell: row => <div>{row.deviceSerialNumber}</div>,
            },
            {
                name:
                    <div className="ctooltip">
                        <div className="tt">
                            {loca.TranslateHelper(this.state.communicationMode + '-label')}
                        </div>
                        {loca.TranslateHelper(this.state.communicationMode + '-label')}
                    </div>,
                sortable: true,
                minWidth: '160px',
                selector: (row, index) => row.iotHubDeviceId,
                cell: row => <div>{row.iotHubDeviceId}</div>,
            },
            {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('is-active-label')}</div>{loca.TranslateHelper('is-active-label')}</div>,
                sortable: true,
                width: '90px',
                selector: (row, index) => row.isActive,
                cell: row => (
                    <div onClick={() => openModal(<ChangeStatus systemId={row.systemId} isActive={row.isActive} />, { 'size': '600px' })} className={'active ' + row.isActive} />
                )
            },
            {
                name: <div className="ctooltip"><div className="tt">{loca.TranslateHelper('is-online-label')}</div>{loca.TranslateHelper('is-online-label')}</div>,
                sortable: true,
                width: '90px',
                selector: (row, index) => row.isOnline,
                cell: row =>
                    <div>
                        <div className={"status " + row.isOnline} />
                    </div>,
            },
            {
                name: '',
                sortable: false,
                width: '90px',
                cell: row =>
                (
                    <div>
                        {JSON.parse(localStorage.getItem('loginData')).role === 'Admin' && <button className="btn btn-action" onClick={() => openModal(<EditSystem systemId={row.systemId} system={row} />, { 'size': '600px' })}><i className="icon-edit" /></button>}
                    </div>
                )
            },
        ];

        return (
            <div>
                <Header
                    headerTitle={loca.TranslateHelper('side-settings')}
                    subtitle={loca.TranslateHelper('side-systems')}
                    enableStat={false}
                />
                <div className="inner-padding">
                    <button
                        className="btn btn-full"
                        disabled={this.state.selectedSystems.size === 0}
                        onClick={() => this.synchSystems()}>
                        <i className="icon-synch" />
                        {loca.TranslateHelper('synchronize-assignments-label')}
                    </button>
                </div>
                <div className="mt-5">
                    <DataTable
                        id="systems-table"
                        className="datatable"
                        noHeader
                        customStyles={{ tableWrapper: { style: { tableLayout: "fixed" } } }}
                        pagination={false}
                        highlightOnHover
                        columns={columns}
                        data={this.state.data}
                    />
                </div>
            </div>
        )
    }
}

export default withTranslation()(Systems)
