import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { changeLanguageCode, Logout, openModal, RefreshView } from "../../Helpers";
import CreateUser from "../Users/CreateUser";
import AssignExist from "./AssignExist";
import Select, { components, createFilter } from "react-select";
import SelectColours from "../../css/SelectColours";
import RemoveUserFromBag from "./RemoveUserFromBag";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"
import { LoadingOptionsMessage } from '../../atoms/react-select/LoadingOptionsMessage';

const CustomValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue, setValue } = props;
    const selectedValue = getValue();

    if (!hasValue) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    return (
        <components.ValueContainer {...props}>
            <div className={'row pt-2'}>
                <div className={'col-12'}>
                    {selectedValue[0].label}
                    {selectedValue[0].tags === 'false' ? <i className="icon-info text-light hasTooltip ml-1 d-inline"><div className="ctooltip"><div className="tt">{loca.TranslateHelper('user-without-tag-warning-label')}</div></div></i> : ''}
                </div>
                <button className={'clearField'}><i className={'fa fa-times'} onClick={() => { setValue(null); }}></i></button>
            </div>
        </components.ValueContainer>
    );
};

const CustomOption = ({ data, innerProps }) =>
    data.isDisabledElement.every(element => element === false) ?
        data.tags === 'true' ? (
            <div {...innerProps} key={data.uid}>
                <div className="row custom-dropdown mx-0">
                    <div className="col col-md-6">
                        <div className="checkbox-container">
                            {data.text}
                        </div>
                    </div>
                    <div className="col col-md-6 uid text-right">[UID : {data.uid}]</div>
                </div>

            </div>
        ) : <div {...innerProps} key={data.uid}>
            <div className="row custom-dropdown mx-0">
                <div className="col col-md-6">
                    <div className="checkbox-container">
                        {data.text} <i className="icon-info text-light  hasTooltip"><div className="ctooltip"><div className="tt">{loca.TranslateHelper('user-without-tag-warning-label')}</div></div></i>
                    </div>
                </div>
                <div className="col col-md-6 uid text-right">[UID : {data.uid}]</div>
            </div>

        </div> : <div {...innerProps} key={data.uid}><div className="row custom-dropdown mx-0 d-none"></div></div>;

class UserToBag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAssignedUser: false,
            assignedUser: [],
            bagId: this.props.bag.bagId ? this.props.bag.bagId : this.props.bag[0].bagId,
            currentBag: this.props.bag.bagId ? this.props.bag : this.props.bag[0],
            assignedUsers: [],
            selectedUserId: this.props.createdUser ? this.props.createdUser.userId : '',
            selectedUserName: this.props.createdUser ? this.props.createdUser.lastName + ' ' + this.props.createdUser.firstName : '',
            user: this.props.createdUser ? this.props.createdUser.userId : '',
            users: [],
            bag: this.props.bag,
            genderAlert: false,
            assignmentAlert: false,
            createdUserId: '',
            loadingUsers: false,
        };

        this.UserToBagValidator = new SimpleReactValidator({
            locale: loca.language,
        })

        window.userToBag = this
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onInputChange = (values, { action }) => {
        // Action is undefined on clear
        if (action === undefined) {
            this.setState({
                assignmentAlert: false,
                genderAlert: false,
            })
        }

        switch (action) {
            case 'set-value':
                this.setState({ 'user': null });
                this.setState({ 'selectedUserId': null });
                this.setState({ 'selectedUserName': null });
                this.setState({ 'genderAlert': false });
                this.setState({ 'assignmentAlert': false });
                break;
            case 'select-option':
                this.setState({ 'user': values.value });
                this.setState({ 'selectedUserId': values.value });
                this.setState({ 'selectedUserName': values.label });

                values.label = values.label.split('#')[0]

                if (values.gender === this.state.currentBag.roomGender || values.gender === null || values.gender === '' || values.gender === 'Neutral' || this.state.currentBag.roomGender === 'Neutral') {
                    this.setState({ 'genderAlert': false });
                } else {
                    this.setState({ 'genderAlert': true });
                }

                if (values.hasAssignment) {
                    this.setState({ 'assignmentAlert': true });
                } else {
                    this.setState({ 'assignmentAlert': false });
                }

                return false;
            case 'menu-close':
                return;
            default:
                return;
        }
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    getUsers() {
        this.setState({ loadingUsers: true });
        axios(process.env.REACT_APP_API_URL + 'User', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            let users = Object.keys(response.data).map((key) => {
                                return {
                                    tags: response.data[key]['tags'].length ? 'true' : 'false',
                                    value: response.data[key]['userId'],
                                    label: response.data[key]['lastName'] + ' ' + response.data[key]['firstName'] + ' #[' + response.data[key]['externalId'] + ']',
                                    text: response.data[key]['lastName'] + ' ' + response.data[key]['firstName'],
                                    uid: response.data[key]['externalId'],
                                    gender: response.data[key]['genderName'],
                                    hasAssignment: response.data[key]['hasAssignment'],
                                    isDisabledElement: Boolean(false) || this.state.assignedUsers.map((item, index) => (response.data[key]['userId'] === item.value && Boolean(true))),
                                }
                            });
                            this.setState({ users: users, loadingUsers: false });

                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    getAssignedUsers() {
        axios(process.env.REACT_APP_API_URL + 'Assignment/userAssignments/' + this.state.bagId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            if (response.data.length) {
                                this.setState({ isAssignedUser: true });
                            }
                            let assignedUsers = Object.keys(response.data).map((key) => {
                                return {
                                    value: response.data[key]['userId'],
                                    label: response.data[key]['lastName'] + ' ' + response.data[key]['firstName'],
                                    uid: response.data[key]['externalId']
                                }
                            });

                            const uniqueUsers = Array.from(new Set(assignedUsers.map(a => a.value)))
                                .map(value => {
                                    return assignedUsers.find(a => a.value === value)
                                })

                            this.setState({ assignedUsers: uniqueUsers });
                        }
                    } else {
                        toast.error('error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    assignBagToUser() {
        if (this.UserToBagValidator.allValid()) {
            if (this.state.isAssignedUser) {
                openModal(<AssignExist user={this.state.user} bag={this.state.bag} assignedUser={this.state.assignedUsers[0]} selectedUserId={this.state.selectedUserId} selectedUserName={this.state.selectedUserName} />, { 'size': '600px' });
            } else {
                this.doAssign();
            }
        } else {
            this.UserToBagValidator.showMessages();
            this.forceUpdate();
        }
    }

    doAssign() {
        const bags = []
        if (this.props.bag.length >= 1) {
            Object.keys(this.props.bag).map(key =>
                bags.push({ bagId: this.props.bag[key].bagId, userId: this.state.user })
            )
        } else {
            bags.push({ bagId: this.props.bag.bagId, userId: this.state.user })
        }


        this.setState({ bagToRemove: bags });
        document.getElementById("assignUserBTN").classList.add("loading");
        if (this.UserToBagValidator.allValid()) {
            //axios(process.env.REACT_APP_API_URL+'Assignment',{
            axios(process.env.REACT_APP_API_URL + 'Assignment/addMultipleAssignments', {
                method: 'POST',
                data: bags,
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 204) {
                            if (response.data === 'User not logged in') {
                                Logout()
                            } else {


                                window.Modal.props.closeAction();

                                toast.success(loca.TranslateHelper('assign-to-bag-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                RefreshView(window.View.state.currentview);

                                document.getElementById("assignUserBTN").classList.remove("loading");
                            }
                        } else {
                            toast.error(response.data, {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            document.getElementById("assignUserBTN").classList.remove("loading");
                        }
                    }
                )
                .catch(error => {
                    console.log(error);
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    document.getElementById("assignUserBTN").classList.remove("loading");
                });
        } else {
            this.UserToBagValidator.showMessages();
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this.getUsers();
        this.getAssignedUsers();
    }

    render = () => {
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('user-to-bag-assignment-label')}</h2>
                <div className="row subrow mb-5">
                    <div className="col col-auto">
                        {this.props.bag.length >= 1 && Object.keys(this.props.bag).map(key =>
                            <div key={key}>
                                {this.props.bag[key].building}
                                <span className="font-bold"> / {this.props.bag[key].room}</span>
                            </div>
                        )}

                        {!this.props.bag.length && <div>
                            {this.props.bag.building}
                            <span className="font-bold"> / {this.props.bag.room}</span>
                        </div>}
                    </div>
                    <div className="col col-auto">

                        {this.props.bag.length >= 1 && Object.keys(this.props.bag).map(key =>
                            <div key={key}>
                                {loca.TranslateHelper('system-label')}<span className="font-bold"> / {this.props.bag[key].systemName}</span>
                            </div>
                        )}

                        {!this.props.bag.length && <div>
                            {loca.TranslateHelper('system-label')}<span className="font-bold"> / {this.props.bag.systemName}</span>
                        </div>}

                    </div>
                    <div className="col col-auto">
                        {this.props.bag.length >= 1 && Object.keys(this.props.bag).map(key =>
                            <div key={key}>
                                {loca.TranslateHelper('bag-number-label')}<span className="font-bold"> / {this.props.bag[key].bagNumber}</span>
                            </div>
                        )}

                        {!this.props.bag.length && <div>
                            {loca.TranslateHelper('bag-number-label')}<span className="font-bold"> / {this.props.bag.bagNumber}</span>
                        </div>}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col col-md-6">
                        <div className="row">
                            <div className="col col-md-12">
                                <h5 className="main mb-3">{loca.TranslateHelper('assign-new-user-label')}</h5>

                                <div className="form-group">
                                    <div className="input-group absolute-label">
                                        <Select
                                            isSearchable
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            name="user"
                                            id="user"
                                            defaultValue={this.props.createdUser && this.props.createdUser.userId ? { 'value': this.props.createdUser.userId, 'label': this.props.createdUser.lastName + ' ' + this.props.createdUser.firstName } : null}
                                            onChange={this.onInputChange}
                                            isClearable
                                            className="SelectContainer"
                                            styles={SelectColours}
                                            placeholder={loca.TranslateHelper('choose-user-label')}
                                            options={this.state.users}
                                            components={{
                                                Option: CustomOption,
                                                ValueContainer: CustomValueContainer,
                                                NoOptionsMessage: this.state.loadingUsers ? LoadingOptionsMessage : components.NoOptionsMessage
                                            }} />
                                        <label htmlFor="user">{loca.TranslateHelper('name-label')} <em>*</em></label>
                                    </div>

                                    {this.UserToBagValidator.message('user', this.state.user, 'required')}

                                    {this.state.genderAlert && <GenderAlert roomGender={this.state.currentBag.roomGender} />}
                                    {this.state.assignmentAlert && <AssignmentAlert />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <div className="row">
                            <div className="col col-md-12">
                                <h5 className="main mb-3">{loca.TranslateHelper('current-user-label')}</h5>
                                <ul id="tags" className="tagList">

                                    {this.state.assignedUsers.map((item, index) => (
                                        (item.value ? <li key={item.value}><i className="icon-user" /> {item.label} <button onClick={() => openModal(<RemoveUserFromBag userId={item.value} userName={item.label} bag={this.state.bag} />, { 'size': '600px', 'closeAction': () => openModal(<UserToBag bag={this.state.bag} />, { 'size': '800px' }) })}><i className="icon-delete" /></button></li> : '')
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col col-md-12">
                        <div className="form-group">
                            <button className="btn btn-full mt-3" id="assignUserBTN" onClick={() => this.assignBagToUser()}><i className="icon-plus" />{loca.TranslateHelper('assign-user-label')}</button>
                            <button disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled ? 'disabled' : ''} className="btn btn-simple mt-3 ml-3 px-3" onClick={() => openModal(<CreateUser bag={this.state.bag} />, { 'size': '1000px', 'props': { 'bag': this.state.bag }, 'closeAction': (createdUser) => openModal(<UserToBag createdUser={createdUser} bag={this.state.bag} />, { 'size': '800px' }) })}><i className="icon-add-user" />{loca.TranslateHelper('create-new-user-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const GenderAlert = ({ roomGender }) => {
    return (
        <div className="col col-auto p-0 mt-2">
            <div className="font-bold alert-box"> {roomGender === 'Male' || roomGender === 'Herren' ? loca.TranslateHelper('gender-alert-female-label') : loca.TranslateHelper('gender-alert-male-label')} </div>
        </div>
    )
}

const AssignmentAlert = () => {
    return (
        <div className="col col-auto p-0 mt-2">
            <div className="font-bold alert-box"> {loca.TranslateHelper('user-already-has-bag-label')} </div>
        </div>
    )
}

export default withTranslation()(UserToBag);
