export const dateUtils = {
    getDaysUntilToday(dateString: string): number {
        const today = new Date();
        const date = new Date(dateString);

        const diffInTime = today.getTime() - date.getTime();

        const diffInDays = diffInTime / (1000 * 60 * 60 * 24);

        return Math.floor(diffInDays);
    }
}