import React, { useState } from "react";

const formats = ["csv", "xlsx"] as const;

type Formats = typeof formats[number];

interface Props { exportWithFormat(format: Formats): void }

export function ExportContent(props: Props) {
    const [visible, setVisisble] = useState(false);
    const [format, setFormat] = useState<Formats>(formats[0]);

    return (
        <div className="export-selector-container">
            <button onClick={() => props.exportWithFormat(format)}>
                <i className="fas fa-download" />
            </button>
            <div
                className={visible ? "export-selector opened" : "export-selector"}
                onMouseEnter={() => setVisisble(true)}
                onMouseLeave={() => setVisisble(false)}
            >
                {format}
                <button className="icon-dropdown" />

                <div className="export-selector-dropdown" >
                    <ul>
                        {formats.map((formatOption) => (<li key={formatOption}>
                            <button
                                onClick={() => {
                                    setFormat(formatOption);
                                    setVisisble(false);
                                }}
                            >
                                {formatOption}
                            </button>
                        </li>))}
                    </ul>
                </div>
            </div>
        </div>)
}