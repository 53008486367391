import React from "react";
import loca from "../utils/localization";

interface Props {
  columns: string[];

  isVisible: boolean;
  omit: Record<string, boolean>;

  toggleHideVisibility(): void;
  saveHideColumns(column: string): void;
}

export function ColumnVisibilityButton(props: Props) {
  return (
    <>
      <button
        className={"float-right"}
        onClick={() => props.toggleHideVisibility()}
      >
        <i className="fas fa-eye mr-2" />
      </button>

      {props.isVisible && <ColumnVisibilityTable {...props} />}
    </>
  );
}

function ColumnVisibilityTable(props: Props) {
  return (
    <div
      className={"column-hide"}
      onMouseLeave={() => props.toggleHideVisibility()}
    >
      <h4>{loca.TranslateHelper("responsive-table-label")}</h4>
      <ul>
        {props.columns.map((column) => {
          const isOmitted = props.omit[column];
          const isChecked = !isOmitted;
          return (
            <li key={column}>
              <div
                className={"row align-items-center"}
                onClick={() => props.saveHideColumns(column)}
              >
                <div className={"col-auto pr-2"}>
                  <div className={`fake-checkbox ${isChecked && "checked"}`}>
                    <input type="checkbox" id={`cb_${column}`} />
                    <label htmlFor={`cb_${column}`} className={"selector"} />
                  </div>
                </div>
                <div className={"col-auto pl-0"}>
                  {loca.TranslateHelper(`${column}-label`)}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
