import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {changeLanguageCode, Logout, RefreshView} from "../../Helpers";
import loca from "../../utils/localization"

class RemoveTagFromUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deletable: true,
        }
    }
    checkIfSafe(tagId){
        axios(process.env.REACT_APP_API_URL+'Tag/isDeleteSafe/'+tagId,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in'){
                            Logout();
                        }else {
                            if(response.data === false){
                                this.setState({
                                    deletable: false,
                                });
                            }
                        }
                    }else{
                        toast.error('ERROR', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("deleteBTN").classList.remove("loading");
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
                document.getElementById("deleteBTN").classList.remove("loading");
            });
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId,e){
        this.setState({[stateId] : e.value})
    }

    deleteTagFromUser(tagId){
        document.getElementById("deleteBTN").classList.add("loading");
        axios(process.env.REACT_APP_API_URL+'Assignment/removeUserTagAssignment',{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            data:{
                'userId':this.props.userId,
                'tagId':this.props.tagId
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in'){
                            Logout();
                        }else {
                            RefreshView(window.View.state.currentview);

                            window.Modal.props.closeAction(this.props.tagId);

                            toast.success(loca.TranslateHelper('delete-tag-success-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            document.getElementById("deleteBTN").classList.remove("loading");
                        }
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    componentDidMount() {
        this.checkIfSafe(this.props.tagId);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt=""/>
                <h2 className="main tc">{loca.TranslateHelper('remove-tag-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        { this.state.deletable ?
                            <p>{t('confirm-remove-tag-from-user-label',{user: this.props.userName, tag: this.props.tagName})}</p> : <p>{t('unsafe-confirm-remove-tag-from-user-label',{user: this.props.userName, tag: this.props.tagName})}</p> }
                        <div className="form-group">
                            <button id="deleteBTN" className="btn btn-full mt-3" onClick={() => this.deleteTagFromUser(this.props.tagId)}>{loca.TranslateHelper('delete-tag-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(RemoveTagFromUser);
