import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {changeLanguageCode, Logout} from "../../Helpers";
import loca from "../../utils/localization"

class DeleteJobTitle extends Component {

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId,e){
        this.setState({[stateId] : e.value})
    }

    deleteJobTitle(jobTitleId){
        document.getElementById("deleteBTN").classList.add("loading");
        axios(process.env.REACT_APP_API_URL+'CustomFields/jobTitle/'+jobTitleId,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 204){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else {
                            window.CustomFields.getJobTitles();

                            toast.success(loca.TranslateHelper('delete-jobtitle-success-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            window.Modal.props.closeAction();
                            document.getElementById("deleteBTN").classList.remove("loading");
                        }
                    }else{
                        toast.error('ERROR', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("deleteBTN").classList.remove("loading");
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
                document.getElementById("deleteBTN").classList.remove("loading");
            });
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt=""/>
                <h2 className="main tc">{loca.TranslateHelper('delete-jobtitle-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {t('confirm-delete-jobtitle-label',{jobtitle: this.props.jobTitleName})}
                        <div className="form-group">
                            <button id="deleteBTN" className="btn btn-full mt-3" onClick={() => this.deleteJobTitle(this.props.jobTitleId)}>{loca.TranslateHelper('delete-jobtitle-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DeleteJobTitle);
