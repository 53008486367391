import React from "react";
import i18n from "../i18n"
import { Trans as TransInner, I18nextProvider } from "react-i18next";

const Translate = React.memo(function TranslateWithOverride({ locaKey, values }: { locaKey: string, values?: {} }) {
    const modifiedLocaKey = overrideIfNecessary(locaKey);

    return <TransInner i18nKey={modifiedLocaKey} values={values} />
});

function TranslateHelper(locaKey: string, values: {} | undefined = undefined) {
    return <Translate locaKey={locaKey} values={values} />;
}

function t(locaKey: string, values?: {}) {
    const modifiedLocaKey = overrideIfNecessary(locaKey);

    return i18n.t(modifiedLocaKey, values);
}

const thirdPartyLockerOverrides: {
    [key: string]: string | undefined
} = {
    "bag-label": "compartment-label",
    "bags-label": "compartments-label",
    "side-lockerBags": "side-compartments-label",
    "LockerBag-label": "side-compartments-label",
    "user-to-bag-assignment":	"user-to-unit-assignment",
    "user-to-bag-assignment-label":	"user-to-unit-assignment-label",
    "bag-number-label":	"unit-number-label",
    "assign-to-bag-success-label":	"assign-to-unit-success-label",
    "remove-user-from-bag-success-label":	"remove-user-from-unit-success-label",
    "unsafe-confirm-remove-tag-from-user-label":	"unsafe-confirm-remove-tag-from-user-label-unit-version",
    "confirm-remove-user-from-bag-label":	"confirm-remove-user-from-unit-label",
    "user-assignent-exist-label":	"user-assignent-exist-label-unit-version",
    "user-already-has-bag-label":	"user-already-has-unit-label",
    "unsafe-confirm-delete-tag-label":	"unsafe-confirm-delete-tag-label-unit-version",
    "assigned-bags-label":	"assigned-units-label",
    "place-to-cleaning-state-label":	"place-to-cleaning-state-label-unit-version",
    "clean-bag-question-label":	"clean-unit-question-label",
    "place-to-cleaning-state-user-delete-label":	"place-to-cleaning-state-user-delete-label-unit-version",
    "bag-cleaned-successfull-label":	"unit-cleaned-successfull-label",
    "cleaning-state-tooltip-label":	"cleaning-state-tooltip-label-unit-version",
    "tag-missing-state-tooltip-label":	"tag-missing-state-tooltip-label-unit-version",
    "cleaning-successfull-label":	"cleaning-successfull-label-unit-version",
    "clean-bags-label":	"clean-units-label",
    "place-to-cleaning-state-users-delete-label":	"place-to-cleaning-state-users-delete-label-unit-version",
    "assigned-state-tooltip-label":	"assigned-state-tooltip-label-unit-version",
    "free-state-tooltip-label":	"free-state-tooltip-label-unit-version",
    "add-user-to-bag-label":	"add-user-to-unit-label",
    "remove-users-from-bags-success-label":	"remove-users-from-units-success-label",
    "confirm-remove-users-from-bags-label":	"confirm-remove-users-from-units-label",
    "choose-bag-label":	"choose-unit-label",
    "too-many-bags-per-system":	"too-many-units-per-system",
    "unassign-bags-label":	"unassign-units-label"
}

function overrideIfNecessary(locaKey: string) {
    const configData = localStorage.getItem('configData');
    const isThirdPartyLockersEnabled = configData ? JSON.parse(configData)?.isThirdPartyLockersEnabled === true ?? false : false;

    if (isThirdPartyLockersEnabled) {
        const overrideLabel = thirdPartyLockerOverrides[locaKey];
        return overrideLabel ?? locaKey;
    }

    return locaKey;
}

export default {
    TranslateHelper,
    Translate,
    t,
    language: i18n.language,
    getDataByLanguage: i18n.getDataByLanguage,
    i18n,
    I18nextProvider,
}