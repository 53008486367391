import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import axios from "axios";
import { changeLanguageCode, Logout, openModal } from "../Helpers";
import DataTable from "react-data-table-component";
import CreateAppUser from "../modals/System/CreateAppUser";
import DeleteAppUser from "../modals/System/DeleteAppUser";
import EditAppUser from "../modals/System/EditAppUser";
import { toast } from "react-toastify";
import loca from "../utils/localization"

const columns = [
    {
        name: <div>{loca.TranslateHelper('lastname-label')}</div>,
        sortable: true,
        selector: (row) => row.lastName,
        cell: row => <div>{row.lastName}</div>,
    },
    {
        name: <div>{loca.TranslateHelper('firstname-label')}</div>,
        sortable: true,
        id: 'firstname',
        selector: (row) => row.firstName,
        cell: row => <div>{row.firstName}</div>,
    },
    {
        name: <div>{loca.TranslateHelper('email-label')}</div>,
        sortable: true,
        selector: (row, index) => row.email,
        cell: row => <div>{row.email}</div>,
    },
    {
        name: <div>{loca.TranslateHelper('user-id-label')}</div>,
        sortable: true,
        selector: (row, index) => row.externalId,
        cell: row => <div>{row.externalId}</div>,
    },
    {
        name: <div>{loca.TranslateHelper('user-type-label')}</div>,
        sortable: true,
        selector: (row, index) => row.role,
        cell: row => <div>{row.role}</div>,
    },
    {
        name: '',
        sortable: false,
        width: '80px',
        cell: row =>
        (
            <div>
                {row.id !== JSON.parse(localStorage.getItem('loginData')).id ? <button className="btn btn-action" onClick={() => openModal(<DeleteAppUser userId={row.id} userName={row.lastName + ' ' + row.firstName} />, { 'size': '600px' })}><i className="icon-delete" /></button> : ''}
                <button className="btn btn-action"
                    onClick={() => openModal(
                        <EditAppUser
                            userId={row.id}
                            user={row} />,
                        { 'size': '600px' }
                    )}>
                    <i className="icon-edit" />
                </button>
            </div>
        )
    },
];

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            department: '',
            jobtitle: '',
            departments: [],
            jobtitles: []
        };

        window.UserManagement = this;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        axios(process.env.REACT_APP_API_URL + 'AppUser/Manage', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ data: response.data })
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    render() {
        return (
            <div>
                <Header
                    page="users"
                    headerTitle={loca.TranslateHelper('side-settings')}
                    subtitle={loca.TranslateHelper('side-user-management')}
                    enableStat={false}
                    actionBtn={
                        <button className="btn btn-full float-right mt-4"
                            onClick={() => openModal(
                                <CreateAppUser />,
                                { 'size': '600px' })
                            }>
                            <i className="icon-plus" />
                            {loca.TranslateHelper('create-new-label')}
                        </button>}
                />

                <div className="mt-5">
                    <DataTable
                        id="users-table"
                        noHeader
                        pagination={false}
                        highlightOnHover
                        columns={columns}
                        data={this.state.data}
                    />
                </div>
            </div>
        )
    }
}

export default withTranslation()(UserManagement)
