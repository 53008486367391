import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap-grid.min.css';


import loca from "./utils/localization"
import {createStore} from "redux";
import { Provider } from 'react-redux';

import "./css/reset.scss";
import "./css/main.scss";

function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique =  arr.map(e => e[comp])
        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
}

const initialState = {
    customFields: {
        genders: [],
        departments: [],
        jobtitles: []
    },
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_GENDER':
            return {
                ...state, 
                customFields: {
                    ...state.customFields,
                    genders: getUnique(action.obj,'value')
                }
             }
        case 'ADD_DEPARTMENT':
            return {
                ...state, 
                customFields: {
                    ...state.customFields,
                    departments: getUnique(action.obj,'value')
                }
             }
        case 'ADD_JOBTITLE':
            return {
                ...state, 
                customFields: {
                    ...state.customFields,
                    jobTitles: getUnique(action.obj,'value')
                }
             }
        default:
            return state
    }
}

export const store = createStore(reducer)

ReactDOM.render(
    <loca.I18nextProvider i18n={loca.i18n}>
        <Provider store={store}>
            <App />
        </Provider>
        <ToastContainer />
    </loca.I18nextProvider>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();
