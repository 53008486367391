import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {NavLink} from 'react-router-dom';
import Support from "../modals/Support";
import {changeLanguageCode, Logout, openModal} from "../Helpers";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import {toast} from "react-toastify";
import loca from "../utils/localization"

class SideComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSystem: false,
            bagsPending: 0,
            usersPending: 0
        };

        window.Side = this;
        this.validator = new SimpleReactValidator();
    }

    changeMenu(){
        this.setState({'openSystem' : !this.state.openSystem})
        this.props.expandMenu()
    }

    bagsStatistics(){
        axios(process.env.REACT_APP_API_URL+'Assignment/statistics',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            this.setState({bagsPending: response.data.pending})

                        }
                    }else{
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }
    usersStatistics(){

        axios(process.env.REACT_APP_API_URL+'User/statistics',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            this.setState({usersPending: response.data.deletePending})
                        }
                    }else{
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    componentDidMount() {
        this.bagsStatistics();
        this.usersStatistics();
    }

    render() {
        return (
            <div className={`sidemenu ${this.props.showMenu ? "full" : "small"}`} onClick={()=>{window.Header.setState({showSearchResult:false})}}>
                <button className="expand" onClick={this.props.toggleMenu}><i className="icon-expand">&nbsp;</i></button>

                <a href="/" className="logo">
                    <img src={require("../images/zippsafe-logo.svg")} alt="Zippsafe"/>
                </a>

                <legend>{loca.t('side-overview')}</legend>
                <ul>
                    <li><NavLink to={'/locker-bags'} activeClassName="active" onClick={()=>this.setState({'openSystem' : false})}><i className="icon-bag">&nbsp;</i><span>{loca.t('side-lockerBags')} <span className={this.state.bagsPending ? 'badge badge-warning font-size-10 ml-2 text-black text-decoration-none' : 'd-none'}> <i className="fas fa-hourglass-end mr-1"></i>{this.state.bagsPending}</span></span></NavLink></li>
                    <li><NavLink to={'/users/'} activeClassName="active" onClick={()=>this.setState({'openSystem' : false})}><i className="icon-users">&nbsp;</i><span>{loca.t('side-users')}<span className={this.state.usersPending ? 'badge badge-warning badge-rounded font-size-10 ml-2 text-black text-decoration-none' : 'd-none'}>{this.state.usersPending}</span></span></NavLink></li>
                    <li><NavLink to={'/tags/'} activeClassName="active" onClick={()=>this.setState({'openSystem' : false})}><i className="icon-tag">&nbsp;</i><span>{loca.t('side-tags')}</span></NavLink></li>
                </ul>

                <legend>{loca.t('side-administration')}</legend>
                <ul>
                    {JSON.parse(localStorage.getItem('loginData')).role !== 'Officer' ?
                        <li>
                        <div className={window.location.pathname.includes("settings") ? 'cursor-pointer active' : 'cursor-pointer'} onClick={()=>{this.changeMenu()}}><i className="icon-settings">&nbsp;</i><span>{loca.t('side-settings')}</span></div>
                        {this.state.openSystem ||  window.location.pathname.includes("settings")? <ul className="mt-4">
                            <li><NavLink to={'/settings/custom-fields/'} className="submenu mt-1" activeClassName="active"><span>{loca.t('side-customFields')}</span></NavLink></li>
                            <li><NavLink to={'/settings/systems/'} className="submenu mt-1" activeClassName="active"><span>{loca.t('side-systems')}</span></NavLink></li>
                            <li><NavLink to={'/settings/user-management/'} className="submenu mt-1" activeClassName="active"><span>{loca.t('side-user-management')}</span></NavLink></li>
                            <li><NavLink to={'/settings/cleaning/'} className="submenu mt-1" activeClassName="active"><span>{loca.t('cleaning-label')}</span></NavLink></li>
                        </ul> : ''}
                    </li> : ''}
                    {JSON.parse(localStorage.getItem('amsData')).isEnabled ?
                    <li>
                        <NavLink to={'/synch/'} activeClassName="active" onClick={()=>this.setState({'openSystem' : false})}><i className="fas fa-sync-alt small">&nbsp;</i><span>{loca.t('side-data-synchronization')}</span></NavLink>
                    </li> : ''}
                    <li><NavLink to={'/floorplan/'} activeClassName="active" onClick={()=>this.setState({'openSystem' : false})}><i className="icon-page">&nbsp;</i><span>{loca.t('side-floorPlan')}</span></NavLink></li>
                    <li><div className="pointer" onClick={()=>openModal(<Support/>)}><i className="icon-support">&nbsp;</i><span>{loca.t('side-support')}</span></div></li>
                    <li><NavLink to={'/profile/'} activeClassName="active" onClick={()=>this.setState({'openSystem' : false})}><i className="icon-profile">&nbsp;</i><span>{loca.t('side-userProfile')}</span></NavLink></li>
                </ul>


                <div className="version">
                    <div className={'hasTooltip'}>
                        <div className={'ctooltip'}>
                            <div className={'tt'}>
                                <span>{JSON.parse(localStorage.getItem('versionData')).versionInfo}</span>
                            </div>
                        </div>
                        <span>{loca.t('side-version')}</span> {JSON.parse(localStorage.getItem('versionData')).versionNumber}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(SideComponent);
