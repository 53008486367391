import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { changeLanguageCode, Logout, RefreshView } from "../../Helpers";
import { toast } from "react-toastify";
import loca from "../../utils/localization"

class CleanBags extends Component {
    cleanBags = () => {
        axios(process.env.REACT_APP_API_URL + 'Assignment/removeCleaningStates', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
            data: this.props.bags.map(bag => bag.bagId)
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            toast.success(loca.TranslateHelper('cleaning-successfull-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            window.Modal.props.closeAction();
                            RefreshView(window.View.state.currentview);

                            window.BagsTable.setState({ filterState: '' });
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }


    render() {
        const { t } = this.props;
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt="" />
                <h2 className="main tc">{t('clean-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {t('clean-bag-question-label')}
                        <div className="form-group">
                            <button id="cleanBagBTN" className="btn btn-full mt-3" onClick={() => this.cleanBags()}>{t('yes-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CleanBags);
