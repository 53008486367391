import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import loca from "../../utils/localization";

class SynchResponse extends Component {

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    render() {
        const values = {
            successedSystemCount: this.props.selectedSystemCount - this.props.failedSychronizationCount,
            selectedSystemCount: this.props.selectedSystemCount,
            failedSychronizationCount: this.props.failedSychronizationCount,
            totalSystemCount: this.props.totalSystemCount
        }

        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('assignments-synchronization-label')}</h2>

                <div className="row mt-3">
                    <div className="col-12">

                        {this.props.failedSychronizationCount === 0 &&
                            <div className={'row p-4 alert alert-success'}>
                                <div className={'col-1'}>
                                    <i className={'icon-check'} />
                                </div>
                                <div className={'col-11'} dangerouslySetInnerHTML={{
                                    __html: loca.t('synch-stat-success-label', values)
                                }}>

                                </div>
                            </div>
                        }
                        {this.props.failedSychronizationCount !== 0 &&
                            <div className={'row p-4 alert alert-danger'}>
                                <div className={'col-1'}>
                                    <i className={'icon-cross'} />
                                </div>
                                <div className={'col-11'} dangerouslySetInnerHTML={{
                                    __html: loca.t('synch-stat-failed-label', values)
                                }}>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(SynchResponse);
