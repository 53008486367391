import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Select from 'react-select';
import axios from "axios";
import SelectColours from "../../css/SelectColours";
import { toast } from "react-toastify";
import { changeLanguageCode, Logout, UserTypesFromHelpers } from "../../Helpers";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

class EditAppUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: this.props.user.role === 'Admin' ? 2 : this.props.user.role === 'Officer' ? 0 : 1,
            firstname: this.props.user.firstName,
            lastname: this.props.user.lastName,
            email: this.props.user.email,
            externalId: this.props.user.externalId,
            password: '',
            password2: '',
        };

        this.EditAppUserValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onInputChange = (values, { action }) => {
        switch (action) {
            case 'select-option':
                let selectedValues = Object.keys(values).map((key) => {
                    return values[key].value
                });
                this.setState({ 'userType': selectedValues });
                return;
            default:
                return;
        }
    }

    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    saveUser() {
        if (this.EditAppUserValidator.allValid()) {
            document.getElementById("saveUserBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'AppUser/Manage/' + this.props.userId, {
                method: 'POST',
                data: {
                    "role": this.state.userType,
                    "firstName": this.state.firstname,
                    "lastName": this.state.lastname,
                    "email": this.state.email,
                    "externalId": this.state.externalId,
                    "password": this.state.password,
                    "password2": this.state.password2
                },
                headers: {
                    'Access-Control-Allow-Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {
                                if (window.UserManagement) {
                                    window.UserManagement.getUsers();
                                }

                                toast.success(loca.TranslateHelper('save-user-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                window.Modal.props.closeAction();
                                document.getElementById("saveUserBTN").classList.remove("loading");
                            }
                        } else {
                            toast.error('There was an error!', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            document.getElementById("saveUserBTN").classList.remove("loading");
                        }
                    }
                )
                .catch(error => {
                    document.getElementById("saveUserBTN").classList.remove("loading");
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        } else {
            document.getElementById("saveUserBTN").classList.remove("loading");
            this.EditAppUserValidator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('edit-user-label')}</h2>
                <div className="row mt-4">
                    <div className="col col-md-12">
                        <form>
                            <div className="row">
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <Select
                                                name="userType"
                                                id="userType"
                                                isDisabled={true}
                                                styles={SelectColours}
                                                className="SelectContainer"
                                                onChange={this.handleChange.bind(this, 'userType')}
                                                // eslint-disable-next-line
                                                value={UserTypesFromHelpers.filter(option => option.value === this.state.userType)}
                                                isOptionDisabled={
                                                    // eslint-disable-next-line
                                                    (option) => JSON.parse(localStorage.getItem('loginData')).role === 'LocalAdmin' ? option.value === 2 : null
                                                }
                                                placeholder={loca.TranslateHelper('choose-user-type-label')}
                                                options={UserTypesFromHelpers} components={{}} />
                                            <label htmlFor="usertype">{loca.TranslateHelper('user-type-label')} <em>*</em></label>
                                        </div>
                                        {this.EditAppUserValidator.message('userType', this.state.userType, 'required')}
                                    </div>
                                </div>

                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="lastname" id="lastname" placeholder={loca.TranslateHelper('lastname-label')} onChange={this.onChange} value={this.state.lastname || ''} />
                                            <label htmlFor="lastname">{loca.TranslateHelper('lastname-label')} <em>*</em></label>
                                        </div>
                                        {this.EditAppUserValidator.message('lastname', this.state.lastname, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="firstname" id="firstname" placeholder={loca.TranslateHelper('firstname-label')} onChange={this.onChange} value={this.state.firstname || ''} />
                                            <label htmlFor="firstname">{loca.TranslateHelper('firstname-label')} <em>*</em></label>
                                        </div>
                                        {this.EditAppUserValidator.message('firstname', this.state.firstname, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="email" id="email" autoComplete="email" placeholder={loca.TranslateHelper('email-label')} onChange={this.onChange} value={this.state.email || ''} />
                                            <label htmlFor="email">{loca.TranslateHelper('email-label')} <em>*</em></label>
                                        </div>
                                        {this.EditAppUserValidator.message('email', this.state.email, 'required|email')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" name="externalId" id="externalId" placeholder={loca.TranslateHelper('user-id-label')} onChange={this.onChange} value={this.state.externalId || ''} />
                                            <label htmlFor="userId">{loca.TranslateHelper('user-id-label')} <em>*</em></label>
                                        </div>
                                        {this.EditAppUserValidator.message('externalId', this.state.externalId, 'required')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="password" name="password" id="password" autoComplete={'current-password'} placeholder={loca.TranslateHelper('new-password-label')} onChange={this.onChange} />
                                            <label htmlFor="password">{loca.TranslateHelper('new-password-label')}</label>
                                        </div>
                                        {this.EditAppUserValidator.message('password', this.state.password, 'min:8,string')}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="password" name="password2" id="password2" autoComplete={'current-password'} placeholder={loca.TranslateHelper('password-confirm-label')} onChange={this.onChange} />
                                            <label htmlFor="password2">{loca.TranslateHelper('password-confirm-label')}</label>
                                        </div>
                                        {this.EditAppUserValidator.message('confirm-password', this.state.password2, this.state.password ? `required|in:${this.state.password}` : `in:${this.state.password}`)}
                                    </div>
                                </div>
                                <div className="col col-md-12">
                                    <div className="form-group">
                                        <button type="button" id="saveUserBTN" className="btn btn-full" onClick={() => this.saveUser()}>{loca.TranslateHelper('save-user-label')}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EditAppUser);
