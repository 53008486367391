import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import { changeLanguageCode, getConfig, Logout } from "../Helpers";
import axios from "axios";
import { toast } from "react-toastify";
import loca from "../utils/localization"

class Synch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auto: false,
            deletionPending: JSON.parse(localStorage.getItem('configData')).isPendingUserDeletionDisabled,
            loaded: false,
            token: '',
        };

        this.getToken();

        this.execute = this.execute.bind(this);
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        window.Synch = this;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggle = (e) => {
        this.setState({ 'auto': !this.state.auto });
        this.state.auto === true ? this.stop() : this.start()
    }
    toggleDeletionState = (e) => {
        const newValue = !this.state.deletionPending;
        this.setState({ 'deletionPending': newValue });
        this.setPendingDeletion(newValue)
    }

    getToken() {
        axios(JSON.parse(localStorage.getItem('amsData')).url + '/ApiClient/GetToken', {
            method: 'POST',
            data: {
                'userName': JSON.parse(localStorage.getItem('amsData')).userName,
                'password': JSON.parse(localStorage.getItem('amsData')).password
            },
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        this.setState({ 'token': response.data })
                        this.getAMS();
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    setPendingDeletion(newValue) {
        axios(process.env.REACT_APP_API_URL + 'Tenant/configuration/setUserPendingDeletionState?enable=' + newValue, {
            method: 'POST',
            data: {
                'enable': newValue
            },
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        getConfig();

                        toast.success(loca.TranslateHelper('successfully-updated-label'), {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    execute() {
        axios(JSON.parse(localStorage.getItem('amsData')).url + '/AmsIntegration/Execute/' + JSON.parse(localStorage.getItem('loginData')).tenantId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Authorization': `Bearer ${this.state.token}`,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            toast.success(loca.TranslateHelper('immediate-synch-success-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });

                        Logout();
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    start() {
        axios(JSON.parse(localStorage.getItem('amsData')).url + '/AmsIntegration/Start/' + JSON.parse(localStorage.getItem('loginData')).tenantId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Authorization': `Bearer ${this.state.token}`,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            toast.success(loca.TranslateHelper('auto-synch-started-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });

                        Logout();
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    stop() {
        axios(JSON.parse(localStorage.getItem('amsData')).url + '/AmsIntegration/Stop/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Authorization': `Bearer ${this.state.token}`,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            toast.success(loca.TranslateHelper('auto-synch-stopped-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });

                        Logout();
                    }
                }
            )
            .catch(error => {
            });
    }

    getAMS() {
        axios(JSON.parse(localStorage.getItem('amsData')).url + '/AmsIntegration/IsRunning/' + JSON.parse(localStorage.getItem('loginData')).tenantId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Authorization': `Bearer ${this.state.token}`,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ auto: response.data })
                            this.setState({ loaded: true })
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    render() {
        return (
            <div>
                <Header
                    headerTitle={loca.TranslateHelper('side-data-synchronization')}
                    subtitle={loca.TranslateHelper('data-synchronization-text')}
                    enableStat={false}
                />

                {this.state.loaded &&
                    <div className="container-div mt-5">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                {loca.TranslateHelper('automated-synchronization')}
                                <div className="switch mt-3">
                                    <input type="checkbox" id={'auto'} name={'auto'} onChange={this.toggle} disabled={JSON.parse(localStorage.getItem('loginData')).role === 'Admin' ? '' : 'disabled'} checked={this.state.auto ? 'checked' : ''} />
                                    <div className="switcher">
                                        <label htmlFor="auto" className="toggler" />
                                        <div className={'onlabel'}>{loca.TranslateHelper('on-label')}</div>
                                        <div className={'offlabel'}>{loca.TranslateHelper('off-label')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pt-5 pt-md-0">
                                {loca.TranslateHelper('immediate-synchronization')}
                                <button className="btn btn-full d-block mt-3" onClick={this.execute}>{loca.TranslateHelper('run-label')}</button>
                            </div>
                            <div className="col-12 col-md-6 pt-5">
                                {loca.TranslateHelper('disable-deletion-pending-label')}
                                <div className="switch mt-3">
                                    <input type="checkbox" id={'deletionPending'} name={'deletionPending'} onChange={this.toggleDeletionState} disabled={JSON.parse(localStorage.getItem('loginData')).role === 'Admin' ? '' : 'disabled'} checked={this.state.deletionPending ? 'checked' : ''} />
                                    <div className="switcher">
                                        <label htmlFor="deletionPending" className="toggler" />
                                        <div className={'onlabel'}>{loca.TranslateHelper('on-label')}</div>
                                        <div className={'offlabel'}>{loca.TranslateHelper('off-label')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}

export default withTranslation()(Synch)

