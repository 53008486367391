// TODO abalint This condition should be reconsidered
/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {
    openModal,
    Logout,
    changeLanguageCode
} from "../Helpers";
import EditUser from "../modals/Users/EditUser";
import DeleteUser from "../modals/Users/DeleteUser";
import { toast } from "react-toastify";
import Select from "react-select";
import SelectColours from "../css/SelectColours";
import loca from "../utils/localization"
import ApproveAllDeletions from "../modals/Users/ApproveAllDeletions";
import ApproveDeletion from "../modals/Users/ApproveDeletion";
import qs from 'qs';
import handleViewport from 'react-in-viewport';
import ShowMore from "../modules/showMore";
import DeleteUsers from "../modals/Users/DeleteUsers";
import { ExportContent } from '../atoms/ExportContent';
import { getFilename } from '../utils/axios';
import { ColumnVisibilityButton } from '../atoms/ColumnVisibilityButton';


require('intersection-observer');

const Block = (props) => {
    const { forwardedRef, enablePaging } = props;
    return (
        <div className="viewport-block" ref={forwardedRef}>
            {enablePaging && <ShowMore />}
        </div>
    );
};

const ViewportBlock = handleViewport(Block, /** options: {}, config: {} **/);

const FilterComponent = ({ onFilter, onClear, handleChange, state }) => (
    <div className="col-md-10 filters">
        <div className="row">
            <div className="col col-md-2 pr-0">
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            isSearchable
                            isClearable
                            name="gender"
                            id="gender"
                            className="SelectContainer"
                            styles={SelectColours}
                            placeholder={loca.TranslateHelper('choose-gender-label')}

                            onChange={
                                e => { handleChange('filterGender', e); setTimeout(function () { onFilter(e) }, 100) }
                            }
                            value={state.filterItems.genderIds ? state.filterItems.genderIds.filter(option => option.value === state.filterGender) : ''}
                            options={state.filterItems.genderIds} />

                        <label htmlFor="gender">{loca.TranslateHelper('gender-label')}</label>
                    </div>
                </div>
            </div>
            <div className="col col-md-2 pr-0">
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            isSearchable
                            isClearable
                            name="department"
                            id="department"
                            className="SelectContainer"
                            styles={SelectColours}
                            placeholder={loca.TranslateHelper('choose-department-label')}

                            onChange={
                                e => { handleChange('filterDepartment', e); setTimeout(function () { onFilter(e) }, 100) }
                            }
                            value={state.filterItems.departmentIds ? state.filterItems.departmentIds.filter(option => option.value === state.filterDepartment) : ''}
                            options={state.filterItems.departmentIds} />

                        <label htmlFor="department">{loca.TranslateHelper('department-label')}</label>
                    </div>
                </div>
            </div>
            <div className="col col-md-2 pr-0">
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            isSearchable
                            isClearable
                            name="jobtitle"
                            id="jobtitle"
                            className="SelectContainer"
                            styles={SelectColours}
                            placeholder={loca.TranslateHelper('choose-jobtitle-label')}

                            onChange={
                                e => { handleChange('filterJobTitle', e); setTimeout(function () { onFilter(e) }, 100) }
                            }
                            value={state.filterItems.jobTitleIds ? state.filterItems.jobTitleIds.filter(option => option.value === state.filterJobTitle) : ''}
                            options={state.filterItems.jobTitleIds} />
                        <label htmlFor="jobtitle">{loca.TranslateHelper('jobtitle-label')}</label>
                    </div>
                </div>
            </div>
            <div className="col col-md-2 pr-0">
                <div className="form-group">
                    <div className="input-group">
                        <Select
                            isSearchable
                            isClearable
                            name="hasAssignment"
                            id="hasAssignment"
                            className="SelectContainer"
                            styles={SelectColours}

                            onChange={
                                e => { handleChange('filterTagType', e); setTimeout(function () { onFilter(e) }, 100) }
                            }
                            value={state.filterItems.states ? state.filterItems.states.filter(option => option.value === state.filterTagType) : ''}
                            options={state.filterItems.states} />
                        <label htmlFor="state">{loca.TranslateHelper('has-assignment-label')}</label>
                    </div>
                </div>
            </div>
            <div className="col col-md-auto pr-0">
                <div className="form-group">
                    <button className="btn btn-simple mt-3" onClick={e => { onClear(e); setTimeout(function () { onFilter(e) }, 100) }}><i className="icon-cross">&nbsp;</i>{loca.TranslateHelper('clear-filter-label')}</button>
                </div>
            </div>
        </div>
    </div>
);

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            resetPaginationToggle: false,
            filterGender: "00000000-0000-0000-0000-000000000000",
            filterDepartment: "00000000-0000-0000-0000-000000000000",
            filterJobTitle: "00000000-0000-0000-0000-000000000000",
            filterTagType: "",
            dataLoading: true,
            sorting: "",
            sortDirection: "asc",
            page: 1,
            pageSize: 20,
            maxPage: null,
            enablePaging: true,
            filterItems: {
                genderIds: [],
                departmentIds: [],
                jobTitleIds: [],
                states: []
            },
            omit: localStorage.getItem('usersTableColumns') ? JSON.parse(localStorage.getItem('usersTableColumns')) : {
                "job-title": false,
                department: false,
                gender: false,
                comments: false,
                tags: false,
                email: false
            },
            selectedUsers: new Set(),
        }

        window.UsersTable = this;
    }

    handleChange = (stateId, e) => {
        if (stateId === 'filterGender') {
            this.setState({ genderText: e.label });
        }
        if (stateId === 'filterDepartment') {
            this.setState({ departmentText: e.label });
        }
        if (stateId === 'filterJobTitle') {
            this.setState({ jobTitleText: e.label });
        }
        this.setState({ [stateId]: e.value });
    }

    handleClear = () => {
        if (this.state.sorting !== "") {
            window.location.reload();
        }

        const { resetPaginationToggle } = this.state;

        this.setState({
            resetPaginationToggle: !resetPaginationToggle,
            filterGender: "",
            filterDepartment: "",
            filterJobTitle: "",
            filterTagType: ""
        });

        this.resetState();
    };

    updateFilter = () => {
        this.setState({ dataLoading: true })

        this.updateStatistics();

        this.refreshFilter(true);
    }

    refreshFilter(resetPaging) {
        if (resetPaging) {
            this.resetState();
        }

        axios(process.env.REACT_APP_API_URL + 'Filter/userOptions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            var genderIdsFilter = Object.keys(response.data.genderIds).map(function (key, index) {
                                return { value: key, label: response.data.genderIds[key] };
                            });

                            var jobTitleIdsFilter = Object.keys(response.data.jobTitleIds).map(function (key, index) {
                                return { value: key, label: response.data.jobTitleIds[key] };
                            });

                            var departmentIdsFilter = Object.keys(response.data.departmentIds).map(function (key, index) {
                                return { value: key, label: response.data.departmentIds[key] };
                            });

                            var statesFilter = Object.keys(response.data.userStatuses).map(function (key, index) {
                                return { value: key, label: response.data.userStatuses[key] };
                            });

                            genderIdsFilter.unshift({ key: "00000000-0000-0000-0000-000000000000", label: loca.TranslateHelper('all-label') });
                            departmentIdsFilter.unshift({ key: "00000000-0000-0000-0000-000000000000", label: loca.TranslateHelper('all-label') });
                            jobTitleIdsFilter.unshift({ key: "00000000-0000-0000-0000-000000000000", label: loca.TranslateHelper('all-label') });
                            statesFilter.unshift({ key: "", label: loca.TranslateHelper('all-label') });

                            this.setState({
                                filterItems: {
                                    genderIds: genderIdsFilter,
                                    departmentIds: departmentIdsFilter,
                                    jobTitleIds: jobTitleIdsFilter,
                                    states: statesFilter,
                                }
                            });
                        }
                    } else {
                        toast.error('error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    updateStatistics = () => {
        axios(process.env.REACT_APP_API_URL + 'User/statistics', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            params: {
                GenderId: this.state.filterGender,
                DepartmentId: this.state.filterDepartment,
                State: this.state.filterState
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            window.Header.setState({ stat_value1: response.data.unassigned })
                            window.Header.setState({ stat_value2: response.data.assigned })
                            window.Header.setState({ stat_value3: '' })
                            window.Header.setState({ stat_value4: '' })
                            window.Header.setState({ stat_valueTotal: response.data.totalCount })
                            window.Header.setState({ stat_show: true })

                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    exportData = (format) => {

        const data = {
            GenderId: this.state.filterGender,
            DepartmentId: this.state.filterDepartment,
            JobTitleId: this.state.filterJobTitle,
            UserStatus: this.state.filterTagType,
            ExportFormat: format,
        };

        axios(process.env.REACT_APP_API_URL + 'User/export?' + qs.stringify(data), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            responseType: 'arraybuffer', // default is json
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            var byte = response.data;

                            var blob = new Blob([byte], { type: response.headers["content-type"] });
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);

                            link.download = getFilename(response);
                            link.click();
                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    toggleHideColumn = () => {
        this.setState({ showColumnHide: !this.state.showColumnHide });
    }

    saveHideColumns = (col) => {
        this.setState(prevState => ({
            omit: {
                ...prevState.omit,
                [col]: !this.state.omit[col]
            }
        }), () => setImmediate(() => {
            window.localStorage.setItem("usersTableColumns", JSON.stringify(this.state.omit));
        }));
    }

    getSubHeaderComponent = () => {
        const selectionDetails = this.getSelectionDetails();

        return (
            <div className={'row w-100'}>
                <div className={'col-12'}>
                    <div className="row w-100 my-3">
                        <FilterComponent
                            onFilter={this.updateFilter}
                            onClear={this.handleClear}
                            onInputChange={this.handleChange}
                            handleChange={this.handleChange}
                            state={this.state}
                        />

                        <div className="col-action">
                            <ExportContent exportWithFormat={this.exportData} />
                            <button className="float-right" onClick={window.Header.showHeader}><i className="icon-show-header">&nbsp;</i></button>
                            <button className="float-right" onClick={window.Header.hideHeader}><i className="icon-hide-header">&nbsp;</i></button>
                            <ColumnVisibilityButton
                                columns={["job-title", "gender", "department", "email", "comments"]}
                                isVisible={this.state.showColumnHide}
                                omit={this.state.omit}
                                saveHideColumns={this.saveHideColumns}
                                toggleHideVisibility={this.toggleHideColumn} />
                            <div className="clear">&nbsp;</div>
                        </div>
                    </div>
                </div>
                {selectionDetails && (<div className={'col-12 pb-3'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <button className="btn btn-full"
                                disabled={this.state.selectedUsers.size === 0}
                                onClick={selectionDetails.onClick}>
                                <i className={selectionDetails.iconClassName} />{" "}
                                <loca.Translate locaKey={selectionDetails.label} values={{ count: this.state.selectedUsers.size }} />
                            </button>
                        </div>
                    </div>
                </div>)}
            </div>
        );
    };

    getStatistics() {
        window.Header.setState({ stat_show: false })
        window.Header.setState({ stat_value1: '' })
        window.Header.setState({ stat_value2: '' })
        window.Header.setState({ stat_value3: '' })
        window.Header.setState({ stat_value4: '' })
        window.Header.setState({ stat_valueTotal: '' })

        axios(process.env.REACT_APP_API_URL + 'User/statistics', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            window.Header.setState({ stat_value1: response.data.unassigned })
                            window.Header.setState({ stat_value2: response.data.assigned })
                            window.Header.setState({ stat_value3: '' })
                            window.Header.setState({ stat_value4: response.data.deletePending })
                            window.Side.setState({ usersPending: response.data.deletePending })
                            window.Header.setState({ stat_valueTotal: response.data.totalCount })
                            window.Header.setState({ stat_show: true })

                        }
                    } else {
                        toast.error('Error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    appendUsers(resetPaging) {
        if (this.state.enablePaging || resetPaging) {
            axios(process.env.REACT_APP_API_URL + 'User/paged', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                params: {
                    Page: this.state.page,
                    PageSize: this.state.pageSize,
                    OrderBy: this.state.sorting,
                    DescedingOrder: this.state.sortDirection === 'asc' ? false : true,
                    GenderId: this.state.filterGender,
                    DepartmentId: this.state.filterDepartment,
                    JobTitleId: this.state.filterJobTitle,
                    UserStatus: this.state.filterTagType,
                },
                withCredentials: true,
                credentials: 'same-origin',
            })
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data === 'User not logged in.') {
                                Logout()
                            } else {

                                this.setState({ data: [...this.state.data, ...response.data.data] });
                                this.filteredItems = [...this.state.data, ...response.data.data];

                                if (response.data.pageInfo.page < Math.ceil(response.data.totalRecords / response.data.pageInfo.pageSize)) {
                                    this.setState({ page: response.data.pageInfo.page + 1 });
                                    this.setState({ enablePaging: true });
                                } else {
                                    this.setState({ enablePaging: false });
                                }

                                this.getStatistics();

                                this.refreshFilter(resetPaging);
                                this.setState({ dataLoading: false })
                            }
                        } else {
                            toast.error('There was an error!', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        }
    }

    approveDeletion = () => {
        const users = [...this.state.selectedUsers];
        openModal(<ApproveAllDeletions users={users} />, { 'size': '600px' });
    }

    openUserDeleteModal = () => {
        const users = [...this.state.selectedUsers];
        openModal(<DeleteUsers users={users} />, { 'size': '600px' });
    }

    resetState() {
        this.setState({
            page: 1,
            enablePaging: true,
            data: [],
            selectedUsers: new Set(),
        });
    }

    loadMore() {
        this.appendUsers(false)
    }


    sortDataTable(column, direction, event) {
        const self = this;

        self.setState({
            sorting: column.id,
            sortDirection: direction,
            page: 1,
            enablePaging: false,
        })

        self.appendUsers(true);
    }

    handleGlobalSelectorClicked = (event) => {
        if (event.target.checked) {
            // Check all currently loadeds
            this.setState(state => ({
                ...state,
                selectedUsers: new Set(state.data.map(d => d.userId)),
            }));
        }
        else {
            // Uncheck all
            this.setState(state => ({
                ...state,
                selectedUsers: new Set(),
            }));
        }
    }

    getSelectionDetails = () => {
        const isAmsEnabled = JSON.parse(localStorage.getItem('amsData')).isEnabled;
        // Justification: Sometimes it is "3" unfortunately TODO abalint Fix this
        // eslint-disable-next-line eqeqeq
        const isDeletionPending = this.state.filterTagType == 3;

        if (!isAmsEnabled)
            return {
                label: "delete-users-label",
                iconClassName: "icon-delete",
                onClick: this.openUserDeleteModal
            };

        if (isDeletionPending)
            return {
                label: 'delete-users-label',
                iconClassName: "icon-delete",
                onClick: this.approveDeletion,
            };

        return undefined; // No action, do not allow selection
    }

    render = () => {
        const selectionDetails = this.getSelectionDetails();

        const columns = [
            {
                name: selectionDetails
                    ? <div className="ctooltip">
                        <div>
                            <input type="checkbox" id={'cb_all'} onChange={this.handleGlobalSelectorClicked} />
                            <label htmlFor={'cb_all'} className={'selector'} />
                        </div>
                    </div>
                    : null,
                sortable: false,
                width: '70px',
                export: false,
                cell: row => {
                    const isSelected = this.state.selectedUsers.has(row.userId);
                    const toggle = () => {
                        this.setState(state => {
                            const newSelecteds = new Set(state.selectedUsers);
                            if (isSelected)
                                newSelecteds.delete(row.userId);
                            else
                                newSelecteds.add(row.userId);

                            return {
                                ...state,
                                selectedUsers: newSelecteds,
                            }
                        })
                    }

                    return selectionDetails
                        ? <div>
                            <div className={'userSelector'}>
                                <input type="checkbox" id={'cb_' + row.userId} checked={isSelected} onChange={toggle} />
                                <label htmlFor={'cb_' + row.userId} />
                            </div>
                        </div>
                        : null
                }
            },
            {
                name: loca.TranslateHelper('name-label'),
                id: 'name',
                width: this.state.fixedColumnWidth ? '25%' : null,
                minWidth: '25%',
                sortable: true,
                omit: false,
                selector: (row) => row.lastName.toUpperCase() + ' ' + row.firstName.toUpperCase() + ' ' + (row.externalId ? '(' + row.externalId + ')' : ''),
                cell: row => <div>{row.lastName} {row.firstName} {row.externalId ? '(' + row.externalId + ')' : ''} </div>
            },
            {
                name: loca.TranslateHelper('job-title-label'),
                sortable: true,
                id: 'job title',
                width: this.state.fixedColumnWidth ? '7%' : null,
                omit: this.state.omit['job-title'],
                selector: (row) => row.jobTitleName,
                cell: row => <div title={row.jobTitleName}>{row.jobTitleName}</div>,
            },
            {
                name: loca.TranslateHelper('gender-label'),
                sortable: true,
                id: 'gender',
                selector: (row) => row.gender,
                omit: this.state.omit.gender,
                width: this.state.fixedColumnWidth ? '7%' : null,
                cell: row => <div title={row.genderName}>{row.genderName}</div>,
            },
            {
                name: loca.TranslateHelper('department-label'),
                sortable: true,
                id: 'department',
                selector: (row) => row.departmentName,
                omit: this.state.omit.department,
                width: this.state.fixedColumnWidth ? '7%' : null,
                cell: row => <div title={row.departmentName}>{row.departmentName}</div>,
            },
            {
                name: loca.TranslateHelper('email-label'),
                sortable: true,
                width: this.state.fixedColumnWidth ? '10%' : null,
                omit: this.state.omit.email,
                selector: (row) => row.emailAddress,
                cell: row => <div title={row.emailAddress}>{row.emailAddress}</div>,
            },
            {
                name: loca.TranslateHelper('assigned-tags-label'),
                selector: row => row.tags,
                width: this.state.fixedColumnWidth ? '14%' : null,
                sortable: true,
                omit: this.state.omit.tags,
                cell: row =>
                    Object.keys(row.tags).map(key =>
                        <span key={key}>
                            {row.tags[key].tagName} ({row.tags[key].externalId})<br />
                        </span>
                    )
            },
            {
                name: loca.TranslateHelper('comments-label'),
                selector: (row) => row.comment,
                sortable: true,
                id: 'comments',
                omit: this.state.omit.comments,
                cell: row => {
                    if (row.comment) {
                        return <div title={row.comment}>
                            <i className="fa fa-comment-alt" /> {row.comment}
                        </div>
                    } else {
                        return ''
                    }
                }
                ,
            },
            {
                name: '',
                sortable: false,
                width: '10%',
                export: false,
                cell: row =>
                    <div>
                        <button className="btn btn-action"
                            disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled
                                && !row.deletionPending
                                || (row.deletionPending && JSON.parse(localStorage.getItem('configData')).isPendingUserDeletionDisabled) ? 'disabled' : ''}
                            onClick={() => openModal(row.deletionPending ? <ApproveDeletion userId={row.userId} hasAssignment={row.hasAssignment} userName={row.lastName + ' ' + row.firstName} /> : <DeleteUser userId={row.userId} hasAssignment={row.hasAssignment} userName={row.lastName + ' ' + row.firstName} />, { 'size': '600px' })}>
                            <i className="icon-delete" />
                            {row.deletionPending && !JSON.parse(localStorage.getItem('configData')).isPendingUserDeletionDisabled && <span className="badge position-absolute badge-pending">{loca.TranslateHelper('pending-label')}</span>}
                        </button>
                        <button className="btn btn-action" onClick={() => openModal(<EditUser userId={row.userId} user={row} />, { 'size': '1100px' })}><i className="icon-edit" /></button>
                    </div>,
            },
        ];
        const data = this.state.data;

        return (
            <div onClick={() => { window.Header.setState({ showSearchResult: false }) }}>
                <DataTable
                    noHeader
                    className="datatable"
                    progressPending={this.state.dataLoading}
                    progressComponent={<div><h2 className="main my-5">{loca.TranslateHelper('loading-label')}</h2></div>}
                    pagination={false}
                    paginationPerPage={this.state.pageSize}
                    paginationRowsPerPageOptions={[15, 30, 50]}
                    paginationResetDefaultPage={this.state.resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    onSort={(column, sortDirection, event) => this.sortDataTable(column, sortDirection, event)}
                    sortServer={true}
                    subHeader
                    subHeaderComponent={this.getSubHeaderComponent()}
                    highlightOnHover
                    columns={columns}
                    data={data}
                />
                {this.state.enablePaging && <ViewportBlock enablePaging={this.state.enablePaging} onEnterViewport={() => this.loadMore()} />}
            </div>

        )
    }

}

export default (UsersTable);
