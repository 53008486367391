import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from './translations/en.json';
import translationDE from './translations/de.json';
import translationFR from './translations/fr.json';

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: translationEN
        },
        de: {
            translations: translationDE
        },
        fr: {
            translations: translationFR
        }
    },
    fallbackLng: 'en',
    defaultLanguage: 'en',
    browserLanguageDetection: false,
    serverLanguageDetection: false,
    debug: false,
    supportedLngs: ["en", "de", "fr"],

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;