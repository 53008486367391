import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {changeLanguageCode, Logout, RefreshView} from "../../Helpers";
import {toast} from "react-toastify";
import loca from "../../utils/localization"

class CleanBag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bags:[],
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId,e){
        this.setState({[stateId] : e.value})
    }

    setBagInfo(){
        const bags = []
        if(this.props.bag.length >=1){
            Object.keys(this.props.bag).map(key =>
                bags.push(this.props.bag[key].bagId)
            )
        }else{
            bags.push(this.props.bag.bagId)
        }

        this.setState({bags:bags});
    }

    componentDidMount() {
        this.setBagInfo();
    }

    doCleanBag(){
        const {t} = this.props
        document.getElementById("cleanBagBTN").classList.add("loading");

        axios(process.env.REACT_APP_API_URL+'Assignment/removeCleaningStates',{
            method: 'PATCH',
            data:this.state.bags,
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in'){
                            Logout()
                        }else{
                            window.Modal.props.closeAction();
                            RefreshView(window.View.state.currentview);
                            toast.success(t('bag-cleaned-successfull-label',{cleaned: response.data}), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }else{
                        toast.error(response.data, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        document.getElementById("cleanBagBTN").classList.remove("loading");
                    }
                }
            )
            .catch(error => {
                toast.error(error.response.data, {
                    position: toast.POSITION.TOP_CENTER,
                });

                document.getElementById("cleanBagBTN").classList.remove("loading");
            });
            document.getElementById("cleanBagBTN").classList.remove("loading");
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt=""/>
                <h2 className="main tc">{t('clean-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {t('clean-bag-question-label')}
                        <div className="form-group">
                            <button id="cleanBagBTN" className="btn btn-full mt-3" onClick={() => this.doCleanBag()}>{t('yes-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CleanBag);
