import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../css/main.scss";
import Header from "../modules/Header";
import {changeLanguageCode, Logout} from "../Helpers";
import axios from "axios";
import {toast} from "react-toastify";
import loca from "../utils/localization"

class Cleaning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cleaning : JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled,
            user: JSON.parse(localStorage.getItem('loginData'))
        };
    }

    toggle = (e) => {
        this.setState({'cleaning': !this.state.cleaning});
        this.changeCleaning();
    }

    getConfig(){
        axios(process.env.REACT_APP_API_URL+'Tenant/configuration/'+JSON.parse(localStorage.getItem('loginData')).tenantId,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            window.localStorage.setItem("configData", JSON.stringify(response.data));
                        }
                    }else{
                        toast.error('error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    changeCleaning(){
        axios(process.env.REACT_APP_API_URL+'Tenant/configuration/setCleaningState',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if(response.status === 200){
                        if(response.data === 'User not logged in.'){
                            Logout();
                        }else{
                            this.setState({'cleaning':response.data})
                            this.getConfig()
                        }
                    }else{
                        toast.error('error', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }

    render() {
        return (
            <div>
                <Header
                    headerTitle={loca.TranslateHelper('cleaning-label')}
                    subtitle={false}
                    enableStat={false}
                />

                <div className="container-div">
                    <div className="row">
                        <div className={'col-12'}>
                            <div className="switch mt-3">
                                <input type="checkbox" id={'cleaning'} name={'cleaning'} onChange={this.toggle} checked={this.state.cleaning ? 'checked' : ''} />
                                <div className="switcher">
                                    <label htmlFor="cleaning" className="toggler"/>
                                    <div className={'onlabel'}>{loca.TranslateHelper('on-label')}</div>
                                    <div className={'offlabel'}>{loca.TranslateHelper('off-label')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Cleaning)
