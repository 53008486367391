import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { changeLanguageCode, Logout, openModal, RefreshView } from "../../Helpers";
import loca from "../../utils/localization"
import DeletionResponse from "../Bags/DeletionResponse";

class ApproveAllDeletion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activateCleaningState: JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled ? true : false,
        }
    }

    approveAllDeletion = () => {
        axios(process.env.REACT_APP_API_URL + 'User/deleteMultipleUsers', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
            credentials: 'same-origin',
            data: {
                activateCleaningState: this.state.activateCleaningState,
                userIds: this.props.users
            }
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            toast.success(loca.TranslateHelper('users-delete-successfull-label'), {
                                position: toast.POSITION.TOP_CENTER,
                            });

                            RefreshView(window.View.state.currentview);

                            openModal(<DeletionResponse deleteSucceeded={response.data.deleteSucceeded} deleteFailed={response.data.deleteFailed} />, { 'size': '600px' });
                        }
                    } else {
                        toast.error('There was an error!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                console.log(error);
            });
    }

    changeCleaningState = (e) => {
        this.setState({ activateCleaningState: !this.state.activateCleaningState });
    }

    render() {
        return (
            <div>
                <img className="modal-icon" src={require("../../images/svg/error.svg")} alt="" />
                <h2 className="main tc">{loca.TranslateHelper('approve-deletion-users-label')}</h2>

                <div className="row mt-3">
                    <div className="col-md-12 tc">
                        {loca.t('confirm-delete-users-label', { user: this.props.userName })}
                        {JSON.parse(localStorage.getItem('configData')).isCleaningStateEnabled && <div className="checkbox-container mt-4 mb-2">
                            <input type="checkbox" id={'activateCleaningState'} name="activateCleaningState" className="inp-cbx" checked={this.state.activateCleaningState === true ? 'checked' : ''} onChange={this.changeCleaningState} />
                            <label className="cbx" htmlFor={'activateCleaningState'}>
                                <span><svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1" /></svg></span>
                                <span className={'text-left'}>{loca.TranslateHelper('place-to-cleaning-state-users-delete-label')}</span>
                            </label>
                        </div>}
                        <div className="form-group">
                            <button id="deleteBTN" className="btn btn-full mt-3" onClick={() => this.approveAllDeletion()}>{loca.TranslateHelper('delete-users-label', { count: this.props.users.length })}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ApproveAllDeletion);
