import React from 'react';
import Desktop from "../pages/Desktop";
import Login from "../pages/Login";

function App() {
    return (
        localStorage.getItem('loginData') ?
            <div className="App">
                <Desktop />
            </div> :
            <div className="Login">
                <Login />
            </div>
    );
}

export default App;
