import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  changeLanguageCode,
  getBagsTooltip,
  Logout,
  openModal,
  getBagStatusLabel,
} from "../Helpers";
import UserToBag from "../modals/Bags/UserToBag";
import { toast } from "react-toastify";
import Select from "react-select";
import SelectColours from "../css/SelectColours";
import RemoveUserFromBag from "../modals/Bags/RemoveUserFromBag";
import EditUser from "../modals/Users/EditUser";

import CleanBag from "../modals/Bags/CleanBag";
import CleanBags from "../modals/Bags/CleanBags";
import loca from "../utils/localization";
import qs from "qs";
import ShowMore from "../modules/showMore";
import handleViewport from "react-in-viewport";
import RemoveAssignment from "../modals/Bags/RemoveAssignment";

import * as signalR from "@microsoft/signalr";
import { ExportContent } from "../atoms/ExportContent";
import { getFilename } from "../utils/axios";
import { ColumnVisibilityButton } from "../atoms/ColumnVisibilityButton";

import { dateUtils } from "../utils/dateUtils";

require("intersection-observer");

function getRepresentative(bagsAndAssignment) {
  return bagsAndAssignment.bags[0].bagId;
}

function getAssignedUser(bag, assignments) {
  // Justification: In tag missing mode the assignment has no user tags list which contains the user which contains the bag
  // Since the bag has no user/assignment id and the assignment entity has no bag id there is no way to know the correct assignment
  // However at the moment bag groups are always assigned together
  // TODO abalint Improve this search
  return assignments[0]?.userId;
}

const Block = (props) => {
  const { forwardedRef, enablePaging } = props;
  return (
    <div className="viewport-block" ref={forwardedRef}>
      {enablePaging && <ShowMore />}
    </div>
  );
};

const ViewportBlock = handleViewport(Block);

const FilterComponent = ({
  onFilter,
  onClear,
  handleChange,
  state,
  hideUnusedFilters,
}) => {
  return (
    <div className="col-md-10 filters">
      <div className="row">
        <div className="col col-md-2 pr-0">
          <div className="form-group">
            <div className="input-group">
              <Select
                isSearchable
                isClearable
                name="building"
                id="building"
                className="SelectContainer"
                styles={SelectColours}
                placeholder={loca.TranslateHelper("choose-building-label")}
                onChange={(e) => {
                  handleChange("filterBuilding", e);
                  setTimeout(function () {
                    onFilter(e);
                  }, 100);
                }}
                value={
                  state.filterItems.buildings.length
                    ? state.filterItems.buildings.filter(
                      (option) => option.value === state.filterBuilding
                    )
                    : ""
                }
                options={state.filterItems.buildings}
              />

              <label htmlFor="building">
                {loca.TranslateHelper("building-label")}
              </label>
            </div>
          </div>
        </div>
        <div className="col col-md-2 pr-0">
          <div className="form-group">
            <div className="input-group">
              <Select
                isSearchable
                isClearable
                name="room"
                id="room"
                className="SelectContainer"
                styles={SelectColours}
                placeholder={loca.TranslateHelper("choose-room-label")}
                onChange={(e) => {
                  handleChange("filterRoom", e);
                  setTimeout(function () {
                    onFilter(e);
                  }, 100);
                }}
                value={
                  state.filterItems.rooms.length
                    ? state.filterItems.rooms.filter(
                      (option) => option.value === state.filterRoom
                    )
                    : ""
                }
                options={state.filterItems.rooms}
              />

              <label htmlFor="room">{loca.TranslateHelper("room-label")}</label>
            </div>
          </div>
        </div>
        {!hideUnusedFilters && (
          <div className="col col-md-2 pr-0">
            <div className="form-group">
              <div className="input-group">
                <Select
                  isSearchable
                  isClearable
                  name="gender"
                  id="gender"
                  className="SelectContainer"
                  styles={SelectColours}
                  placeholder={loca.TranslateHelper("choose-gender-label")}
                  onChange={(e) => {
                    handleChange("filterGender", e);
                    setTimeout(function () {
                      onFilter(e);
                    }, 100);
                  }}
                  value={
                    state.filterItems.genderIds.length
                      ? state.filterItems.genderIds.filter(
                        (option) => option.value === state.filterGender
                      )
                      : ""
                  }
                  options={state.filterItems.genderIds}
                />

                <label htmlFor="gender">
                  {loca.TranslateHelper("gender-label")}
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="col col-md-2 pr-0">
          <div className="form-group">
            <div className="input-group">
              <Select
                isSearchable
                isClearable
                name="system"
                id="system"
                className="SelectContainer"
                styles={SelectColours}
                placeholder={loca.TranslateHelper("choose-system-label")}
                onChange={(e) => {
                  handleChange("filterSystem", e);
                  setTimeout(function () {
                    onFilter(e);
                  }, 100);
                }}
                value={
                  state.filterItems.systemIds.length
                    ? state.filterItems.systemIds.filter(
                      (option) => option.value === state.filterSystem
                    )
                    : ""
                }
                options={state.filterItems.systemIds}
              />

              <label htmlFor="system">
                {loca.TranslateHelper("system-label")}
              </label>
            </div>
          </div>
        </div>
        {!hideUnusedFilters && (
          <div className="col col-md-2 pr-0">
            <div className="form-group">
              <div className="input-group">
                <Select
                  isSearchable
                  isClearable
                  name="state"
                  id="state"
                  className="SelectContainer"
                  styles={SelectColours}
                  placeholder={loca.TranslateHelper("choose-state-label")}
                  onChange={(e) => {
                    handleChange("filterState", e);
                    setTimeout(function () {
                      onFilter(e);
                    }, 100);
                  }}
                  value={
                    state.filterItems.states.length
                      ? state.filterItems.states.filter(
                        (option) => option.value === state.filterState
                      )
                      : ""
                  }
                  options={state.filterItems.states}
                />

                <label htmlFor="state">
                  {loca.TranslateHelper("state-label")}
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="col col-md-2 pr-0">
          <div className="row">
            <button
              className="btn btn-simple"
              onClick={(e) => {
                onClear(e);
                setTimeout(function () {
                  onFilter(e);
                }, 100);
              }}
            >
              <i className="icon-cross">&nbsp;</i>
              {loca.TranslateHelper("clear-filter-label")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

class BagsTable extends Component {
  constructor(props) {
    super(props);

    const configData = localStorage.getItem("configData");
    const isThirdPartyLockersEnabled = configData
      ? JSON.parse(configData)?.isThirdPartyLockersEnabled === true ?? false
      : false;

    this.state = {
      data: [],
      resetPaginationToggle: false,
      filterGender: this.props.filterGender ?? "",
      filterBuilding: "",
      filterSystem: "",
      filterState: this.props.filterState ?? "",
      filterRoom: "",
      filterBagType: "",
      buildingOptions: [],
      genderOptions: [],
      dataLoading: true,
      pageSize: 20,
      showCol: false,
      test: true,
      filters: {},
      page: 1,
      maxPage: null,
      enablePaging: true,
      sorting: "",
      filterItems: {
        buildings: [],
        genderIds: [],
        rooms: [],
        systemIds: [],
        states: [],
      },
      filterDeadLocker: null,
      filterPending: null,
      fixedColumnWidth: false,
      omit: localStorage.getItem("bagsTableColumns")
        ? JSON.parse(localStorage.getItem("bagsTableColumns"))
        : {
          building: false,
          "job-title": false,
          room: false,
          gender: false,
          state: false,
          department: false,
          comments: false,
          "system-type": !isThirdPartyLockersEnabled,
          "last-usage-date": false,
        },
      selectedBagRepresentatives: new Set(),
    };

    this.hubConnection = this.createSignalRConnection();

    window.BagsTable = this;
  }

  handleChange = (stateId, e) => {
    this.setState({ [stateId]: e.value });
  };

  handleClear = () => {
    if (this.state.sorting !== "") {
      window.location.reload();
    }

    const { resetPaginationToggle } = this.state;

    this.setState({
      resetPaginationToggle: !resetPaginationToggle,
      filterGender: this.props.filterGender ?? "",
      filterBuilding: "",
      filterSystem: "",
      filterState: this.props.filterState ?? "",
      filterRoom: "",
      filterBagType: "",
      filterDeadLocker: null,
      filterPending: null,
      sorting: "",
      sortDirection: "",
      showPendingStatus: false,
    });
  };

  updateStatistics = () => {
    axios(
      process.env.REACT_APP_API_URL +
      "Assignment/statistics/?SystemName=" +
      encodeURIComponent(
        this.state.filterSystem ? this.state.filterSystem : ""
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
          "Accept-Language": changeLanguageCode(loca.language),
        },
        params: {
          Building: this.state.filterBuilding,
          Room: this.state.filterRoom,
          GenderId: this.state.filterGender,
        },
        withCredentials: true,
        credentials: "same-origin",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            window.Header.setState({ stat_value1: response.data.unassigned });
            window.Header.setState({ stat_value2: response.data.assigned });
            window.Header.setState({ stat_value3: response.data.outOfUse });
            window.Header.setState({ stat_value4: response.data.cleaning });
            window.Header.setState({ stat_value5: response.data.pending });
            window.Header.setState({ stat_value6: response.data.deadLocker });
            window.Side.setState({ bagsPending: response.data.pending });
            window.Header.setState({
              stat_valueTotal: response.data.totalCount,
            });
            window.Header.setState({ stat_show: true });
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  updateFilter = (onload) => {
    window.BagsTable = this;
    this.setState({ dataLoading: true });

    this.updateStatistics();

    this.refreshFilter(true);

    this.setState({ dataLoading: false });
  };

  exportData = (format) => {
    const data = {
      Building: this.state.filterBuilding,
      Room: this.state.filterRoom,
      GenderId: this.state.filterGender,
      SystemName: this.state.filterSystem,
      State: this.state.filterState,
      ExportFormat: format,
    };

    axios(
      process.env.REACT_APP_API_URL + "Assignment/export?" + qs.stringify(data),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
          "Accept-Language": changeLanguageCode(loca.language),
        },
        responseType: "arraybuffer", // default is json
        withCredentials: true,
        credentials: "same-origin",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            var byte = response.data;

            var blob = new Blob([byte], {
              type: response.headers["content-type"],
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            link.download = getFilename(response);
            link.click();
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  toggleHideVisibility = () => {
    this.setState({ showColumnHide: !this.state.showColumnHide });
  };

  saveHideColumns = (col) => {
    this.setState(
      (prevState) => ({
        omit: {
          ...prevState.omit,
          [col]: !this.state.omit[col],
        },
      }),
      () => {
        setImmediate(() => {
          window.localStorage.setItem(
            "bagsTableColumns",
            JSON.stringify(this.state.omit)
          );
        });
      }
    );
  };

  getSubHeaderComponent = () => {
    const selectionDetails = this.getSelectionDetails();
    const selectedBags = this.getSelectedBagsWithAssignedUser();

    return (
      <div className={"row w-100"}>
        <div className={"col-12"}>
          <div className="row w-100 my-3">
            <FilterComponent
              onFilter={() => this.updateFilter(false)}
              onClear={this.handleClear}
              onInputChange={this.handleChange}
              handleChange={this.handleChange}
              hideUnusedFilters={this.props.hideUnusedFilters}
              state={this.state}
              appendBags={this.appendBags}
              getStatistics={this.getStatistics}
            />

            <div className="col-action">
              {!window.BagsTable.props.selectForUser && (
                <ExportContent exportWithFormat={this.exportData} />
              )}

              {!this.props.disableAction && (
                <button
                  className="float-right"
                  onClick={window.Header.showHeader}
                >
                  <i className="icon-show-header">&nbsp;</i>
                </button>
              )}
              {!this.props.disableAction && (
                <button
                  className="float-right"
                  onClick={window.Header.hideHeader}
                >
                  <i className="icon-hide-header">&nbsp;</i>
                </button>
              )}

              <ColumnVisibilityButton
                columns={[
                  "building",
                  "room",
                  "gender",
                  "system-type",
                  "state",
                  "last-usage-date",
                  "job-title",
                  "department",
                  "comments",
                ]}
                isVisible={this.state.showColumnHide}
                omit={this.state.omit}
                saveHideColumns={this.saveHideColumns}
                toggleHideVisibility={this.toggleHideVisibility}
              />

              <div className="clear">&nbsp;</div>
            </div>
          </div>
        </div>

        {selectionDetails && (
          <div className={"col-12 pb-3"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <button
                  className="btn btn-full"
                  disabled={selectedBags.length === 0}
                  onClick={selectionDetails.onClick}
                >
                  <i className={selectionDetails.iconClassName} />{" "}
                  <loca.Translate
                    locaKey={selectionDetails.label}
                    values={{ count: selectedBags.length }}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  getStatistics() {
    window.Header.setState({ stat_show: false });
    window.Header.setState({ stat_value1: "" });
    window.Header.setState({ stat_value2: "" });
    window.Header.setState({ stat_value3: "" });
    window.Header.setState({ stat_value4: "" });
    window.Header.setState({ stat_value5: "" });
    window.Header.setState({ stat_value6: "" });
    window.Header.setState({ stat_valueTotal: "" });

    axios(process.env.REACT_APP_API_URL + "Assignment/statistics", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
        "Accept-Language": changeLanguageCode(loca.language),
      },
      data: {
        Building: this.state.filterBuilding,
        Room: this.state.filterRoom,
        GenderId: this.state.filterGender,
        SystemId: this.state.filterSystem,
        State: this.state.filterState,
      },
      withCredentials: true,
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            window.Header.setState({ stat_value1: response.data.unassigned });
            window.Header.setState({ stat_value2: response.data.assigned });
            window.Header.setState({ stat_value3: response.data.outOfUse });
            window.Header.setState({ stat_value4: response.data.cleaning });
            window.Header.setState({ stat_value5: response.data.pending });
            window.Side.setState({ bagsPending: response.data.pending });
            window.Header.setState({
              stat_valueTotal: response.data.totalCount,
            });
            window.Header.setState({ stat_show: true });
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  refreshFilter(resetPaging) {
    if (resetPaging) {
      this.resetState();
    }

    axios(process.env.REACT_APP_API_URL + "Filter/bagOptions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
        "Accept-Language": changeLanguageCode(loca.language),
      },
      withCredentials: true,
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            var buildingFilter = Object.keys(response.data.buildings).map(
              function (key, index) {
                return { value: key, label: response.data.buildings[key] };
              }
            );

            var genderFilter = Object.keys(response.data.genderIds).map(
              function (key, index) {
                return { value: key, label: response.data.genderIds[key] };
              }
            );

            var roomFilter = Object.keys(response.data.rooms).map(function (
              key,
              index
            ) {
              return { value: key, label: response.data.rooms[key] };
            });

            var statesFilter = Object.keys(response.data.states).map(function (
              key,
              index
            ) {
              return { value: Number(key), label: response.data.states[key] };
            });

            var systemFilter = Object.keys(response.data.systems).map(function (
              key,
              index
            ) {
              return { value: key, label: response.data.systems[key] };
            });

            buildingFilter.unshift({
              key: "",
              label: loca.TranslateHelper("all-label"),
            });
            genderFilter.unshift({
              key: "",
              label: loca.TranslateHelper("all-label"),
            });
            roomFilter.unshift({
              key: "",
              label: loca.TranslateHelper("all-label"),
            });
            statesFilter.unshift({
              key: "",
              label: loca.TranslateHelper("all-label"),
            });
            systemFilter.unshift({
              key: "0",
              label: loca.TranslateHelper("all-label"),
            });

            this.setState({
              filterItems: {
                buildings: buildingFilter,
                genderIds: genderFilter,
                rooms: roomFilter,
                states: statesFilter,
                systemIds: systemFilter,
              },
            });
          }
        } else {
          toast.error("error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  appendBags(resetPaging) {
    const self = this;
    if (self.state.enablePaging || resetPaging) {
      axios(
        process.env.REACT_APP_API_URL +
        "Assignment/groupedBagsPaged/?SystemName=" +
        encodeURIComponent(
          self.state.filterSystem ? self.state.filterSystem : ""
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
            "Accept-Language": changeLanguageCode(loca.language),
          },
          params: {
            Page: self.state.page,
            PageSize: self.state.pageSize,
            OrderBy: self.state.sorting,
            DescedingOrder: self.state.sortDirection === "asc" ? false : true,
            Building: self.state.filterBuilding,
            Room: self.state.filterRoom,
            GenderId: self.state.filterGender,
            State: self.state.filterState,
            DeadLocker: self.state.filterDeadLocker,
            Pending: self.state.filterPending,
            LastActivityDate: null,
          },
          withCredentials: true,
          credentials: "same-origin",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data === "User not logged in.") {
              Logout();
            } else {
              const newData = [...self.state.data, ...response.data.data];

              window.BagsTable.setState({
                data: newData,
              });
              window.BagsTable.filteredItems = [
                ...self.state.data,
                ...response.data.data,
              ];

              if (
                response.data.pageInfo.page <
                Math.ceil(
                  response.data.totalRecords / response.data.pageInfo.pageSize
                )
              ) {
                self.setState({ page: response.data.pageInfo.page + 1 });
                self.setState({ enablePaging: true });
              } else {
                self.setState({ enablePaging: false });
              }

              window.BagsTable.setState({ dataLoading: false });

              self.updateStatistics();
              self.refreshFilter(resetPaging);

              return newData;
            }
          } else {
            toast.error("error", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .then((newData) => {
          if (newData.length === 0 && self.state.filterDeadLocker) {
            self.setState({ filterDeadLocker: false });
            self.appendBags(true);
          }
        })
        .catch((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }

  sortDataTable(column, direction, event) {
    const self = this;

    self.setState({
      sorting: column.id,
      sortDirection: direction,
      data: [],
      page: 1,
      enablePaging: false,
    });

    self.appendBags(true);
  }

  snoozeBag(bagId) {
    const self = this;
    axios(process.env.REACT_APP_API_URL + "Bag/nudge/" + bagId, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        tenantId: JSON.parse(localStorage.getItem("loginData")).tenantId,
        "Accept-Language": changeLanguageCode(loca.language),
      },
      bagId: bagId,
      withCredentials: true,
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.status === 204) {
          if (response.data === "User not logged in.") {
            Logout();
          } else {
            self.appendBags(true);
          }
        } else {
          toast.error("error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  resetState() {
    this.setState({
      data: [],
      selectedBagRepresentatives: new Set(),
      page: 1,
      enablePaging: true,
    });
  }

  createSignalRConnection = () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`/hubs/lockerSystem`)
      .withAutomaticReconnect()
      .build();

    return connection;
  };

  startListeningToHub = (failedAttempts = 0) => {
    const attemptNumber = failedAttempts + 1;

    this.hubConnection
      .start()
      .then(() => {
        console.log("Started listening to hub");
        this.hubConnection.on("PendingStateChangesAsync", this.handleHubEvent);
      })
      .catch((err) => {
        console.error(`Failed to connect to SignalR hub because ${err}`);
        console.log(`Retrying, attempt #${attemptNumber}`);
        if (failedAttempts < 3) this.startListeningToHub(attemptNumber);
        // TODO abalint: Consider showing a toast maybe
        else
          console.error(
            "Giving up but site is still useable just need to refresh occassionally to see updates"
          );
      });
  };

  stopListeningToHub = () => {
    this.hubConnection.stop().then(console.log("Stopped listening to hub"));
  };

  handleHubEvent = (eventData) => {
    // Update bags
    this.setState((state) => {
      return {
        ...state,
        data: state.data.map((dataLine) => ({
          ...dataLine,
          bags: dataLine.bags.map((bag) => {
            const bagEvent = eventData.find(
              (event) => event.bagId === bag.bagId
            );

            if (bagEvent !== undefined) {
              return {
                ...bag,
                state: bagEvent.state,
                isPending: bagEvent.isPending, // should be false
              };
            }
            return bag;
          }),
        })),
      };
    }, this.updateStatistics);
  };

  componentDidMount() {
    this.startListeningToHub();
  }

  componentWillUnmount() {
    this.stopListeningToHub();
  }

  getSelectionDetails = () => {
    // TODO abalint why is filterState its own state?
    if (
      this.state.filterState === 1 ||
      this.state.filterState === 2 ||
      this.state.deadLocker === true
    )
      return {
        label: "unassign-bags-label",
        iconClassName: "fas fa-trash",
        onClick: () =>
          openModal(
            <RemoveAssignment bag={this.getSelectedBagsWithAssignedUser()} />,
            { size: "800px" }
          ),
      }; // unassign

    if (this.state.filterState === 3)
      return {
        label: "clean-bags-label",
        iconClassName: "fas fa-broom",
        onClick: () =>
          openModal(
            <CleanBags bags={this.getSelectedBagsWithAssignedUser()} />,
            {
              size: "800px",
            }
          ),
      }; // clean

    return undefined; // No action, do not allow selection
  };

  getSelectedBagsWithAssignedUser = () => {
    return this.state.data
      .filter((d) =>
        this.state.selectedBagRepresentatives.has(getRepresentative(d))
      )
      .map((d) => ({
        bags: d.bags.map((bag) => ({
          ...bag,
          userId: getAssignedUser(bag, d.assignments),
        })),
      }))
      .flatMap((d) => d.bags);
  };

  handleGlobalSelectorClicked = (event) => {
    if (event.target.checked) {
      // Check all currently loaded bags
      this.setState((state) => ({
        ...state,
        selectedBagRepresentatives: new Set(
          state.data.map((d) => getRepresentative(d))
        ),
      }));
    } else {
      // Uncheck all bags
      this.setState((state) => ({
        ...state,
        selectedBagRepresentatives: new Set(),
      }));
    }
  };

  selectBagForUser = (bags) => {
    window.Modal.props.closeAction(bags);
  };

  render = () => {
    const actionOnSelected = this.getSelectionDetails();
    const configData = localStorage.getItem('configData');
    const isGroupedMode = configData ? JSON.parse(configData)?.isGroupedModeEnabled === true : false;
    const isThirdPartyLockersEnabled = configData ? JSON.parse(configData)?.isThirdPartyLockersEnabled === true : false;

    const allowSorting = !isGroupedMode;


    const columns = [
      {
        name: actionOnSelected ? (
          <div className="ctooltip">
            <div>
              <input
                type="checkbox"
                id={"cb_all"}
                onChange={this.handleGlobalSelectorClicked}
              />
              <label htmlFor={"cb_all"} className={"selector"} />
            </div>
          </div>
        ) : null,
        sortable: false,
        width: "70px",
        export: false,
        cell: (row) => {
          const representative = getRepresentative(row);
          const htmlId = `cb_${representative}`;
          const isSelected =
            this.state.selectedBagRepresentatives.has(representative);
          const toggle = () => {
            this.setState((state) => {
              const newSelecteds = new Set(state.selectedBagRepresentatives);
              if (isSelected) newSelecteds.delete(representative);
              else newSelecteds.add(representative);

              return {
                ...state,
                selectedBagRepresentatives: newSelecteds,
              };
            });
          };

          return actionOnSelected ? (
            <div>
              <div className={"bagSelector"}>
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={toggle}
                  id={htmlId}
                />
                <label htmlFor={htmlId} className={"selector"} />
              </div>
            </div>
          ) : null;
        },
      },
      {
        name: loca.TranslateHelper("building-label"),
        sortable: allowSorting,
        maxWidth: "100px",
        id: "building_id",
        label: (
          <div>
            <loca.Translate locaKey={"building-label"} />
          </div>
        ),
        hideable: true,
        omit: this.state.omit.building,
        selector: (row, index) => row.building,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key) => (
              <div key={key} className={"py-1"} title={row.bags[key].building}>
                {row.bags[key].building}&nbsp;
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("room-label"),
        sortable: allowSorting,
        maxWidth: "80px",
        minWidth: "80px",
        id: "room",
        label: (
          <div>
            <loca.Translate locaKey={"room-label"} />
          </div>
        ),
        hideable: true,
        omit: this.state.omit.room,
        selector: (row, index) => row.room,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key) => (
              <div key={key} className={"py-1"} title={row.bags[key].room}>
                {row.bags[key].room}&nbsp;
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("gender-label"),
        sortable: allowSorting,
        maxWidth: "80px",
        minWidth: "80px",
        id: "gender",
        label: (
          <div>
            <loca.Translate locaKey={"gender-label"} />
          </div>
        ),
        hideable: true,
        omit: this.props.hideGender || this.state.omit.gender,
        selector: (row, index) => row.roomGender,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key) => (
              <div
                key={key}
                className={"py-1"}
                title={row.bags[key].roomGender}
              >
                {row.bags[key].roomGender}&nbsp;
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("system-label"),
        sortable: allowSorting,
        maxWidth: "100px",
        minWidth: "100px",
        id: "system",
        label: <div>{loca.TranslateHelper("system-label")}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.systemName,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key) => (
              <div
                key={key}
                className={"py-1"}
                title={row.bags[key].systemName}
              >
                {row.bags[key].systemName}
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("system-type-label"),
        sortable: allowSorting,
        maxWidth: "120px",
        minWidth: "120px",
        id: "system type",
        label: <div>{loca.TranslateHelper("system-type-label")}</div>,
        hideable: true,
        omit: this.state.omit["system-type"],
        selector: (row, index) => row.systemType,
        cell: (row) => (
          <div>
            {row.bags.map((value) => (
              <div key={value.bagId} className={"py-1"}>
                {value.systemType && <div>{value.systemType}</div>}
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("bag-label"),
        sortable: allowSorting,
        maxWidth: loca.language !== "en" && !isThirdPartyLockersEnabled ? "150px" : "100px",
        minWidth: loca.language !== "en" && !isThirdPartyLockersEnabled ? "150px" : "100px",
        id: "bag",
        label: <div>{loca.TranslateHelper("bag-label")}</div>,
        hideable: true,
        omit: false,
        selector: (row, index) => row.bagNumber,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key) => (
              <div key={key} className={"py-1"} title={row.bags[key].bagNumber}>
                {row.bags[key].bagNumber}&nbsp;
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("state-label"),
        sortable: allowSorting,
        maxWidth: "70px",
        minWidth: "70px",
        id: "state",
        label: (
          <div>
            <loca.Translate locaKey={"state-label"} />
          </div>
        ),
        hideable: true,
        omit: this.props.hideState || this.state.omit.state,
        selector: (row, index) => row.state,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key) => (
              <div
                key={key}
                className={
                  "my-3 status status-pending-" +
                  row.bags[key].isPending +
                  " status-" +
                  row.bags[key].state
                }
                aria-label={row.bags[key].state}
              >
                {loca.t(getBagStatusLabel(row.bags[key].state))}&nbsp;
                <div className={"ctooltip"}>
                  <div className={"tt"}>
                    {loca.TranslateHelper(getBagsTooltip(row.bags[key].state))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("assigned-users-label"),
        selector: (row) => row["users"],
        minWidth: "160px",
        id: "users",
        label: (
          <div>
            <loca.Translate locaKey={"assigned-users-label"} /> (id)
          </div>
        ),
        hideable: true,
        omit: this.props.hideAssigned,
        sortable: false,
        cell: (row) =>
          Object.keys(row.assignments).map((key) => (
            <div
              key={key}
              className="maxWidth100"
              style={{
                overflow: "unset",
                color: row.bags[key].deadLocker ? "grey" : "black",
              }}
            >
              <div className={"ellipsis maxWidth100"}>
                {row.assignments[key].userId !==
                  "00000000-0000-0000-0000-000000000000" ? (
                  <button
                    className=""
                    onClick={() =>
                      openModal(
                        <RemoveUserFromBag
                          userId={row.assignments[key].userId}
                          userName={
                            row.assignments[key].lastName +
                            " " +
                            row.assignments[key].firstName
                          }
                          bag={row.bags}
                        />,
                        { size: "600px" }
                      )
                    }
                  >
                    <i className="icon-delete">&nbsp;</i>
                  </button>
                ) : (
                  ""
                )}
                {row.assignments[key].userId !==
                  "00000000-0000-0000-0000-000000000000" ? (
                  <button
                    className=""
                    onClick={() =>
                      openModal(
                        <EditUser
                          userId={row.assignments[key].userId}
                          hasAssignment={true}
                          user={row.assignments[key]}
                        />,
                        { size: "1100px" }
                      )
                    }
                  >
                    <i className="icon-edit">&nbsp;</i>
                  </button>
                ) : (
                  ""
                )}
                {row.assignments[key].lastName} {row.assignments[key].firstName}{" "}
                <br />{" "}
                {row.assignments[key].externalId &&
                  "(" + row.assignments[key].externalId + ")"}
              </div>
            </div>
          )),
      },
      {
        name: "",
        sortable: false,
        selector: (row) => row["add-user"],
        id: "",
        width: this.state.fixedColumnWidth ? "10%" : null,
        export: false,
        ignoreRowClick: true,
        cell: (row) => (
          <div>
            {Object.keys(row.bags).map((key, index) =>
              row.bags[key].state === 3 ? (
                index === 0 && (
                  <button
                    className="btn btn-full"
                    onClick={() =>
                      openModal(<CleanBag bag={row.bags} />, { size: "800px" })
                    }
                  >
                    <i className="fas fa-broom" />
                    <loca.Translate locaKey={"clean-label"} />
                  </button>
                )
              ) : window.BagsTable.props.selectForUser ? (
                <button
                  className="btn btn-full btn-sm"
                  onClick={() => window.BagsTable.selectBagForUser(row.bags)}
                  disabled={this.props.bagsPendingAssignment?.some((x) =>
                    row.bags.some((y) => y.bagId === x.bagId)
                  )}
                >
                  {loca.TranslateHelper("select-label")}
                </button>
              ) : (
                <button
                  className="btn btn-full"
                  onClick={() =>
                    openModal(<UserToBag bag={row.bags} />, { size: "800px" })
                  }
                >
                  <i className="icon-add-user" />
                  <loca.Translate locaKey={"users-label"} />
                </button>
              )
            )}
          </div>
        ),
      },
      {
        name: loca.TranslateHelper("last-usage-date-label"),
        minWidth: "240px",
        id: "last-usage-date",
        label: (
          <div>
            <loca.Translate locaKey={"last-usage-date-label"} />
          </div>
        ),
        hideable: true,
        omit: this.state.omit["last-usage-date"],
        sortable: true,
        cell: (row) => {
          return (
            <div className="d-flex flex-column w-100">
              {Object.values(row.bags).map((bag) => {
                const hasBeenUsed =
                  bag.lastBagActivityDate !== "0001-01-01T00:00:00";
                const deadLocker = bag.deadLocker;
                const daysSinceLastActivity = dateUtils.getDaysUntilToday(bag.lastBagActivityDate);


                return (
                  <div key={bag.bagId} className="w-100 py-1 d-flex flex-row align-items-center">
                    <span>
                      {hasBeenUsed
                        ? new Date(bag.lastBagActivityDate).toLocaleString()
                        : "N/A"}
                    </span>

                    {deadLocker && (
                      <>
                        <div className={"hasTooltip"}>
                          <i
                            className={`fas fa-exclamation-triangle ml-1 text-secondary`}
                            style={{ fontSize: "10px" }}
                          />
                          <div className={"ctooltip"}>
                            <div className={"tt tt-below"}>
                              <loca.Translate locaKey={"dead-locker-warning-label"} values={{ count: daysSinceLastActivity }} />
                            </div>
                          </div>
                        </div>

                        <div className={"ml-auto"}>
                          <button
                            className={"btn btn-full"}
                            onClick={() => this.snoozeBag(bag.bagId)}
                          >
                            {loca.t("snooze-label")}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      // TODO abalint Discuss with ancsi to keep either jobtitle or job-title (visiblity uses job-title!!!)
      {
        name: loca.TranslateHelper("job-title-label"),
        selector: (row) => row["title"],
        width: this.state.fixedColumnWidth ? "10%" : null,
        id: "title",
        label: (
          <div>
            <loca.Translate locaKey={"jobtitle-label"} />
          </div>
        ),
        hideable: true,
        omit: this.state.omit["job-title"],
        sortable: false,
        cell: (row) =>
          Object.keys(row.assignments).map((key) => (
            <span className={"d-block ellipsis"} key={key}>
              <div
                className={"d-block ellipsis"}
                title={row.assignments[key].jobTitleName}
              >
                {row.assignments[key].jobTitleName}&nbsp;
              </div>
            </span>
          )),
      },
      {
        name: loca.TranslateHelper("department-label"),
        selector: (row) => row["department"],
        width: this.state.fixedColumnWidth ? "10%" : null,
        id: "department",
        label: (
          <div>
            <loca.Translate locaKey={"department-label"} />
          </div>
        ),
        hideable: true,
        omit: this.state.omit.department,
        sortable: false,
        cell: (row) =>
          Object.keys(row.assignments).map((key) => (
            <span className={"d-block ellipsis"} key={key}>
              <div
                className={"d-block ellipsis"}
                title={row.assignments[key].departmentName}
              >
                {row.assignments[key].departmentName}&nbsp;
              </div>
            </span>
          )),
      },
      {
        name: loca.TranslateHelper("comments-label"),
        selector: (row) => row["comments"],
        id: "comments",
        sortable: false,
        hidable: true,
        omit: this.state.omit.comments,
        cell: (row) =>
          Object.keys(row.assignments).map(
            (key) =>
              row.assignments[key].comment && (
                <span
                  className="d-block ellipsis"
                  title={row.assignments[key].comment}
                  key={key}
                >
                  <i className="fas fa-comment-alt" />{" "}
                  {row.assignments[key].comment}
                </span>
              )
          ),
      },
    ];

    const data = this.state.data;

    return (
      <div
        id="bagsTable"
        onClick={() => {
          window.Header.setState({ showSearchResult: false });
        }}
      >
        <DataTable
          noHeader
          className="datatable"
          progressPending={this.state.dataLoading}
          progressComponent={
            <div>
              <h2 className="main my-5">
                {loca.TranslateHelper("loading-label")}
              </h2>
            </div>
          }
          pagination={false}
          paginationPerPage={this.state.pageSize}
          paginationRowsPerPageOptions={[15, 30, 50]}
          paginationResetDefaultPage={this.state.resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={this.getSubHeaderComponent()}
          onSort={(column, sortDirection, event) =>
            this.sortDataTable(column, sortDirection, event)
          }
          sortServer={true}
          highlightOnHover={true}
          columns={columns}
          data={data}
        />
        {this.state.enablePaging && (
          <ViewportBlock
            enablePaging={this.state.enablePaging}
            onEnterViewport={() => this.appendBags(false)}
          />
        )}
      </div>
    );
  };
}

export default BagsTable;
