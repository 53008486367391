import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import loca from "../../utils/localization";

class DeletionResponse extends Component {

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChange(stateId, e) {
        this.setState({ [stateId]: e.value })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('delete-user-label', { count: this.props.deleteSucceeded })}</h2>

                <div className="row mt-3">
                    <div className="col-12">

                        {this.props.deleteSucceeded !== 0 &&
                            <div className={'row p-4 alert alert-success'}>
                                <div className={'col-1'}>
                                    <i className={'icon-check'} />
                                </div>
                                <div className={'col-11'} dangerouslySetInnerHTML={{ __html: t('delete-success-label', { successedDelete: this.props.deleteSucceeded, failedDelete: this.props.deleteFailed, totalDeleteCount: this.props.deleteSucceeded + this.props.deleteFailed }) }}>

                                </div>
                            </div>
                        }
                        {this.props.deleteFailed !== 0 &&
                            <div className={'row p-4 alert alert-danger'}>
                                <div className={'col-1'}>
                                    <i className={'icon-cross'} />
                                </div>
                                <div className={'col-11'} dangerouslySetInnerHTML={{ __html: t('delete-failed-label', { successedDelete: this.props.deleteSucceeded, failedDelete: this.props.deleteFailed, totalDeleteCount: this.props.deleteSucceeded + this.props.deleteFailed }) }}>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DeletionResponse);
