import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import {
    Logout,
    openModal,
    RefreshView,
    changeLanguageCode,
    TagTypesLabels
} from "../../Helpers"
import DeleteTag from "./DeleteTag";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

class EditTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rfid_uid: '',
            tag_name: '',
            tagtype: '',
        };

        this.EditTagValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSelectChange = (e) => {
        this.setState({ tagtype: e.value })
    }

    componentDidMount() {
        this.getTag();
    }

    getTag() {
        axios(process.env.REACT_APP_API_URL + 'Tag/' + this.props.tagId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                'Accept-Language': changeLanguageCode(loca.language)
            },
            withCredentials: true,
        })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data === 'User not logged in.') {
                            Logout();
                        } else {
                            this.setState({ rfid_uid: response.data.externalId });
                            this.setState({ tag_name: response.data.tagName });
                            this.setState({ tagtype: response.data.tagType });
                            this.setState({ user: response.data.user });
                            this.setState({ userId: response.data.userId });
                        }
                    } else {
                        toast.error('ERROR', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            )
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                document.getElementById("saveTagBTN").classList.remove("loading");
            });
    }

    saveEditTag() {
        if (this.EditTagValidator.allValid()) {
            document.getElementById("saveTagBTN").classList.add("loading");
            axios(process.env.REACT_APP_API_URL + 'Tag/' + this.props.tagId, {
                method: 'PUT',
                data: {
                    'tagId': this.props.tagId,
                    'externalId': this.state.rfid_uid,
                    'tagName': this.state.tag_name,
                    'tagType': this.state.tagtype,
                    'userId': this.state.userId,
                    'user': this.state.user
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if (response.status === 202) {
                            if (response.data === 'User not logged in.') {
                                Logout();
                            } else {
                                RefreshView(window.View.state.currentview);

                                toast.success(loca.TranslateHelper('edit-tag-success-label'), {
                                    position: toast.POSITION.TOP_CENTER,
                                });

                                window.Modal.props.closeAction();
                                document.getElementById("saveTagBTN").classList.remove("loading");
                            }
                        } else {
                            toast.error('ERROR', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                            document.getElementById("saveTagBTN").classList.remove("loading");
                        }
                    }
                )
                .catch(error => {
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    document.getElementById("saveTagBTN").classList.remove("loading");
                });
        } else {
            this.EditTagValidator.showMessages();
            this.forceUpdate();
        }
    }

    render = () => {
        return (
            <div>
                <h2 className="main">{loca.TranslateHelper('create-tag-edit-label')}</h2>
                <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" disabled name="rfid_uid" id="rfid_uid" placeholder={loca.TranslateHelper('rfid-uid-label')} onChange={this.onChange} value={this.state.rfid_uid || ''} />
                                <label htmlFor="rfid_uid">{loca.TranslateHelper('rfid-uid-label')} <em>*</em></label>
                            </div>
                            {this.EditTagValidator.message('rfid_uid', this.state.rfid_uid, ['required', 'min:1', 'max:16', 'regex:^[a-fA-F0-9]+$'])}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled ? 'disabled' : ''} type="text" name="tag_name" id="tag_name" placeholder={loca.TranslateHelper('tag-name-label')} onChange={this.onChange} value={this.state.tag_name || ''} />
                                <label htmlFor="tag_name">{loca.TranslateHelper('tag-name-label')} <em>*</em></label>
                            </div>
                            {this.EditTagValidator.message('tag_name', this.state.tag_name, 'required|alpha_num_dash_space')}
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" disabled name="tagtype" id="tagtype" placeholder={loca.TranslateHelper(('tag-type-label'))} value={loca.t(TagTypesLabels[this.state.tagtype])} />
                                <label htmlFor="tagtype">{loca.TranslateHelper(('tag-type-label'))} <em>*</em></label>
                            </div>
                        </div>
                        <div className="form-group">
                            <button id="saveTagBTN" className="btn btn-full" onClick={() => this.saveEditTag()}><i className="icon-tag" />{loca.TranslateHelper('save-tag-label')}</button>
                            <button disabled={JSON.parse(localStorage.getItem('amsData')).isEnabled && this.state.tagtype !== 3 ? 'disabled' : ''} id="deleteTagBTN" className="btn btn-full ml-2" onClick={() => openModal(<DeleteTag tagId={this.props.tagId} tagName={this.state.tag_name} />)}><i className="icon-delete" />{loca.TranslateHelper('delete-tag-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EditTag);
