import React, { Component } from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {changeLanguageCode, Logout} from "../../Helpers";
import {toast} from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import loca from "../../utils/localization"

const setLoginLocal = async (loginData) => {
    try {
        window.localStorage.setItem("loginData", JSON.stringify(loginData.data));
        //await AsyncStorage.setItem('loginData', JSON.stringify(loginData.data));
    } catch (err) {
        console.log(err);
    }
};

class ChangeName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: this.props.firstName,
            lastname: this.props.lastName,
        };

        this.ChangeNameValidator = new SimpleReactValidator({
            locale: loca.language,
        })
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    changeName(){
        if (this.ChangeNameValidator.allValid()) {
            document.getElementById("changeNameBTN").classList.add("loading");

            axios(process.env.REACT_APP_API_URL+'AppUser/Profile/Name',{
                method: 'POST',
                data:{
                    'firstname' : this.state.firstname,
                    'lastname': this.state.lastname
                },
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                    'Accept-Language': changeLanguageCode(loca.language)
                },
                withCredentials: true,
            })
                .then(
                    response => {
                        if(response.status === 200){
                            if(response.data === 'User not logged in.'){
                                Logout();
                            }else{
                                axios(process.env.REACT_APP_API_URL + 'AppUser', {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'tenantId': JSON.parse(localStorage.getItem('loginData')).tenantId,
                                        'Accept-Language': changeLanguageCode(loca.language)
                                    },
                                    withCredentials: true,
                                })
                                    .then(
                                        response => {
                                            if(response.status !== 200){
                                                Logout();
                                            }else{
                                                const o = {...response, userId: response.data.userId};
                                                setLoginLocal(o);

                                                toast.success(loca.TranslateHelper('save-success-label'), {
                                                    position: toast.POSITION.TOP_CENTER,
                                                });

                                                setTimeout(function(){
                                                    window.location.reload(false)
                                                },2000);
                                            }
                                        }
                                    )
                                    .catch(error => {
                                        console.log(error);
                                        document.getElementById("changeNameBTN").classList.remove("loading");

                                        toast.error(error.response.data, {
                                            position: toast.POSITION.TOP_CENTER,
                                        });
                                    });
                            }
                        }else{
                            toast.error('Error', {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                )
                .catch(error => {
                    console.log(error);
                    document.getElementById("changeNameBTN").classList.remove("loading");
                    toast.error(error.response.data, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                });
        } else {
            this.ChangeNameValidator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (
            <div>
                <h2 className="main tc">{loca.TranslateHelper('change-name-label')}</h2>
                <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" name="lastname" id="lastname" placeholder= { loca.TranslateHelper('lastname-label') } value={this.state.lastname || ''} onChange={this.onChange}/>
                                <label htmlFor="lastname">{loca.TranslateHelper('lastname-label')}</label>
                            </div>
                            {this.ChangeNameValidator.message('lastname', this.state.lastname, 'required')}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input type="text" name="firstname" id="firstname" placeholder= { loca.TranslateHelper('firstname-label') } value={this.state.firstname || ''} onChange={this.onChange}/>
                                <label htmlFor="firstname">{loca.TranslateHelper('firstname-label')}</label>
                            </div>
                            {this.ChangeNameValidator.message('firstname', this.state.firstname, 'required')}
                        </div>
                        <div className="form-group">
                            <button id="changeNameBTN" className="btn btn-full mt-3" onClick={() => this.changeName()}>{loca.TranslateHelper('save-label')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ChangeName);
