export const selectColours = {
    container: () => ({
        padding: '0',
    }),

    control: () => ({
    }),

    clearIndicator: () => ({
        display: 'none'
    }),

    placeholder: () => ({
        opacity: 1,
        fontSize: '14px',
        color: '#201E20',
        fontFamily: 'Blender',
        fontWeight: 'bold',
        padding: '5px 20px 0 0',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }),

    valueContainer: () => ({
        padding: '0px 20px',
        background: 'none',
        fontSize: '14px',
        color: '#201E20',
        fontFamily: "Blender",
        fontWeight: 'bold',
        height: '100%'
    }),

    dropdownIndicator: () => ({
        position: 'absolute',
        right: '10px',
        top: '15px',
        width: '100%',
        svg: {
            float: 'right',
        }
    }),

    menu: () => ({
        top: '100%',
        backgroundColor: "#fff",
        borderRadius: '0px',
        left: '0',
        boxShadow: '0 70px 100px 0 rgba(0,0,0,0.14);',
        margin: '0 0 8px 0',
        position: 'absolute',
        width: '100%',
        zIndex: '1',
        boxSizing: 'border-box'
    }),

    menuList: () => ({
        maxHeight: '200px',
        overflow: 'auto',
    }),

    input: () => ({
        height: '100%',
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        padding: '4px 20px 0'
    }),

    multiValue: () => ({
        backgroundColor: '#efefef',
        height: '20px',
        width: 'auto',
        padding: '0 10px',
        margin: '20px 3px 0 0',
        float: 'left',
        lineHeight: '20px',
        zIndex: '10',
        position: 'relative',
    }),

    multiValueLabel: () => ({
        float: 'left',
    }),

    multiValueRemove: () => ({
        float: 'right',
        margin: '3px 0 0 3px'
    }),

    option: (provided, state) => ({
        borderBottom: '1px solid #E8E8E8',
        padding: '10px 20px',
        lineHeight: '1em',
        background: state.isSelected ? '#F6F6F6' : state.isFocused ? '#FBFBFB' : 'none',
        fontSize: '14px',
        color: '#000000',
        opacity: state.isDisabled ? 0.2 : 1,
        cursor: 'pointer',
        margin: '0',
        '&:hover': {
            backgroundColor: '#FBFBFB'
        },
    }),
    singleValue: (provided, state) => {
        const margin = '4px 0px 0px';
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';


        return { ...provided, opacity, transition, margin };
    }
};

export default (selectColours);